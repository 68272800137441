import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterEventsService {
  private _prevUrl: string = undefined;
  private _currUrl: string = undefined;

  constructor(private router: Router) {
    this._currUrl = this.router.url;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._prevUrl = this._currUrl;
        this._currUrl = event.url;
      }
    });
  }

  public getPrevUrl(){
    return this._prevUrl;
  }
}
