import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../shared/services/api.service';
import { ApiConstants } from '../constants/api-constants';

import { ClientDetailsModel } from '../models/clients/ClientDetailsModel';
import { ClientModel } from '../models/clients/ClientModel';
import { InvoiceRequestModel } from '../models/projectModels/InvoiceRequestModel';

@Injectable({
    providedIn: 'root'
})
export class ClientsService {
    private _apiUrl = ApiConstants.CLIENTS_API_ENDPOINT;

    constructor(private apiService: ApiService) { }

    getClients(): Observable<ClientDetailsModel[]> {
        return this.apiService.get<ClientDetailsModel[]>(this._apiUrl);
    }

    addClient(newClient: ClientDetailsModel): Observable<ClientDetailsModel> {
        return this.apiService.post<ClientDetailsModel>(`${this._apiUrl}`, newClient);
    }

    editClient(updatedClient: ClientDetailsModel): Observable<ClientDetailsModel> {
        return this.apiService.put<ClientDetailsModel>(`${this._apiUrl}`, updatedClient);
    }

    archiveClient(clientId: number): Observable<void> {
        return this.apiService.delete<void>(`${this._apiUrl}/${clientId}`);
    }

    getClient(clientId: number): Observable<ClientModel> {
        return this.apiService.get<ClientModel>(`${this._apiUrl}/${clientId}`);
    }

    generateInvoices(requestModel: InvoiceRequestModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUrl}/generateInvoices`, requestModel);
    }
}
