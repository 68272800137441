import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";

import { ReviewModel } from "../models/reviews/ReviewModel";

import { AddReviewModel } from "../models/reviews/AddReviewModel";
import { PerformReviewModel } from "../models/reviews/PerformReviewModel";
import { EditReviewModel } from "../models/reviews/EditReviewModel";
import { ReviewAssignees } from "../models/reviews/ReviewAssignees";
import { ReviewersByRole } from "../models/reviews/ReviewersByRole";
import { ReviewStatusModel } from "../models/review-status/ReviewStatusModel";
import { NextReviewDateModel } from "../models/review-status/NextReviewDateModel";
import { ReviewPdf } from "../models/reviews/ReviewPdf";

@Injectable({
    providedIn: "root",
})
export class ReviewService {
    private _apiBaseUrl = ApiConstants.REVIEW_API_ENDPOINT;

    constructor(private apiService: ApiService) { }

    public getUserReviews(userId: number): Observable<ReviewModel[]> {
        return this.apiService.get<ReviewModel[]>(`${this._apiBaseUrl}/${userId}`);
    }

    public addReview(review: AddReviewModel): Observable<ReviewModel> {
        return this.apiService.post<ReviewModel>(`${this._apiBaseUrl}`, review);
    }

    public editReview(review: EditReviewModel): Observable<ReviewModel> {
        return this.apiService.put<ReviewModel>(`${this._apiBaseUrl}/edit`, review);
    }

    public performReview(review: PerformReviewModel): Observable<ReviewModel[]> {
        return this.apiService.put<ReviewModel[]>(`${this._apiBaseUrl}/perform`, review);
    }

    public deleteReview(reviewId: number): Observable<ReviewModel> {
        return this.apiService.delete<ReviewModel>(`${this._apiBaseUrl}/${reviewId}`);
    }

    public getReviewsForUser(): Observable<ReviewModel[]> {
        return this.apiService.get<ReviewModel[]>(`${this._apiBaseUrl}/user`);
    }

    public getReviewsForAllUsers(): Observable<ReviewModel[]> {
        return this.apiService.get<ReviewModel[]>(`${this._apiBaseUrl}/upcomingReviews`);
    }

    public saveReviewAssignee(reviewAssignees: ReviewAssignees): Observable<ReviewAssignees> {
        return this.apiService.post<ReviewAssignees>(`${this._apiBaseUrl}/saveReviewAssignees`, reviewAssignees);
    }

    public getReviewsWithTypeReview(): Observable<ReviewModel[]> {
        return this.apiService.get<ReviewModel[]>(`${this._apiBaseUrl}/reviewsForDM`);
    }

    public getReviewAssignees(reviewId: number): Observable<ReviewersByRole[]> {
        return this.apiService.get<ReviewersByRole[]>(`${this._apiBaseUrl}/getReviewAssignees/${reviewId}`);
    }

    getReviewPDF(formId: number): Observable<ReviewPdf> {
        return this.apiService.get<ReviewPdf>(`${this._apiBaseUrl}/pdf/${formId}`);
    }

    public editReviewAssignee(editReviewAssignees: ReviewAssignees): Observable<ReviewAssignees> {
        return this.apiService.put<ReviewAssignees>(`${this._apiBaseUrl}/editReviewAssignees`, editReviewAssignees);
    }

    public getReviewStatusDetails(reviewId: number): Observable<ReviewStatusModel> {
        return this.apiService.get<ReviewStatusModel>(`${this._apiBaseUrl}/reviewStatus/${reviewId}`);
    }

    public completeReview(review: NextReviewDateModel): Observable<ReviewModel> {
        return this.apiService.put<ReviewModel>(`${this._apiBaseUrl}/complete`, review);
    }
}
