import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize, takeUntil } from "rxjs/operators";

import { DialogService } from "src/app/shared/services/dialog.service";
import { clone, createDateComponent } from "src/app/shared/utils/Utils";
import { NameNotificationService } from "src/app/shared/services/name-notification.service";
import { LoadableComponentBase } from "src/app/shared/utils/LoadableComponentBase";
import { formatDate } from "src/app/shared/utils/Formatters";

import { UserService } from "src/app/services/user.service";

import { UserInfoModel } from "src/app/models/users/UserInfoModel";

import { PatternConstants } from "src/app/constants/pattern-constants";
import { DateTimeModel } from "src/app/models/DateTimeModel";
import { ChildrenModel } from "src/app/models/users/ChildrenModel";
@Component({
  selector: "app-my-details",
  templateUrl: "./my-details.component.html",
  styleUrls: ["./my-details.component.scss"],
})
export class MyDetailsComponent extends LoadableComponentBase implements OnInit {

  user: UserInfoModel;
  userClone: UserInfoModel;
  isModalButtonEnabled = true;
  birthdayHasValue = true;
  idCardExpirationDateHasValue = true;
  childrenLeftSide: ChildrenModel[];
  childrenRightSide: ChildrenModel[];
  formatDateForTable = formatDate;

  currentDate = new Date();
  phonePattern = PatternConstants.PHONE_NUMBER_PATTERN;
  emailPattern = PatternConstants.EMAIL_PATTERN;
  namePattern = PatternConstants.NAME_PATTERN;
  cnpPattern = PatternConstants.CNP_PATTERN;
  idCardNumberPattern = PatternConstants.ID_CARD_NUMBER_PATTERN;
  maxBirthdate: number;
  missingDataForChild: string[] = [];

  @ViewChild("detailsModal", { static: true }) modal: ModalDirective;

  constructor(
    private userService: UserService,
    private dialogService: DialogService,
    private nameNotificationService: NameNotificationService,
  ) {
    super();
    this.maxBirthdate = Date.now();
  }

  ngOnInit(): void {
    this.startLoader();
    this._getCurrentUserDetails();
  }

  private _getCurrentUserDetails() {
    this.userService.getCurrentUserDetails()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe((result) => {
        this.user = result;
        this._initializeChildrenSides();
      });
  }

  createChildField() {
    let item = new ChildrenModel();
    this.userClone.children.push(item);
  }


  removeChildField(index: number) {
    if (index !== -1) {
      this.userClone.children.splice(index, 1);
    }
  }

  public openEditModal() {
    this.userClone = clone(this.user);
    this.modal.show();
  }

  public closeEditModal() {
    this.birthdayHasValue = true;
    this.idCardExpirationDateHasValue = true;
    this.modal.hide()
    this.missingDataForChild = [];
  }

  saveChanges() {
    if (this._validateChildren()) {
      return;
    }

    this.isModalButtonEnabled = false

    if (!this.userClone.birthday || !this.userClone.idCardExpirationDate) {
      this.birthdayHasValue = !!this.userClone.birthday;
      this.idCardExpirationDateHasValue = !!this.userClone.idCardExpirationDate;
      this.isModalButtonEnabled = true;
      return;
    }

    this.userClone.birthdayDateComponent = createDateComponent(this.userClone.birthday);
    this.userClone.idCardExpirationDateComponent = createDateComponent(this.userClone.idCardExpirationDate);
    this.userClone.medicalExaminationDateComponent = createDateComponent(this.userClone.medicalExaminationDate);
    this.userClone.employmentDateComponent = createDateComponent(this.userClone.employmentDate);
    this.userClone.leavingDateComponent = createDateComponent(this.userClone.leavingDate);

    this.userClone.children.forEach(children => {
      const birthdayDate = new Date(children.birthday);
      children.birthdayComponent = new DateTimeModel(birthdayDate.getDate(), birthdayDate.getMonth() + 1, birthdayDate.getFullYear())
    })
    this.userService.editUserDetails(this.userClone)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (result) => {
        this.user = result;
        this._initializeChildrenSides();
        this.nameNotificationService.notifyNameRetrieved(this.user);
        this.closeEditModal();
        this.userClone = null;
        this.dialogService.showSimpleDialog(
          "Success",
          "Details were updated successfully",
          "success"
        );
        this.isModalButtonEnabled = true;
      },
      (err) => {
        this.closeEditModal();
        this.userClone = null;
        this.dialogService.showSimpleDialog("Error!", err, "error");
        this.isModalButtonEnabled = true;
      }
    );
  }

  private _initializeChildrenSides() {
    if (this.user) {
      this.childrenLeftSide = this.user.children.filter((_, index) => { return (index % 2 === 0) });
      this.childrenRightSide = this.user.children.filter((_, index) => { return (index % 2 != 0) });
    }
  }

  private _validateChildren(): boolean {
    let existsMissingData = false;
    this.missingDataForChild = [];
    this.userClone.children.forEach((value, index) => {
      if (!Object.keys(value).length || Object.keys(value).length < 2) {
        this.missingDataForChild.push(index.toString());
        existsMissingData = true;
      }
    });
    return existsMissingData;
  }
}
