import { Component, OnInit, Input } from "@angular/core";
import { ICellRendererParams, RowNode } from "ag-grid-community";
import { finalize, takeUntil } from "rxjs/operators";
import { InvoiceRequestModel, LAST_MONTH, MONTH_TO_DATE } from "src/app/models/projectModels/InvoiceRequestModel";
import { ProjectModel } from "src/app/models/projectModels/ProjectModel";
import { ProjectService } from "src/app/services/project.service";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { DialogService } from "src/app/shared/services/dialog.service";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { clone } from "src/app/shared/utils/Utils";

@Component({
  selector: "trackify-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"],
})
export class ProjectComponent extends GridComponentBase<ProjectModel> implements OnInit {
  public displayArchived: Boolean = false;
  public archivedProjects: ProjectModel[] = [];
  areSelectedRows: boolean = false;
  _selectedNodes: RowNode[] = [];
  isDropdownOpen: boolean = false;

  @Input() isInTab: boolean = false
  @Input() clientId;

  constructor(
    private dialogService: DialogService,
    private projectService: ProjectService,
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        field: 'isChecked',
        width: 50,
        suppressSizeToFit: true,
        pinned: 'left',
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        field: "coloredCell",
        headerName: "Color",
        width: 100,
        suppressSizeToFit: true,
        cellStyle: function (params) {
          return { "background-color": params.data.color };
        },
      },
      {
        field: "name",
        headerName: "Name",
        width: 400,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => (x.data.clientName ? x.data.clientName : "N/A"),
        headerName: "Client",
        width: 400,
        suppressSizeToFit: false,
      },
      {
        field: "isArchived",
        headerName: "Archived",
        width: 150,
        hide: true,
        suppressSizeToFit: false,
      },
      {
        headerName: "Archive",
        headerClass: "text-center",
        type: ["buttonColumn"],
        pinned: 'right',
        cellRendererParams: {
          buttonClass: "btn btn-primary btn-xs",
          buttonText: "",
          iconClass: "fas fa-ban white-color",
          shouldCheckArchiveStatus: true,
          onClick: this._archiveProject.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
      //Hide Monthy Report button from now
      // {
      //   headerName: "Monthly Report",
      //   headerClass: "text-center",
      //   type: ["buttonColumn"],
      //   pinned: 'right',
      //   cellRendererParams: {
      //     buttonClass: "btn btn-success btn-xs",
      //     buttonText: "",
      //     iconClass: "fas fa-chart-line",
      //     onClick: this._exportMonthlyReport.bind(this),
      //   },ng serve
      //   cellRenderer: "buttonCellRenderer",
      // }
    ]);

    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No Projects</span>';
  }

  ngOnInit(): void {
    this.startLoader();
    if (this.clientId) {
      this.projectService.getAllUnarchivedProjectsForClient(this.clientId)
        .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
        .subscribe(projects => {
          this.data = projects;
        });
    }
    else {
      this.projectService.getAllUnarchivedProjects()
        .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
        .subscribe(projects => {
          this.data = projects;
        });
    }
  }

  onRowSelected(_$event: any): void {
    if (!_$event.node.isSelected()) {
      this.getSelectedRows();
      return;
    }

    this.currentSelection = clone(_$event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(project => project.id == this.currentSelection.id));

    this.getSelectedRows();
  }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  getInvoiceForLastMonth(): void {
    this._invoicesConfirmation(LAST_MONTH);
  }

  getInvoiceForMonthToDate(): void {

    this._invoicesConfirmation(MONTH_TO_DATE);
  }

  public showHideArchive(): void {
    this.startLoader();
    this.displayArchived = !this.displayArchived;
    if (this.displayArchived) {
      this.gridOptions.columnApi.setColumnVisible("isArchived", true);
      if (this.clientId) {
        this.projectService.getAllProjectsForClient(this.clientId)
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
          .subscribe(projects => {
            this.data = projects;
          });
      }
      else {
        this.projectService.getAllProjects()
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
          .subscribe(projects => {
            this.data = projects;
          });
      }
    } else {
      this.gridOptions.columnApi.setColumnVisible("isArchived", false);
      if (this.clientId) {
        this.projectService.getAllUnarchivedProjectsForClient(this.clientId)
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
          .subscribe(projects => {
            this.data = projects;
          });
      }
      else {
        this.projectService.getAllUnarchivedProjects()
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
          .subscribe(projects => {
            this.data = projects;
          });
      }
      this.updateGridAfterDelete();
    }
    this.gridOptions.api.sizeColumnsToFit();
  }

  // private _exportMonthlyReport(): void {
  //   console.log("This method will export your monthly report");
  // }

  onSelectionChanged(): void {
    this._selectedNodes = this._getRowsAfterFilter();

    if (this._selectedNodes.length != 1) {
      return;
    }

    this.areSelectedRows = true;
    this.currentSelection = this._selectedNodes[0].data;
  }

  getSelectedRows(): void {
    this._selectedNodes = this._getRowsAfterFilter();

    if (this._selectedNodes.length == 0 || this._selectedNodes.length > 1) {
      this.currentSelection = null;
    }

    this.areSelectedRows = !!this._selectedNodes.length;
  }

  private _getRowsAfterFilter(): RowNode[] {
    this._selectedNodes = [];
    this.gridOptions.api.forEachNodeAfterFilter(node => {
      if (node.isSelected()) {
        this.isDropdownOpen = false;
        this._selectedNodes.push(node);
      }
    });

    return this._selectedNodes;
  }

  private _invoicesConfirmation(month: number): void {
    let selectionData = this._selectedNodes.map(element => element.data);
    let projectsName = selectionData.map(project => project.name);

    if(selectionData.some(p => p.isArchived)){
      this.dialogService.showErrorMessage("Denied","Can't generate invoices for archived projects");
      return;
    }

    if(projectsName.length > 8){
      projectsName = projectsName.slice(0,8);
      projectsName.push('etc.');
    }

    this.dialogService.showDialog({
      title: `Are you sure you want to generate invoices for the following projects: ${projectsName.join(', ')}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DC3545',
      confirmButtonText: 'Generate'
    }).then((result) => {

      if (!result.value) {
        this.gridOptions.api.deselectAll();
        this.toggleDropdown();
        return;
      }

      this.gridOptions.api.showLoadingOverlay();
      let model = new InvoiceRequestModel();
      model.ids = selectionData.map(client => client.id);
      model.month = month;
      
      this._generateInvoices(model);
    });
  }

  private _generateInvoices(model: InvoiceRequestModel): void {
    this.projectService.generateInvoices(model)
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
        this.gridOptions.api.deselectAll();
        this.gridOptions.api.hideOverlay();
      }))
      .subscribe(_ => {
        this.getSelectedRows();
        this.dialogService.showSuccessMessage('Success!', 'Invoice Generated');
      },
        err => {
          this.dialogService.showSimpleDialog("Error", err, "error");
        })
  }

  private _archiveProject(obj: ICellRendererParams): void {

    if (obj.data.isArchived) {
      this.dialogService.showSimpleDialog('Error!', "This project is already archived", 'error');
      return;
    }

    this.dialogService.showDialog({
      title: `Are you sure you want to archive this project?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DC3545',
      confirmButtonText: 'Archive'
    }).then(result => {
      if (!!result.value) {
        this.gridOptions.api.showLoadingOverlay()
        this.projectService.archiveProject(this.currentSelection.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            if (!this.displayArchived) {
              this.updateGridAfterDelete();
            } else {
              obj.data.isArchived = !obj.data.isArchived;
              this.gridOptions.api.refreshCells();
              this.updateGrid(this.data);
            }

            this.currentSelection = null;
            this.dialogService.showSuccessMessage('Success!', 'Selected project archived successfully.');
          });
      }
    });
  }
}
