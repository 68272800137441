import { Injectable } from "@angular/core";
import { ApiConstants } from "../constants/api-constants";

@Injectable({
  providedIn: "root",
})
export class DataExportService {
  constructor() { }
  private _apiExport = ApiConstants.EXPORT_DATA_API_ENDPOINT;

  exportBankSalaryData(companyId: number, monthNumber: number, year: number) {
    window.open(`${this._apiExport}/bank-salaries/${companyId}/${monthNumber}/${year}`, "_blank");
  }

  exportFoodTicketsData(companyId: number, monthNumber: number, year: number) {
    window.open(`${this._apiExport}/food-tickets/${companyId}/${monthNumber}/${year}`, "_blank");
  }

  exportHolidayDataForAll(monthNumber: number, year: number) {
    window.open(`${this._apiExport}/holidays/${monthNumber}/${year}`, "_blank");
  }

  exportFoodTicketsDataForAll(monthNumber: number, year: number) {
    window.open(`${this._apiExport}/food-tickets/${monthNumber}/${year}`, "_blank");
  }

  exportSalaryDataForAll(monthNumber: number, year: number) {
    window.open(`${this._apiExport}/salaries/${monthNumber}/${year}`, "_blank");
  }

  exportBankSalaryDataForAll(monthNumber: number, year: number) {
    window.open(`${this._apiExport}/bank-salaries/${monthNumber}/${year}`, "_blank");
  }
}