import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MonthlyStats } from 'src/app/constants/monthly-stats';
import { MonthSimpleModel } from 'src/app/models/monthhDetails/MonthSimpleModel';

@Component({
  selector: 'trackify-month-year-picker',
  templateUrl: './month-year-picker.component.html',
  styleUrls: ['./month-year-picker.component.scss']
})
export class MonthYearPickerComponent implements OnInit {
  @Input() years: number[];
  @Input() months: MonthSimpleModel[];
  @Input() yearToDisplay: number;
  @Input() monthToDisplay: number;

  @Output() onMonthSelectionEvent = new EventEmitter<Number>();
  @Output() onYearSelectionEvent = new EventEmitter<Number>();

  all_months: number = -1;
  all_years: string = MonthlyStats.ALL_DATA;

  constructor() { }

  ngOnInit(): void {
  }

  onMonthSelection(month: number) {
    this.onMonthSelectionEvent.emit(month);
  }
  onYearSelection(year: number) {
    this.onYearSelectionEvent.emit(year);
  }
}
