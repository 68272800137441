import { DateTimeModel } from "../DateTimeModel";

export class AddReviewModel {
    userId: number;
    reviewTypeId: number;
    reviewDate: string;
    reviewDateComponent: DateTimeModel;

    constructor() {
        this.reviewTypeId = null;
    }
}