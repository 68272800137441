import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize, takeUntil } from 'rxjs/operators';
import { BenefitOneTimeCardModel } from 'src/app/models/user-benefits/BenefitOneTimeCardModel';
import { BenefitRequestService } from 'src/app/services/benefits/benefitRequest.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';

@Component({
  selector: 'trackify-one-time-modal',
  templateUrl: './one-time-modal.component.html',
  styleUrls: ['./one-time-modal.component.scss']
})
export class OneTimeModalComponent extends LoadableComponentBase {

  benefitCard: BenefitOneTimeCardModel;

  constructor(private modalRef: BsModalRef,
    private benefitRequestService: BenefitRequestService,
    private dialogService: DialogService) {
    super();
  }

  hideModal(): void {
    this.modalRef.hide();
  }

  requestBenefit(): void {
    this.startLoader();
    this.benefitRequestService.createOneTimeBenefitRequest(this.benefitCard.id)
      .pipe(takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.stopLoader();
          this.modalRef.hide();
        }))
      .subscribe( _ => {
        this.dialogService.showSuccessMessage(
          "Success!",
          `A request for ${this.benefitCard.name} has been created!`
        );
        this.modalRef.content = { hasPendingRequest: true };
      },
        (err) => {
          this.dialogService.showSimpleDialog("Error", err, "error");
        });
  }
}
