import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';
import { ReviewAssignees } from "src/app/models/reviews/ReviewAssignees";
import { ReviewersByRole } from "src/app/models/reviews/ReviewersByRole";
import { ReviewerRoleEnum } from "src/app/models/reviews/ReviewerRoleEnum";
import { UserService } from "src/app/services/user.service";
import { UsersByRole } from "src/app/models/users/UsersByRole";
import { UserDetails } from "src/app/models/users/UserDetails";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ReviewService } from 'src/app/services/review.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { finalize, takeUntil } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ReviewStatusEnum } from 'src/app/models/reviews/ReviewStatusEnum';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'review-assign-modal-component',
  templateUrl: './review-assign-modal.component.html',
  styleUrls: ['./review-assign-modal.component.scss']
})
export class ReviewAssignModalComponent extends LoadableComponentBase implements OnInit {
  reviewId: number;
  userFullName: any;
  reviewStatus: string;
  userData: ReviewersByRole[] = [];
  selectedReviewersByRole: ReviewersByRole[] = [];
  reviewAssignee: ReviewAssignees = {
    reviewId: 0,
    reviewers: []
  };
  ReviewerRoleEnum = ReviewerRoleEnum;
  dropdownSettings: IDropdownSettings = {
    textField: 'fullName',
    allowSearchFilter: true
  };
  dmDropdownSettings: IDropdownSettings = {
    textField: 'fullName',
    allowSearchFilter: true,
    singleSelection: true
  };
  assigneeForm: FormGroup = new FormGroup({
    DM: new FormControl([], Validators.required),
    PMs: new FormControl([]),
    TLs: new FormControl([]),
    TEAM: new FormControl([])
  },
  {
    validators: [this.assignationValidator()]
  }
  );

  constructor(
    private modalRef: BsModalRef,
    private reviewService: ReviewService,
    private dialogService: DialogService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.startLoader();
    this.getAssignResponsibles();
  }

  getAssignResponsibles(): void {
    this.reviewAssignee.reviewId = this.reviewId;
    this.startLoader();

    forkJoin([
      this.userService.getUsersByRole(),
      this.reviewService.getReviewAssignees(this.reviewAssignee.reviewId)
    ]).pipe(takeUntil(this.ngUnsubscribe),
      finalize(() => {
        this.stopLoader();
        this.changeDetectorRef.detectChanges();
      }))
      .subscribe(
        ([allUsersByRole, alreadyAssignedReviewers]) => {
          this._mapData(allUsersByRole);
          this._populateForm(alreadyAssignedReviewers);
        });
  }

  getRoleIndex(role: ReviewerRoleEnum): number {
    let index: number;
    switch (role) {
      case ReviewerRoleEnum.DM:
        index = 0;
        break;
      case ReviewerRoleEnum.PM:
        index = 1;
        break;
      case ReviewerRoleEnum.TL:
        index = 2;
        break;
      case ReviewerRoleEnum.TEAM:
        index = 3;
        break;
      default:
        break;
    }
    return index;
  }

  isDMAssigned(): boolean {
    return this.assigneeForm.get('DM').value.length != 0;
  }

  assignationValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const pm = form.get("PMs").value;
      const tl = form.get("TLs").value;
      const team = form.get("TEAM").value;

      if (this.haveSameUser(pm, tl) || this.haveSameUser(pm, team) || this.haveSameUser(tl, team)) {
        return { assignationError: true };
      }

      return null;
    }
  }

  haveSameUser(users1: UserDetails[], users2: UserDetails[]): boolean {
    return users1.some(user1 => users2.some(user2 => user1.id === user2.id));
  }

  isInvalidAssignation(): boolean {
    return this.assigneeForm.hasError("assignationError");
  }

  isReviewCompleted(): boolean {
    return this.reviewStatus == ReviewStatusEnum.Completed;
  }

  isFirstAssignation(): boolean {
    return this.reviewStatus == ReviewStatusEnum.Pending;
  }

  onSubmit(): void {
    if (!this.isDMAssigned()) {
      return;
    }

    if (this.isInvalidAssignation()) {
      return;
    }

    this._mapToModel();
    this.startLoader();
    this.reviewService.saveReviewAssignee(this.reviewAssignee)
      .pipe(takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.stopLoader();
          this.onHideModal();
        }))
      .subscribe(() => {
        this.dialogService.showSuccessMessage(
          "Success!",
          "The reviewers were assigned"
        );
      },
        (error) => {
          this.dialogService.showErrorMessage(
            "Error",
            error
          );
        })
  }

  onHideModal(): void {
    this.modalRef.hide();
  }

  private _reviewersByRole(role: ReviewerRoleEnum, alreadyAssignedReviewers: ReviewersByRole[]): UserDetails[] {
    const entry = alreadyAssignedReviewers.find(item => item.reviewerRole == role);

    if (entry) {
      return entry.reviewers;
    }

    return [];
  }

  private _populateForm(alreadyAssignedReviewers: ReviewersByRole[]): void {
    this.assigneeForm.setValue({
      DM: this._reviewersByRole(ReviewerRoleEnum.DM, alreadyAssignedReviewers),
      PMs: this._reviewersByRole(ReviewerRoleEnum.PM, alreadyAssignedReviewers),
      TLs: this._reviewersByRole(ReviewerRoleEnum.TL, alreadyAssignedReviewers),
      TEAM: this._reviewersByRole(ReviewerRoleEnum.TEAM, alreadyAssignedReviewers),
    });
  }

  private _mapData(usersByRole: UsersByRole): void {
    this.userData = [];
    this.userData.push(<ReviewersByRole>{
      reviewerRole: ReviewerRoleEnum.DM,
      reviewers: usersByRole.dms ? usersByRole.dms : []
    });

    this.userData.push(<ReviewersByRole>{
      reviewerRole: ReviewerRoleEnum.PM,
      reviewers: usersByRole.pms ? usersByRole.pms : []
    });

    this.userData.push(<ReviewersByRole>{
      reviewerRole: ReviewerRoleEnum.TL,
      reviewers: usersByRole.tls ? usersByRole.tls : []
    });

    this.userData.push(<ReviewersByRole>{
      reviewerRole: ReviewerRoleEnum.TEAM,
      reviewers: usersByRole.devs ? usersByRole.devs : []
    });
  }

  private _mapToModel(): void {
    const dmValue = this.assigneeForm.get('DM').value;
    const pmsValue = this.assigneeForm.get('PMs').value;
    const tlsValue = this.assigneeForm.get('TLs').value;
    const teamValue = this.assigneeForm.get('TEAM').value;

    const dmReviewer: ReviewersByRole = {
      reviewerRole: ReviewerRoleEnum.DM,
      reviewers: dmValue ? dmValue : []
    };
    const pmReviewer: ReviewersByRole = {
      reviewerRole: ReviewerRoleEnum.PM,
      reviewers: pmsValue ? pmsValue : []
    };
    const tlReviewer: ReviewersByRole = {
      reviewerRole: ReviewerRoleEnum.TL,
      reviewers: tlsValue ? tlsValue : []
    };
    const teamReviewer: ReviewersByRole = {
      reviewerRole: ReviewerRoleEnum.TEAM,
      reviewers: teamValue ? teamValue : []
    };

    this.reviewAssignee.reviewers = [dmReviewer, pmReviewer, tlReviewer, teamReviewer];
  }
}