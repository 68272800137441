import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { finalize, takeUntil } from "rxjs/operators";

import { LoadableComponentBase } from "src/app/shared/utils/LoadableComponentBase";
import { NavMenuItem } from "../utils/NavMenuItem";
import { appMenuItems, clientsMenuItem, projectsMenuItem, benefitsMenuItems } from "../utils/MenuConfig";
import { UserService } from "src/app/services/user.service";
import { RouterEventsService } from "src/app/shared/services/router-events.service";
import { RouteConstants } from "src/app/constants/route-constants";
import { RoleType } from "src/app/models/users/RoleType";
import { MenuItemsSettingsService } from "src/app/services/menu-items-settings.service";

@Component({
  selector: "trackify-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent extends LoadableComponentBase implements OnInit {
  baseMenuItems: NavMenuItem[] = appMenuItems;
  menuItems: NavMenuItem[] = [];
  
  constructor(private router: Router,
    private userService: UserService,
    private routerEventSerive: RouterEventsService,
    private menuItemsSettingsService: MenuItemsSettingsService) { super(); }

  ngOnInit() {
    this.startLoader();
    this._getUserRole();
  }

  private _getUserRole() {
    const previousUrl = this.routerEventSerive.getPrevUrl();
    this.userService.getUserRole()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe(role => {
        //TO DO: To remove the subscribe inside another subscribe
        this.menuItemsSettingsService.getMenuItemsSettings()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(result => {
            if (result.showBenefits) {
              this.baseMenuItems.push(...benefitsMenuItems);
            }

            if (result.showClients && role.id == RoleType.Admin) {
              this.baseMenuItems.push(clientsMenuItem);
            }

            if (result.showProjects) {
              this.baseMenuItems.push(projectsMenuItem);
            }

            if (role.id == RoleType.Admin || role.id == RoleType.Hr) {
              this.menuItems = this.baseMenuItems.filter(item => (item.minRole >= role.id));
              this._sortMenuItemsAlphabetically();
              if (!previousUrl || previousUrl == "/") {
                this.router.navigate([RouteConstants.ADMIN_DASHBOARD]);
              }
            } else {
              this.menuItems = this.baseMenuItems.filter(item => (item.minRole <= role.id));
              this._sortMenuItemsAlphabetically();
              if (!previousUrl || previousUrl == "/") {
                this.router.navigate([RouteConstants.USER_DASHBOARD]);
              }
            }
            if (previousUrl && previousUrl != "/") {
              this.router.navigate([previousUrl]);
            }
          });
      });
  }

  isMenuItemActive(menuItem: NavMenuItem) {
    const routeElements = this.router.routerState.snapshot.url.split("/");
    const currentUrl = routeElements[routeElements.length - 1];
    return currentUrl === menuItem.route.path;
  }

  controlDropdown(menuItem: NavMenuItem) {
    menuItem.isDropDownActive = !menuItem.isDropDownActive;
  }

  onMenuItemClicked(path: string) {
    if (path == "/") return;
    this.router.navigate([path]);
  }

  private _sortMenuItemsAlphabetically(): void {
    this.menuItems.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
}
