import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { Observable, forkJoin } from 'rxjs'
import { ModalDirective } from 'ngx-bootstrap/modal'
import { finalize, takeUntil } from 'rxjs/operators'
import * as moment from 'moment'

import { MonthlyStats } from 'src/app/constants/monthly-stats'
import { clone } from 'src/app/shared/utils/Utils'
import { DialogService } from '../../shared/services/dialog.service'

import { MonthDetailsService } from 'src/app/services/month-details.service'
import { MonthlySalaryService } from 'src/app/services/monthly-salary.service'

import { MonthSimpleModel } from 'src/app/models/monthhDetails/MonthSimpleModel'
import { MonthlySalaryStatsModel } from 'src/app/models/monthlyStats/MonthlySalaryStatsModel'
import { ConfirmHolidayTakenModel } from 'src/app/models/monthlyStats/ConfirmHolidayTakenModel'
import { DataExportService } from 'src/app/services/data-export.service'
import { CompanyService } from '../../services/company.service'
import { Company } from '../../models/companies/Company'
import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase'
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils'

@Component({
  selector: 'trackify-monthly-status',
  templateUrl: './monthly-status.component.html',
  styleUrls: ['./monthly-status.component.scss'],
})
export class MonthlyStatusComponent
  extends GridComponentBase<MonthlySalaryStatsModel>
  implements OnInit {
  @Input() isRegularUser: boolean = false

  currentDate: string;
  monthToDisplay: number;
  yearToDisplay: number;
  months: MonthSimpleModel[] = [];
  years: number[] = [];
  all_months: number = -1;
  all_years: string = MonthlyStats.ALL_DATA;
  stat: MonthlySalaryStatsModel;
  companies: Company[];
  selectedCompanyId: number;
  areExportButtonsDisabled = false;
  isFoodTicketSelected = false;
  isConfirmAvailable: boolean = false;

  exportOptionSelected: number = 1;
  currentMonthForExport: number;
  previouSelectedMonthForExport: number;
  previouSelectedMonthNameForExport: string;
  previouSelectedYearForExport: number;

  selectedMonthNumber: number;
  isFoodTicketForArchive: boolean = false;

  private monthlyStats: MonthlySalaryStatsModel[] = []

  private selectedMonth: number = -1;
  private selectedYear: string = MonthlyStats.ALL_DATA

  isOvertimeForSalary = true;

  @ViewChild('statsEditModal', { static: true }) modal: ModalDirective
  @ViewChild('statsExportModal', { static: true }) exportModal: ModalDirective

  constructor(
    private monthDetailService: MonthDetailsService,
    private salaryService: MonthlySalaryService,
    private dialogService: DialogService,
    private dataExport: DataExportService,
    private companyService: CompanyService,
  ) {
    super()
    AgGridUtils.addDefaultColumnTypes(this.columnTypes)

    this.addColumnDefs([

      {
        field: 'userMonthlyStats.year',
        headerName: 'Year',
        width: 80,
        suppressSizeToFit: true
      },
      {
        field: 'userMonthlyStats.monthName',
        headerName: 'Month',
        width: 95,
        suppressSizeToFit: true
      },
      {
        valueGetter: (x) => 
          (x.data.isConfirmed 
            ? (x.data.userMonthlyStats.confirmedBy && x.data.userMonthlyStats.confirmedAt 
              ? `Confirmed by ${x.data.userMonthlyStats.confirmedBy} on ${x.data.userMonthlyStats.confirmedAt}`
              : 'Confirmed')
              : 'Not Confirmed'
          ),
        headerName: 'Status',
        width: 370,
        suppressSizeToFit: true,
        cellStyle: function (params) {
          if (params.data.isConfirmed) {
            return { backgroundColor: "lightgreen" };
          } else {
            return { backgroundColor: "lightsalmon" };
          }
        }
      },
      {
        field: 'userMonthlyStats.targetHours',
        headerName: 'Target',
        width: 95,
        suppressSizeToFit: true
      },
      {
        field: 'userMonthlyStats.totalHoursInMonth',
        headerName: 'Total Hours',
        width: 90,
        suppressSizeToFit: true
      },
      {
        valueGetter: (x) =>
          x.data.userMonthlyStats.freeHours == 0
            ? 0
            : x.data.userMonthlyStats.freeHours,
        headerName: 'Free Hours',
        width: 95,
        suppressSizeToFit: true
      },
      {
        field: 'userMonthlyStats.freeDays',
        headerName: 'Number of Free Days',
        width: 120,
        suppressSizeToFit: true
      },
      {
        field: 'userMonthlyStats.workingDays',
        headerName: 'Working Days',
        width: 105,
        suppressSizeToFit: true
      },
      {
        field: 'userMonthlyStats.dailyHours',
        headerName: 'Hours / Day',
        width: 95,
        suppressSizeToFit: true
      },
      {
        valueGetter: (x) =>
          x.data.userMonthlyStats.workedHours == 0
            ? 0
            : x.data.userMonthlyStats.workedHours,
        headerName: 'Worked Hours',
        width: 100,
        suppressSizeToFit: true,
        valueFormatter: (params) =>
          params.data.userMonthlyStats.workedHours.toFixed(2),
      },
      {
        valueGetter: x => x.data.userMonthlyStats.notPaidHolidayHours > 0
          ? `${(x.data.userMonthlyStats.notPaidHolidayHours + x.data.userMonthlyStats.holidayHours).toFixed(2)} (${x.data.userMonthlyStats.notPaidHolidayHours.toFixed(2)}h unpaid)`
          : x.data.userMonthlyStats.holidayHours > 0 ? x.data.userMonthlyStats.holidayHours.toFixed(2) : 0,
        headerName: 'Holiday Hours Taken',
        width: 130,
        suppressSizeToFit: true
      },
      {
        field: 'userMonthlyStats.medicalHolidayHours',
        headerName: 'Medical Free Hours',
        width: 120,
        suppressSizeToFit: true
      },
      {
        field: 'userMonthlyStats.specialHolidayHours',
        headerName: 'Special Holiday Hours',
        width: 140,
        suppressSizeToFit: true
      },
      {
        field: 'userMonthlyStats.generatedHolidays',
        headerName: 'Holiday Hours Resulted',
        width: 140,
        suppressSizeToFit: true,
        valueFormatter: (params) =>
          params.data.userMonthlyStats.generatedHolidays.toFixed(2),
      },
      {
        field: 'userMonthlyStats.holidaysRemaining',
        headerName: 'Holiday Hours Left',
        width: 130,
        suppressSizeToFit: true,
        valueFormatter: (params) =>
          params.data.userMonthlyStats.holidaysRemaining.toFixed(2),
      },
      {
        field: "userMonthlyStats.salaryMultiplicator",
        headerName: "Salary Multiplicator",
        width: 132,
        suppressSizeToFit: true,
        valueFormatter: (params) =>
          params.data.userMonthlyStats.salaryMultiplicator.toFixed(4),
      },
      {
        field: "userMonthlyStats.initialPoints",
        headerName: "Initial Benefit Points",
        width: 150,
        suppressSizeToFit: true,
        valueFormatter: (params) => 
          params.data.userMonthlyStats.initialPoints.toFixed(2),
      },
      {
        field: "userMonthlyStats.remainingPoints",
        headerName: "Remaining Benefit Points",
        minWidth: 150,
        suppressSizeToFit: false,
        valueFormatter: (params) => 
          params.data.userMonthlyStats.remainingPoints.toFixed(2),
      },
      {
        headerName: 'Confirm',
        pinned: 'right',
        type: ['buttonColumn'],
        cellRendererParams: {
          buttonClass: 'btn btn-success btn-xs',
          buttonText: '',
          iconClass: 'fa fa-edit',
          onClick: this._onEdit.bind(this),
        },
      },
    ])

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No monthly status entries.</span>';
  }

  addSpecificColumns() {
    if (this.isRegularUser) {
      this.addColumnDefs([
        {
          headerName: 'Refresh',
          pinned: 'right',
          type: ['buttonColumn'],
          cellRendererParams: {
            buttonClass: 'btn btn-primary btn-simple ',
            buttonText: '',
            iconClass: 'fas fa-redo',
            onClick: this._onRefresh.bind(this),
          }
        }
      ]);
    }
    else {
      this.addColumnDefs([
        {
          field: 'username',
          headerName: 'User',
          pinned: 'left',
          width: 132,
          suppressSizeToFit: true,
        }
      ]);
    }
  }

  ngOnInit() {
    this.startLoader()
    this.addSpecificColumns()

    this.monthToDisplay = moment().month()
    this.yearToDisplay = moment().year()

    if (this.isRegularUser) {
      this._setDataForRegularUser()
    } else {
      this._setDataForAdmin()
    }

    this.selectedYear = this.yearToDisplay.toString();
    this.selectedMonth = this.monthToDisplay;
  }

  private _setDataForRegularUser() {
    this.setDataForRegularUser()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe(
        ([months, stats]) => {
          this.setMonths(months)
          this.monthlyStats = stats
          if (this.monthToDisplay == 0) {
            this.monthToDisplay = 12
            this.yearToDisplay--;

            this.selectedYear = this.yearToDisplay.toString();
            this.selectedMonth = this.monthToDisplay;
          }
          this.previouSelectedMonthNameForExport = this.months.find(m => m.monthNumber == this.monthToDisplay).name;
          this.previouSelectedYearForExport = this.yearToDisplay;
          this.data = this.monthlyStats.filter(
            (stats) =>
              stats.userMonthlyStats.monthNumber == this.monthToDisplay &&
              stats.userMonthlyStats.year == this.yearToDisplay,
          );
      });
  }

  private _setDataForAdmin() {
    this.setDataForAdmin()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe(
        ([months, stats, companies]) => {
          this.companies = companies
          this.setMonths(months)
          this.monthlyStats = stats
          if (this.monthToDisplay == 0) {
            this.monthToDisplay = 12
            this.yearToDisplay--;

            this.selectedYear = this.yearToDisplay.toString();
            this.selectedMonth = this.monthToDisplay;
          }
          this.previouSelectedMonthNameForExport = this.months.find(m => m.monthNumber == this.monthToDisplay).name;
          this.previouSelectedYearForExport = this.yearToDisplay;
          this.data = this.monthlyStats.filter(
            (stats) =>
              stats.userMonthlyStats.monthNumber == this.monthToDisplay &&
              stats.userMonthlyStats.year == this.yearToDisplay,
          )
        });
  }

  setDataForAdmin(): Observable<any[]> {
    let salaries = this.getMonthlyStatsForAllUsers()
    let months = this.getAllMonths()
    let companies = this.companyService.getCompanies()

    return forkJoin([months, salaries, companies])
  }

  setDataForRegularUser(): Observable<any[]> {
    let salaries = this.getMonthlyStatsForRegularUser()
    let months = this.getAllMonths()

    return forkJoin([months, salaries])
  }

  getMonthlyStatsForAllUsers(): Observable<MonthlySalaryStatsModel[]> {
    return this.salaryService.getUsersMonthlySalaries()
  }

  getMonthlyStatsForRegularUser(): Observable<MonthlySalaryStatsModel[]> {
    return this.salaryService.getRegularUserMonthlySalaries()
  }

  getAllMonths(): Observable<MonthSimpleModel[]> {
    return this.monthDetailService.getAllSimpleMonths()
  }

  setMonths(monthList: MonthSimpleModel[]) {
    monthList.forEach((item) => {
      if (!this.months.some((element) => element.name == item.name)) {
        this.months.push(item)
      }
      if (!this.years.some((element) => element == item.year)) {
        this.years.push(item.year)
      }
    });

    this.months.sort(function (firstMonth, secondMonth) {
      return firstMonth.monthNumber - secondMonth.monthNumber;
    });
  }

  public onYearSelection(year: string) {
    let numberYear = year == this.all_years ? -1 : +year;
    this.previouSelectedYearForExport = numberYear;
    this.selectedYear = year

    if (
      this.selectedMonth == this.all_months &&
      this.selectedYear == this.all_years
    ) {
      this.data = this.monthlyStats
      return
    }

    if (
      this.selectedMonth !== this.all_months &&
      this.selectedYear == this.all_years
    ) {
      this.data = this.monthlyStats.filter(
        (stats) => stats.userMonthlyStats.monthNumber == this.selectedMonth,
      )
      return
    }

    if (this.selectedMonth == this.all_months) {
      this.data = this.monthlyStats.filter(
        (stats) => stats.userMonthlyStats.year.toString() == this.selectedYear,
      )
    } else {
      this.data = this.monthlyStats.filter(
        (stats) =>
          stats.userMonthlyStats.year.toString() == this.selectedYear &&
          stats.userMonthlyStats.monthNumber == this.selectedMonth,
      )
    }
  }

  public onMonthSelection(month: number) {
    this.previouSelectedMonthNameForExport = month == -1 ? MonthlyStats.ALL_MONTHS_DATA : this.months.find(m => m.monthNumber == month).name;
    this.selectedMonth = month

    if (
      this.selectedMonth == this.all_months &&
      this.selectedYear == this.all_years
    ) {
      this.data = this.monthlyStats
      return
    }

    if (
      this.selectedMonth == this.all_months &&
      this.selectedYear !== this.all_years
    ) {
      this.data = this.monthlyStats.filter(
        (stats) => stats.userMonthlyStats.year.toString() == this.selectedYear,
      )
      return
    }

    if (this.selectedYear == this.all_years) {
      this.data = this.monthlyStats.filter(
        (stats) => stats.userMonthlyStats.monthNumber == this.selectedMonth,
      )
    } else {
      this.data = this.monthlyStats.filter(
        (stats) =>
          stats.userMonthlyStats.year.toString() == this.selectedYear &&
          stats.userMonthlyStats.monthNumber == this.selectedMonth,
      )
    }
  }

  openEditStatusModal(stat: MonthlySalaryStatsModel): void {
    if (stat.isConfirmed) {
      this.dialogService.showSimpleDialog(
        'Error!',
        'This was already confirmed!',
        'error',
      )
    } else {

      this.startLoader();
      this.salaryService.getAvailableToConfirmMonthlyStatus(stat.userMonthlyStats.id)
        .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
          this.stopLoader();         
        }))
        .subscribe((res: boolean) => {
          this.isConfirmAvailable = res.valueOf();

          if (!this.isRegularUser || this.isConfirmAvailable) {
            this.stat = clone(stat);
            this.modal.show();
            this.isOvertimeForSalary = true;
          } else {
            this.dialogService.showSimpleDialog(
              'Error!',
              'This status is already expired',
              'error');
          }
        }, (_) => {
          this.dialogService.showErrorMessage(
            'Something went wrong',
            'Please try again later');
        });
    }
  }

  public changeOvertimeProcessing(setOvertimeForSalary: boolean) {
    this.isOvertimeForSalary = setOvertimeForSalary;
  }

  public onFoodTicketSelection(isForArchive: boolean) {
    this.isFoodTicketForArchive = isForArchive;
    this.isFoodTicketSelected = true;
  }

  public onExportSelection(selectionId: number) {
    this.isFoodTicketSelected = false;
    this.exportOptionSelected = selectionId;
  }

  public companySelected() {
    this.isFoodTicketSelected = false;
    this.exportOptionSelected = 1;
    this.selectedMonthNumber = null;
  }

  public saveChanges() {
    const confirmDto: ConfirmHolidayTakenModel = {
      monthlySalaryId: this.stat.userMonthlyStats.id,
      holidaysTaken: this.stat.userMonthlyStats.holidayHours,
      isModifiedByAdmin: !this.isRegularUser,
      isOvertimeForSalary: this.isOvertimeForSalary
    };

    this.gridOptions.api.showLoadingOverlay();
    this.salaryService
      .confirmHolidays(confirmDto)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (updatedStat) => {
          this.gridOptions.api.showLoadingOverlay()
          this.data[this.currentSelectionIdx] = updatedStat
          this.updateGridAfterEdit(updatedStat)
          this.stat = null
          this.gridOptions.api.hideOverlay();
          this.currentSelection = updatedStat;
          this.data[this.currentSelectionIdx] = this.currentSelection
          this.updateGridAfterEdit(this.currentSelection);
          this.stat = null;

          this.dialogService.showSimpleDialog(
            'Succes!',
            'Confirmed!',
            'success',
          );
          this.modal.hide();
        },
        (err) => {
          this.dialogService.showSimpleDialog('Error!', err, 'error')
          this.modal.hide()
          this.stat = null
          this.gridOptions.api.hideOverlay();
        },
      )
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;

    this.currentSelection = clone($event.data)
    this.currentSelectionIdx = this.data.indexOf(
      this.data.find(
        (u) =>
          u.userMonthlyStats.id == this.currentSelection.userMonthlyStats.id
      ));
  }

  public refreshStatus(monthlySalary: MonthlySalaryStatsModel) {
    if (monthlySalary.isConfirmed) {
      this.dialogService.showSimpleDialog(
        'Error!',
        'This was already confirmed!',
        'error',
      )
    } else {
      this.dialogService
        .confirmationDialog('Regenerate this status')
        .then((result) => {
          if (result.value) {
            this.gridOptions.api.showLoadingOverlay()
            this.salaryService
              .getRegeneratedMonthlySalary(monthlySalary.userMonthlyStats.id)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(
                (refreshedSalary) => {
                  this.filterDisplayedMonthlyStats(
                    refreshedSalary,
                    monthlySalary.userMonthlyStats.id,

                  )
                  this.dialogService.showSuccessMessage(
                    'Success!',
                    'Company details updated successfully.',
                  )
                  this.modal.hide()
                  this.dialogService.showSimpleDialog(
                    'Succes!',
                    'Regenerated!',
                    'success',
                  )
                  this.updateGridAfterEdit(refreshedSalary);
                },
                (err) => {
                  this.dialogService.showSimpleDialog('Error!', err, 'error')
                  this.modal.hide()
                  this.stat = null
                },
              )
          }
        })
    }
  }

  filterDisplayedMonthlyStats(
    monthlySalary: MonthlySalaryStatsModel,
    statsId: number,
  ) {
    const confirmedStatIndex = this.data.findIndex(
      (element) => element.userMonthlyStats.id == statsId,
    )
    this.data[confirmedStatIndex] = monthlySalary
  }

  public exportSelection() {
    if (this.isFoodTicketSelected) {
      if (this.isFoodTicketForArchive) {
        this.dataExport.exportFoodTicketsDataForAll(this.selectedMonthNumber, this.previouSelectedYearForExport);
      } else {
        this.dataExport.exportFoodTicketsData(this.selectedCompanyId, this.selectedMonthNumber, this.previouSelectedYearForExport);
      }
      return;
    }

    switch (this.exportOptionSelected) {
      case 1: {
        if (this.selectedCompanyId < 0) {
          this.exportBankSalaryDataToAll();
        } else {
          this.exportBankSalaryDataForCompany();
        }
        break;
      }
      case 2: {
        this.exportBankSalaryDataToAll();
        break;
      }
      case 3: {
        this.exportSalaryDataToAll();
        break;
      }
      case 4: {
        this.exportHolidayDataToAll();
        break;
      }
      default: {
        break;
      }
    }
  }

  public exportStats() {
    this.currentMonthForExport = moment().month() + 1;
    this.previouSelectedMonthForExport = this.selectedMonth;
    this.exportModal.show()
  }

  public hideExportModal() {
    this.selectedCompanyId = null;
    this.selectedMonthNumber = null;
    this.exportModal.hide();
  }

  public exportBankSalaryDataToAll() {
    this.dataExport.exportBankSalaryDataForAll(this.previouSelectedMonthForExport, this.previouSelectedYearForExport);
  }

  public exportSalaryDataToAll() {
    this.dataExport.exportSalaryDataForAll(this.previouSelectedMonthForExport, this.previouSelectedYearForExport);
  }

  public exportHolidayDataToAll() {
    this.dataExport.exportHolidayDataForAll(this.previouSelectedMonthForExport, this.previouSelectedYearForExport);
  }

  public exportBankSalaryDataForCompany() {
    if (!this.selectedCompanyId) return

    this.dataExport.exportBankSalaryData(this.selectedCompanyId, this.previouSelectedMonthForExport, this.previouSelectedYearForExport)
  }

  private _onEdit(e) {
    this.openEditStatusModal(e.data)
  }

  private _onRefresh(e) {
    this.refreshStatus(e.data)
  }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return ''
  }

  isModelValid(): boolean {
    return true
  }
}