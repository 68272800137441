export class MomentConstants {
  public static readonly YEAR_MONTH_DAY_FORMAT = "YYYY-MM-DD";
  public static readonly YEAR_MONTH_FORMAT = "YYYY-MM-DD";
  public static readonly DAY_MONTH_YEAR_FORMAT = "DD-MM-YYYY";
  public static readonly PRETTY_MONTH_DAY_YEAR = "MMMM Do, YYYY";
  public static readonly DAY_MONTH_DAY_YEAR = "dddd, MMMM Do, YYYY";
  public static readonly DAY_MONTH = "dddd, D MMM";
  public static readonly YEAR_MONTH_DAY = "YYYY-MM-DD";


}
