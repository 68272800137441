import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ReviewTypeEnum } from 'src/app/models/reviewTypes/ReviewTypeEnum';

@Component({
selector: 'assign-reviewers-cell-renderer-component',
templateUrl: './assign-reviewers-cell-renderer.component.html',
styleUrls: ['./assign-reviewers-cell-renderer.component.scss']
})
export class AssignReviewersCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  constructor() {}

  agInit(params: any): void {
    params.isVisible = false;
    if(!!params.shouldCheckStatus){
      // if the review is Review Type and it respects the TimeLead (meaning that it has to be ative soon)
      // then the button is displayed - isVisible set on false
      params.isVisible = !(params.data.reviewType == ReviewTypeEnum.Review && params.data.isReadyToAssign);
    }

    this.params = params;
  }

  refresh(_: any): boolean {
    return false;
  }

  onClick(): void {
    this.params.onClick(this.params.node);
  }
}