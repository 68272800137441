import { Component, Input, OnInit } from "@angular/core";
import { finalize, takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { DialogService } from "src/app/shared/services/dialog.service";
import { clone } from "src/app/shared/utils/Utils";
import { BenefitsPointsLogService } from "../../../services/benefits-points-log.service";
import { LogType } from "src/app/models/benefitsPointsLogModel/LogType";
import { ColorsConstants } from "src/app/constants/colors-constants";
import { BenefitsPointsLogModel } from "src/app/models/benefitsPointsLogModel/BenefitsPointsLog";
import { ChangeStringFormat } from "src/app/shared/utils/Formatters";

@Component({
  selector: 'trackify-current-user-benefits-log',
  templateUrl: './current-user-benefits-log.component.html',
  styleUrls: ['./current-user-benefits-log.component.scss']
})

export class CurrentUserBenefitsLogComponent extends GridComponentBase<BenefitsPointsLogModel> implements OnInit {

  @Input() isRegularUser: boolean;
  @Input() userId: number = null;
  benefitLog: BenefitsPointsLogModel;

  constructor(
    private dialogService: DialogService,
    private benefitsPointsLogService: BenefitsPointsLogService) {
    super();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        headerName: 'Date',
        valueGetter: (x) => (moment(x.data.date)).format('DD-MMM-YYYY'),
        width: 190,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Benefit Name',
        valueGetter: (x) => (x.data.benefitName ? x.data.benefitName : "N/A"),
        minWidth: 250,
        suppressSizeToFit: false,
      },
      {
        headerName: 'Benefit Type',
        valueGetter: (x) => (x.data.benefitType ? ChangeStringFormat(x.data.benefitType.name): "N/A"),
        width: 250,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Initial Points',
        field: 'initPoints',
        width: 190,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Action',
        valueGetter: function (x) {
          switch(x.data.logType) {
            case(LogType.AddBenefit):
              return `+${x.data.actionCost}`;
            case(LogType.RevertPoints):
              return `+${x.data.actionCost}`;
            default:
              return `-${x.data.actionCost}`;
          }
        },
        width: 190,
        suppressSizeToFit: true,
        cellStyle: (params) => {
          switch(params.data.logType) {
            case (LogType.AddBenefit):
              return this._colorCell(ColorsConstants.GREEN);
            case (LogType.ConsumeBenefit):
              return this._colorCell(ColorsConstants.RED);
            case (LogType.RevertPoints):
              return this._colorCell(ColorsConstants.BLUE);
            default:
              return this._colorCell(ColorsConstants.BLACK);
          }
        },
      },
      {
        headerName: 'Remaining Points',
        field: 'remainingPoints',
        width: 190,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Category',
        valueGetter: (x) => (x.data.category ? x.data.category : "N/A"),
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Log Type',
        valueGetter: function (x) {
          switch(x.data.logType){
            case (LogType.AddBenefit):
              return "Add Points";
            case (LogType.ConsumeBenefit):
              return "Consume Points";
            case (LogType.RevertPoints):
              return "Revert Points";
            default:
              return "Invalid";
          }
        },
        width: 250,
        suppressSizeToFit: true,
      }
    ])

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No benefit logs entries.</span>';
  }

  ngOnInit(): void {
    this.startLoader();
    this.addSpecificColumns();
    this._getBenefitsPointsLog();
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;

    this.benefitLog = clone($event.data);
    this.currentSelectionIdx = this.benefitLog.id;
  }

  onRowDoubleClicked(_: any): void {
    throw new Error("Method not implemented.");
  }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  addSpecificColumns(): void {
    if (!this.isRegularUser) {
      this.addColumnDefs([
        {
          headerName: 'Revert Points',
          headerClass: "text-center",
          type: ["buttonColumn"],
          pinned: 'right',
          cellRendererParams: {
            buttonClass: "btn btn-primary",
            buttonText: "",
            iconClass: "fa fa-undo",
            shouldCheckBenefitStatus: true,
            onClick: this._onRevert.bind(this),
          },
          cellRenderer: "revertButtonRenderer",
          suppressSizeToFit: true,
        }
      ]);
    }
  }

  private _getBenefitsPointsLog(): void {
    const getLogs$ = this.userId ? this.benefitsPointsLogService.getBenefitsPointsLogById(this.userId) : this.benefitsPointsLogService.getBenefitsPointsLog()
    getLogs$
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
      .subscribe((benefitsPointsLog) => {
        this.data = benefitsPointsLog;
      });
  }

  private _onRevert(): void {
    this.dialogService
      .showDialog({
        title: 'Are you sure you want to revert points for this event?',
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#DC3545",
        confirmButtonText: "Revert points",
      })
      .then((result) => {
        if (!result.value) return;

        this.startLoader();
        this.benefitsPointsLogService
          .retrievePointsFromBenefitLogs(this.benefitLog.id)
          .pipe(takeUntil(this.ngUnsubscribe), 
          finalize(() => this.stopLoader()))
          .subscribe((newBenefitLog) => {
            this.gridOptions.api.showLoadingOverlay();

            const indexOfEditedReview = this.data.findIndex(log => log.id == this.benefitLog.id);
            this.benefitLog.logType = LogType.Invalid;
            this.data[indexOfEditedReview] = this.benefitLog;
            this.updateGridAfterDelete();
            this.updateGridAfterAdd(this.benefitLog);

            this.data.unshift(newBenefitLog);            
            this.updateGridAfterAdd(newBenefitLog);
            this.gridOptions.api.setRowData([...this.data]);
            
            this.gridOptions.api.hideOverlay();

            this.dialogService.showSuccessMessage(
              "Success!",
              "Selected benefit points have been reverted."
            );
          },
            (err) => {
              this.dialogService.showSimpleDialog("Error", err, "error");
            }
          );
      });
  }
}
