import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import * as moment from "moment";
import { StatusConstants } from "src/app/constants/statuses-constants";
import { HolidayType } from "src/app/models/holiday-type/HolidayType";
import { dateHasPassed } from "src/app/shared/utils/MomentUtils";

@Component({
  selector: "trackify-button-cell-renderer",
  templateUrl: "./button-cell-renderer.component.html",
  styleUrls: ["./button-cell-renderer.component.scss"],
})
export class ButtonCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  constructor() {}

  agInit(params: any): void {
    if (!!params.shouldCheckStatus) {
      if (Array.isArray(params.getStatusToCheck())) {
        // if true => button is not shown
        const isStillPending =
          params.data.statusName == StatusConstants.PENDING_STATUS;

        params.isVisible = isStillPending
          ? dateHasPassed(params.data.startDate, moment())
          : params.getStatusToCheck().includes(params.data.statusName)
          ? dateHasPassed(params.data.startDate, moment())
          : true;
      } else {
        params.isVisible = params.data.statusName != params.getStatusToCheck();
      }
    }

    // if the cell is "View document"
    if (!!params.shouldCheckDocument) {
      // if isVisible is true => view document button is not displayed
      // the button is not displayed if the holiday type is Paid or Not Paid or if there is no file uploaded
      params.isVisible =
        params.data.holidayRequestTypeName == HolidayType.Paid ||
        params.data.holidayRequestTypeName == HolidayType.NotPaid || 
        params.data.filePath == "" || params.data.filePath == null;
    }

    if (!!params.shouldCheckArchiveStatus) {
      params.isVisible = params.data.isArchived;
    }
    
    this.params = params;
  }

  refresh(_: any): boolean {
    return false;
  }

  onClick() {
    this.params.onClick(this.params.node);
  }
}
