import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";

import { ReviewFormDataToSubmit } from "../models/reviews/ReviewFormDataToSubmit";
import { ReviewFormModel } from "../models/reviews/ReviewFormModel";

@Injectable({
    providedIn: "root",
})
export class ReviewFormService {
    private _apiBaseUrl = ApiConstants.REVIEW_FORM_API_ENDPOINT;

    constructor(private apiService: ApiService) { }

    public saveReviewFormResponses(reviewResponse: ReviewFormDataToSubmit): Observable<any> {
        return this.apiService.post<any>(`${this._apiBaseUrl}/submit-responses`, reviewResponse);
    }

    public getReviewFormData(token: string): Observable<ReviewFormModel> {
        return this.apiService.get<ReviewFormModel>(`${this._apiBaseUrl}/form-display-data/${token}`);
    }
}
