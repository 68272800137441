import { Component } from "@angular/core";

@Component({
  selector: "trackify-settings-tabs",
  templateUrl: "./settings-tabs.component.html",
  styleUrls: ["./settings-tabs.component.scss"],
})
export class SettingsTabsComponent {
  constructor() { }
}
