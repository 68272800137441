export class DateTimeModel {
  public day: number;
  public month: number;
  public year: number;

  constructor(d: number, m: number, y: number) {
    this.day = d;
    this.month = m;
    this.year = y;
  }
}
