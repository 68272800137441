import { Component, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { UserBirthday } from 'src/app/models/users/UserBirthday';
import { UserService } from 'src/app/services/user.service';
import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase';
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils';
import { formatLocalTime } from 'src/app/shared/utils/Formatters';
import { dateComparator } from 'src/app/shared/utils/Utils';

@Component({
  selector: 'trackify-next-birthday',
  templateUrl: './next-birthday.component.html',
  styleUrls: ['./next-birthday.component.scss']
})
export class NextBirthdayComponent extends GridComponentBase<UserBirthday> implements OnInit {
  formatDateForTable = formatLocalTime;
  constructor(private userService: UserService) {
    super();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        field: "username",
        headerName: "User",
        width: 170,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) =>
          !x.data.birthday
            ? "N/A"
            : this.formatDateForTable(x.data.birthday),
        headerName: "Birthday",
        width: 140,
        sort: 'asc',
        comparator: (_, __, nodeA, nodeB) => (dateComparator(nodeA.data.birthday, nodeB.data.birthday)),
        suppressSizeToFit: false,
      },
    ]);
    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No users to show</span>';
  }
  ngOnInit() {
    this.startLoader();
    this._loadBirthdayData();
  }

  onRowSelected(_: any): void { }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  private _loadBirthdayData() {
    this.userService
      .getUpcomingUserBirthday()
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
      .subscribe(
        (results) => {
          this.data = results;
        }
      );
  }
}
