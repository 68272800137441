import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConstants } from '../constants/api-constants';
import { WeeklyPlannerBasicModel } from '../models/planner/WeeklyPlannerBasicModel';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class WeeklyPlannerService {
  private _apiBaseUrl = ApiConstants.USER_WEEKLY_PLANNER_ENDPOINT;

  constructor(private apiService: ApiService) { }

  public getWeeklyPlanning(): Observable<WeeklyPlannerBasicModel[]> {
    return this.apiService.get<WeeklyPlannerBasicModel[]>(`${this._apiBaseUrl}/user`);
  }
}