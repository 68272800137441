import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import * as moment from "moment";
import { finalize, takeUntil } from "rxjs/operators";

import { DialogService } from "src/app/shared/services/dialog.service";
import { formatLocalTime } from "src/app/shared/utils/Formatters";

import { ReviewService } from "src/app/services/review.service";
import { ReviewTypeService } from "src/app/services/reviewType.service";

import { ReviewModel } from "src/app/models/reviews/ReviewModel";
import { ReviewTypeModel } from "src/app/models/reviewTypes/ReviewTypeModel";
import { AddReviewModel } from "src/app/models/reviews/AddReviewModel";
import { PerformReviewModel } from "src/app/models/reviews/PerformReviewModel";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { MomentConstants } from "src/app/constants/moment-constants";
import { clone, dateComparator } from "src/app/shared/utils/Utils";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { EditReviewModel } from "src/app/models/reviews/EditReviewModel";
import { DateTimeModel } from "src/app/models/DateTimeModel";
import { ReviewStatusModalComponent } from "../../review-status-modal/review-status-modal.component";
import { ReviewTypeEnum } from "src/app/models/reviewTypes/ReviewTypeEnum";
import { ReviewStatusEnum } from "src/app/models/reviews/ReviewStatusEnum";
import { RowNode } from "ag-grid-community";
import { PatternConstants } from "src/app/constants/pattern-constants";
import { ReviewAssignModalComponent } from "../../review-assign-modal/review-assign-modal.component";

@Component({
  selector: "trackify-user-reviews",
  templateUrl: "./user-reviews.component.html",
  styleUrls: ["./user-reviews.component.scss"],
})
export class UserReviewsComponent extends GridComponentBase<ReviewModel> implements OnInit {

  formatDateForTable = formatLocalTime;
  minDate = moment().toDate();
  maxDate: string;

  reviewTypes: ReviewTypeModel[] = [];
  reviewAddModel: AddReviewModel;
  performReviewModel: PerformReviewModel;
  editReviewModel: EditReviewModel;
  isPerforming = false;
  editMode = false;
  minDateNextReview: string;
  currentDate: Date;
  errorMessage: string;
  isModalButtonEnabled = true;
  dropdownSettings: IDropdownSettings = {};
  private _userId: number;
  allReviews: ReviewModel[] = [];
  reviewHasOccurance: boolean = false;
  reviewModalRef: BsModalRef;
  descriptionPattern = PatternConstants.NON_EMPTY_TEXT_PATTERN;

  @ViewChild("reviewModal", { static: true }) reviewModal: ModalDirective;

  constructor(
    private reviewService: ReviewService,
    private reviewTypeService: ReviewTypeService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private bsModalService: BsModalService
  ) {
    super();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        valueGetter: (x) =>
          `${moment(x.data.reviewDate).format(
            MomentConstants.PRETTY_MONTH_DAY_YEAR
          )}`,
        headerName: "Date",
        sort: "desc",
        comparator: (_, __, nodeA, nodeB) => (dateComparator(nodeA.data.reviewDate, nodeB.data.reviewDate)),
        suppressSizeToFit: true,
        width: 160,
      },
      {
        field: "reviewType",
        headerName: "Type",
        suppressSizeToFit: true,
        width: 140,
      },
      {
        valueGetter: (x) => x.data.reviewStatusId == null ? "N/A" : x.data.reviewStatus,
        headerName: "Status",
        suppressSizeToFit: true,
        width: 120
      },
      {
        valueGetter: (x) =>
          x.data.nextReviewDate == null
            ? "N/A"
            : `${moment(x.data.nextReviewDate).format(
              MomentConstants.PRETTY_MONTH_DAY_YEAR
            )}`,
        headerName: "Next Review",
        width: 160,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => {
          const { description, reviewType } = x.data;
          if (description == null) {
            return reviewType === ReviewTypeEnum.Review ? "N/A, please check perform" : "N/A";
          }
          return description;
        },
        suppressSizeToFit: true,
        headerName: "Description",
        width: 170,
      },
      {
        valueGetter: (x) => x.data.newDailyHours == null ? "N/A" : x.data.newDailyHours,
        headerName: "New Daily Hours",
        minWidth: 160,
        suppressSizeToFit: false,
      },
      {
        headerName: "Assign",
        pinned: 'right',
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-primary",
          buttonText: "",
          iconClass: "fas fa-user-plus",
          shouldCheckStatus: true,
          onClick: this._onAssignResponsibles.bind(this),
        },
        cellRenderer: "assignReviewersCellRenderer",
        suppressSizeToFit: true,
      },
      {
        headerName: "Perform",
        pinned: 'right',
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-success btn-xs",
          buttonText: "",
          iconClass: "fas fa-check-double",
          onClick: this._viewOrPerform.bind(this),
        },
      },
      {
        headerName: "Edit",
        pinned: 'right',
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-primary btn-xs",
          buttonText: "",
          iconClass: "fas fa-edit white-color",
          onClick: this._onEdit.bind(this),
        },
      },
      {
        headerName: "Delete",
        pinned: 'right',
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-danger btn-xs",
          buttonText: "",
          iconClass: "fa fa-trash",
          onClick: this._onDelete.bind(this),
        },
      },
    ]);
    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No review entries.</span>';
  }

  ngOnInit() {
    this.dropdownSettings = {
      idField: 'id',
      textField: 'fullName',
      allowSearchFilter: true
    };

    this.startLoader();
    this._getData();
  }

  openAddReviewModal(): void {
    this.maxDate = this._getMaxDate();
    this.editMode = false;
    this.isPerforming = false;
    this.reviewAddModel = new AddReviewModel();
    this.reviewAddModel.userId = this._userId;
    this.reviewModal.show();
  }

  onHideModal(): void {
    this.reviewModal.hide();
    this.performReviewModel = null;
    this.editReviewModel = null;
    this.reviewAddModel = null;
    this.errorMessage = "";
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;
    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(u => u.id == this.currentSelection.id));
  }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  saveChanges(): void {
    this.isModalButtonEnabled = false;
    this.errorMessage = "";

    if (!this._isDataValid()) {
      this.isModalButtonEnabled = true;
      return;
    }

    if (this.editMode && !this.isPerforming) {
      this._executeEditReview();
    }

    if (!this.editMode && !this.isPerforming) {
      this._executeAddReview();
    }

    if (!this.editMode && this.isPerforming) {
      this._executePerformReview();
    }
  }

  private _getData(): void {
    this._userId = this.route.snapshot.params.id;
    forkJoin([
      this.reviewService.getUserReviews(this._userId),
      this.reviewTypeService.getReviewTypes()
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader())
      )
      .subscribe(
        ([reviews, types]) => {
          this.allReviews = reviews;
          this.data = reviews;
          this.reviewTypes = types;
        });
  }

  // Startig PERFORM functionality:
  private _viewOrPerform(review: RowNode): void {
    if (review.data.reviewType === ReviewTypeEnum.Review) {
      this._openReviewStatusModal(review);
      return;
    }
    this._onPerform(review);
  }

  // PERFORM for reviews that are NOT Review Type
  private _onPerform(review: RowNode): void {
    if (review.data.reviewStatus === ReviewStatusEnum.Completed) {
      this.dialogService.showWarningMessage(
        "Review completed",
        "Review already performed!"
      )
    }
    else {
      this._openPerformReviewModal(review.data);
    }
  }

  private _openPerformReviewModal(review: ReviewModel): void {
    this.reviewHasOccurance = false;
    this.isPerforming = true;
    this.editMode = false;
    this.performReviewModel = new PerformReviewModel();
    this.performReviewModel.id = review.id;
    this.performReviewModel.newDailyHours = review.userDailyHours;

    if (!!review.reviewOccurance) {
      this.reviewHasOccurance = true;
      let date = new Date(review.reviewDate);
      this.performReviewModel.nextReviewDate = this._getDate(new Date(date.setMonth(date.getMonth() + review.reviewOccurance)));
    }

    this.maxDate = this._getMaxDate();
    this.minDateNextReview = review.reviewDate;
    this.reviewModal.show();
  }

  // PERFORM for reviews that are Review Type
  private _openReviewStatusModal(review: RowNode): void {
    const initialState = {
      reviewId: review.data.id,
      userFullName: review.data.userName
    };
    this._onViewAssignee(initialState, this._onCloseReviewModal.bind(this));
  }

  private _onViewAssignee(initialState: any, callback: () => void): void {
    this.reviewModalRef = this.bsModalService.show(ReviewStatusModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg align-center-modal'
    });

    this.reviewModalRef.onHidden
    .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (!!callback) {
          callback();
        }
      });
  }

  private _onCloseReviewModal(): void {
    const isReviewCompleted = this.reviewModalRef.content.isCompleted;

    if (!isReviewCompleted) {
      return;
    }

    const reviewId = this.reviewModalRef.content.reviewId;
    const nextReview = this.reviewModalRef.content.nextReview;
    const review : ReviewModel = this.data.find((review: ReviewModel) => review.id == reviewId);
    // update the review
    review.reviewStatus = ReviewStatusEnum.Completed;
    review.nextReviewDate = nextReview.reviewDate;
    this.updateGridAfterEdit(review);
    // add to the grid the next review
    this.data.push(nextReview);
    this.updateGridAfterAdd(nextReview);
  }

  private _executePerformReview(): void {
    const nextReviewDate = new Date(this.performReviewModel.nextReviewDate);
    this.performReviewModel.nextReviewDateComponent = new DateTimeModel(nextReviewDate.getDate(), nextReviewDate.getMonth() + 1, nextReviewDate.getFullYear());
    this.reviewService
      .performReview(this.performReviewModel)
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => { this.onHideModal(); this.isModalButtonEnabled = true; }))
      .subscribe(
        (result) => {
          this.gridOptions.api.showLoadingOverlay();

          if (result.length > 1) {
            result[0].nextReviewDate = moment(result[0].nextReviewDate).toISOString();
            this.updateGridAfterEdit(result[0]);
            result[1].reviewDate = moment(result[1].reviewDate).toISOString();
            this.updateGridAfterAdd(result[1]);
          } else {
            this.updateGridAfterEdit(result[0]);
          }

          this.gridOptions.api.hideOverlay();
          this.dialogService.showSimpleDialog(
            "Succes!",
            "Review performed successfully",
            "success"
          );
        },
        (_) => {
          this.dialogService.showSimpleDialog(
            "Error!",
            "Something went wrong",
            "error"
          );
        }
      );
  }

  // Starting ASSIGN functionality - only for Review Type reviews:
  private _onAssignResponsibles(review: RowNode): void {
    const initialState = {
      reviewId: review.data.id,
      userFullName: review.data.userName,
      reviewStatus: review.data.reviewStatus
    };

    this.reviewModalRef = this.bsModalService.show(ReviewAssignModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg align-center-modal'
    });
  }

  // Starting EDIT functionality
  private _onEdit(review: RowNode): void {
    this._openEditReviewModal(review.data);
  }

  private _openEditReviewModal(review: ReviewModel): void {
    if (review.reviewStatus === ReviewStatusEnum.Completed) {
      this.dialogService.showWarningMessage(
        "Review completed",
        "Edit is disabled for completed reviews.");
      return;
    }

    this.maxDate = this._getMaxDate();
    this.editMode = true;
    this.isPerforming = false;
    this.editReviewModel = new EditReviewModel();
    this.editReviewModel.id = review.id;
    this.editReviewModel.date = review.reviewDate;

    this.reviewModal.show();
  }

  private _executeEditReview(): void {
    this.reviewService
      .editReview(this.editReviewModel)
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => { this.onHideModal(); this.isModalButtonEnabled = true; }))
      .subscribe(
        (result) => {
          this.gridOptions.api.showLoadingOverlay();
          const indexOfEditedReview = this.data.findIndex(review => review.id == result.id);
          this.data[indexOfEditedReview] = result;
          this.updateGridAfterDelete();
          this.updateGridAfterAdd(result);
          this.gridOptions.api.hideOverlay();
          this.dialogService.showSimpleDialog(
            "Succes!",
            "Review edited successfully",
            "success"
          );
        },
        (error) => {
          this.dialogService.showSimpleDialog(
            "Error!",
            error,
            "error"
          );
        }
      );
  }

  // Starting DELETE functionality
  private _onDelete(review: RowNode): void {
    this._deleteReview(review.data.id);
  }
  
  private _deleteReview(reviewId: number): void {
    this.dialogService
      .showDialog({
        title: "Warning",
        text: "The review will be deleted and you cannot undo this action",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      })
      .then((result) => {
        if (!!result.value) {
          this.gridOptions.api.showLoadingOverlay()
          this.reviewService
            .deleteReview(reviewId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
              (_) => {
                const index = this.data.findIndex((r) => r.id == reviewId);
                this.data.splice(index, 1);
                this.updateGridAfterDelete();
                this.gridOptions.api.hideOverlay();
                this.dialogService.showSimpleDialog(
                  "Success",
                  "Review was deleted succesfully",
                  "success"
                );
              },
              (err) => {
                this.dialogService.showSimpleDialog("Error!", err, "error");
                this.gridOptions.api.hideOverlay();
              }
            );
        }
      });
  }

  // Starting ADD functionality
  private _executeAddReview(): void {
    const reviewDate = new Date(this.reviewAddModel.reviewDate);
    this.reviewAddModel.reviewDateComponent = new DateTimeModel(reviewDate.getDate(), reviewDate.getMonth() + 1, reviewDate.getFullYear());
    this.reviewService
      .addReview(this.reviewAddModel)
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => { this.onHideModal(); this.isModalButtonEnabled = true; }))
      .subscribe(
        (result) => {
          this.gridOptions.api.showLoadingOverlay()
          result.reviewDate = moment(result.reviewDate).toISOString();
          result.nextReviewDate = moment(result.nextReviewDate).toISOString();
          this.data.push(result);
          this.updateGridAfterAdd(result);
          this.gridOptions.api.hideOverlay();

          this.dialogService.showSimpleDialog(
            "Succes!",
            "Review added successfully",
            "success"
          );
        },
        (error) => {
          this.dialogService.showSimpleDialog(
            "Error!",
            error,
            "error"
          );
        }
      );
  }

  private _isDataValid(): boolean {
    if (!this.editMode && !this.isPerforming) {
      if (!this.reviewAddModel.reviewDate) {
        this.errorMessage = "Missing or invalid Review Date\n";
        return false;
      }
      return true;
    }

    if (this.editMode && !this.isPerforming) {
      if (!this.editReviewModel.date) {
        this.errorMessage = "Missing or invalid Review Date\n";
        return false;
      }
      return true;
    }

    return true;
  }

  private _getDate(date: Date): string {
    return moment(date).startOf('day').toISOString(true)
  }

  private _getMaxDate(): string {
    this.currentDate = new Date();
    this.currentDate.setFullYear(this.currentDate.getFullYear() + 2);
    return this.currentDate.toDateString();
  }
}