import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { UserService } from "src/app/services/user.service";

import { UserModel } from "src/app/models/users/UserModel";

@Component({
  selector: "trackify-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"],
})
export class UserDetailsComponent implements OnInit {
  user: UserModel;
  @Input() isRegularUser: boolean = false;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._getUser();
  }

  private _getUser() {
    const userId = this.route.snapshot.params.id;
    this.userService.getUser(userId).subscribe((result) => {
      this.user = result;
    });
  }
}
