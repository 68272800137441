import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../shared/services/api.service";
import { ApiConstants } from "../constants/api-constants";

import { SettingsModel } from "../models/settings/SettingsModel";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private _apiBaseUrl = ApiConstants.SETTINGS_API_ENDPOINT;

  constructor(private apiService: ApiService) {}

  public addSetting(id: number): Observable<SettingsModel> {
    return this.apiService.post<SettingsModel>(`${this._apiBaseUrl}/${id}`, {});
  }

  public deleteSetting(id: number): Observable<any> {
    return this.apiService.delete(`${this._apiBaseUrl}/${id}`);
  }

  public getAllSettings(): Observable<SettingsModel[]> {
    return this.apiService.get<SettingsModel[]>(`${this._apiBaseUrl}`);
  }
}
