import { Component, OnInit } from '@angular/core';
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { HolidayRequestModel } from "src/app/models/holiday-request/HolidayRequestModel";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { formatLocalTime } from "src/app/shared/utils/Formatters";
import { dateComparator } from "src/app/shared/utils/Utils";
import { StatusConstants } from "src/app/constants/statuses-constants";
import { HolidayRequestService } from "src/app/services/holiday-request/holidayRequest.service";
import { HolidayStatusService } from "src/app/services/holiday-request/holidayStatus.service";
import { HolidayRequestTypeService } from "src/app/services/holiday-request/holiday-request-type.service";
import { forkJoin } from "rxjs";
import * as moment from 'moment'
import { MonthlyStats } from 'src/app/constants/monthly-stats'
import { MonthSimpleModel } from 'src/app/models/monthhDetails/MonthSimpleModel'
import { DialogService } from "src/app/shared/services/dialog.service";
import { HoldayRequestResponseModel } from "src/app/models/holiday-request/HoldayRequestResponseModel";
import { takeUntil, finalize } from "rxjs/operators";

import { MonthDetailsService } from 'src/app/services/month-details.service'
import { parseDate } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'trackify-sortable-holiday',
  templateUrl: './sortable-holiday.component.html',
  styleUrls: ['./sortable-holiday.component.scss']
})
export class SortableHolidayComponent
  extends GridComponentBase<HolidayRequestModel>
  implements OnInit {

  private selectedYear: string = MonthlyStats.ALL_DATA
  private selectedMonth: string = MonthlyStats.ALL_DATA
  private holidayRequestsFiltered: HolidayRequestModel[] = [];
  private holidayRequests: HolidayRequestModel[] = [];
  private all_years: string = MonthlyStats.ALL_DATA;
  private all_months: string = "-1";
  isDataLoaded: boolean;
  months: MonthSimpleModel[] = [];
  years: number[] = [];
  monthToDisplay: number;
  yearToDisplay: number;

  formatDate = formatLocalTime;
  constructor(
    private dialogService: DialogService,
    private holidayRequestService: HolidayRequestService,
    private holidayStatusService: HolidayStatusService,
    private holidayRequestTypeService: HolidayRequestTypeService,
    private monthDetailService: MonthDetailsService,
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        field: "userName",
        headerName: "User",
        width: 100,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => this.formatDate(x.data.startDate),
        headerName: "Start Date",
        width: 150,
        sort: 'asc',
        comparator: (_, __, nodeA, nodeB) => (dateComparator(nodeA.data.startDate, nodeB.data.startDate)),
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => this.formatDate(x.data.endDate),
        headerName: "End Date",
        width: 150,
        suppressSizeToFit: false,
      },
      {
        field: "holidayRequestTypeName",
        headerName: "Type",
        width: 100,
        suppressSizeToFit: false,
      },
      {
        field: "statusName",
        headerName: "Status",
        width: 120,
        suppressSizeToFit: false,
      },
      {
        headerName: "Accept",
        pinned: 'right',
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-success",
          buttonText: "",
          iconClass: "fas fa-check",
          shouldCheckStatus: true,
          getStatusToCheck: () => StatusConstants.PENDING_STATUS,
          onClick: this._accept.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
      {
        headerName: "Decline",
        pinned: 'right',
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-danger",
          buttonText: "",
          iconClass: "fas fa-times",
          shouldCheckStatus: true,
          getStatusToCheck: () => StatusConstants.PENDING_STATUS,
          onClick: this._decline.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
      {
        headerName: " View document",
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-success",
          buttonText: "",
          iconClass: "fa fa-eye",
          shouldCheckStatus: true,
          shouldCheckDocument: true,
          getStatusToCheck: () => [StatusConstants.ACCEPTED_STATUS],
          onClick: this._viewDocument.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      }
    ]);
    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No holiday requests entries.</span>';
  }

  ngOnInit() {
    this.isDataLoaded = true;
    this.startLoader();
    this._loadHolidayRequestData();
    this._setData();
    this.isDataLoaded = false;
    this.monthToDisplay = moment().month() + 1
    this.yearToDisplay = moment().year()
  }

  private _loadHolidayRequestData() {
    forkJoin([
      this.holidayRequestService.getRequests(),
      this.holidayStatusService.getAll(),
      this.holidayRequestTypeService.getAll(),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(finalize(() => {
        this.stopLoader();
      }))
      .subscribe(
        ([requests]) => {
          this.holidayRequests = requests;
          this._filterData(this.holidayRequests);
        }
      );
  }

  private _setData() {
    this.monthDetailService.getAllSimpleMonths()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (months) => {
          this._setMonths(months)
        }
      )
  }

  private _filterData(requests: HolidayRequestModel[]) {

    this.holidayRequestsFiltered = [];
    requests.forEach(element => {
      let startDateM = parseDate(element.startDate).getMonth() + 1;
      let endDateM = parseDate(element.endDate).getMonth() + 1;
      let startDateY = parseDate(element.startDate).getFullYear();
      let endDateY = parseDate(element.endDate).getFullYear();

      if (this.monthToDisplay == null && this.yearToDisplay != null &&
        (startDateY == this.yearToDisplay || endDateY == this.yearToDisplay)) {
        this.holidayRequestsFiltered.push(element);
      }
      else if (this.monthToDisplay != null && this.yearToDisplay == null &&
        (startDateM == this.monthToDisplay || endDateM == this.monthToDisplay)) {
        this.holidayRequestsFiltered.push(element);
      }
      else if (this.monthToDisplay != null && this.yearToDisplay != null &&
        (startDateM == this.monthToDisplay && startDateY == this.yearToDisplay ||
          endDateM == this.monthToDisplay && endDateY == this.yearToDisplay ||
          startDateM < this.monthToDisplay && endDateM > this.monthToDisplay) ||
        (startDateM > this.monthToDisplay && startDateY < this.yearToDisplay &&
          endDateM > this.monthToDisplay && endDateY == this.yearToDisplay)) {
        this.holidayRequestsFiltered.push(element);
      }
      else if (this.monthToDisplay == null && this.yearToDisplay == null) {
        this.holidayRequestsFiltered.push(element);
      }
    });
    this.data = this.holidayRequestsFiltered;
  }
  private _setMonths(monthList: MonthSimpleModel[]) {
    monthList.forEach((item) => {
      if (!this.months.some((element) => element.name == item.name)) {
        this.months.push(item)
      }
      if (!this.years.some((element) => element == item.year)) {
        this.years.push(item.year)
      }
    });

    this.months.sort(function (firstMonth, secondMonth) {
      return firstMonth.monthNumber - secondMonth.monthNumber;
    });
  }

  private _accept(e) {
    this._updateHolidayRequestStatus(e.data.id, true);
  }

  private _decline(e) {
    this._updateHolidayRequestStatus(e.data.id, false);
  }

  private _viewDocument(e) {
    if (e.data.filePath) {
      this._openDoc(e.data.filePath);
    }
    else {
      this.dialogService.showErrorMessage(
        "Oops...",
        "No files uploaded!"
      );
    }
  }

  private _openDoc(documentUrl: string) {
    window.open(documentUrl);
  }

  private _updateHolidayRequestStatus(requestId: number, isAccepted: boolean) {
    const dialogMessage = isAccepted ? "Accept request" : "Decline request";
    this.dialogService.confirmationDialog(dialogMessage).then((result) => {

      if (!result.value) return;
      const requestResponse: HoldayRequestResponseModel = {
        holidayRequestId: requestId,
        isAccepted: isAccepted,
      };
      this.gridOptions.api.showLoadingOverlay()
      this.holidayRequestService
        .respondeToRequest(requestResponse)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (result) => {
            this.data[this.currentSelectionIdx] = result;
            this.data[this.currentSelectionIdx].isActionsAvailable = false;
            this.updateGridAfterEdit(this.data[this.currentSelectionIdx]);
            this.gridOptions.api.hideOverlay();
            this.gridOptions.api.redrawRows({
              rowNodes: [
                this.gridOptions.api.getDisplayedRowAtIndex(
                  this.currentSelectionIdx
                ),
              ],
            });
            this._loadHolidayRequestData();
          },
          (err) => {
            this.dialogService.showSimpleDialog("Error", err, "error");
          }
        );
    });
  }

  onYearSelection(year: string) {
    this.selectedYear = year == this.all_years ? this.all_years : year;
    this.yearToDisplay = this.selectedYear == this.all_years ? null : parseInt(this.selectedYear);
    this._loadHolidayRequestData();
    this._filterData(this.holidayRequests);
  }

  onMonthSelection(month: string) {
    this.selectedMonth = month == this.all_months ? this.all_months : month;
    this.monthToDisplay = this.selectedMonth == this.all_months ? null : parseInt(this.selectedMonth);
    this._loadHolidayRequestData();
    this._filterData(this.holidayRequests);
  }

  onRowSelected(): void { }
  onRowDoubleClicked(): void { }
  validateModel(_: string): string {
    return "";
  }
  isModelValid(): boolean {
    return true;
  }
}
