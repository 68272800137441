export class DashboardDataModel {
  id: number = 0;
  paidTypeHolidaysInYear: number = 0;
  month: number = 0;
  overtime: number = 0;
  targetHours: number = 0;
  userId: number = 0;
  workedHours: number = 0;
  year: number = 0;
  dailyHours: number = 0;
  paidTypeHolidaysInMonth?: number = 0;
  holidaysRemaining?: number = 0;
  nrOfFreeDays?: number = 0;
  isDailyStatsReminderActivated?: boolean;
  name: string;
  notPaidTypeHolidaysInMonth: number = 0;
  notPaidTypeHolidaysInYear: number = 0;
  medicalTypeHolidaysInYear: number = 0;
  medicalTypeHolidaysInMonth: number = 0;
  specialTypeHolidaysInMonth: number = 0;
  specialTypeHolidaysInYear: number = 0;
  specialTypePaidHolidaysInMonth: number = 0;
  specialTypePaidHolidaysInYear: number = 0;
  specialTypeNotPaidHolidaysInMonth: number = 0;
  specialTypeNotPaidHolidaysInYear: number = 0;

  scheduledNotPaidHoliday: number = 0;
  scheduledPaidHoliday: number = 0;
  scheduledMedicalHoliday: number = 0;
  scheduledSpecialHoliday: number = 0;
  scheduledPaidSpecialHoliday: number = 0;
  scheduledNotPaidSpecialHoliday: number = 0;
  
  nationalHolidaysInYear: number = 0;
  nationalHolidaysRemaining: number = 0;
}
