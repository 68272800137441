import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize, takeUntil } from 'rxjs/operators';
import { BenefitCardConstants } from 'src/app/constants/benefit-card-constants';
import { BenefitRequestType } from 'src/app/models/admin-benefits/BenefitRequestType';
import { BenefitRequestWithEnrollment } from 'src/app/models/benefit-request/BenefitRequestWithEnrollment';
import { CreateRecurrentBenefitRequestModel } from 'src/app/models/benefit-request/CreateRecurrentBenefitRequestModel';
import { BenefitCardModel } from 'src/app/models/user-benefits/BenefitCardModel';
import { BenefitEnrollmentStatusEnum } from 'src/app/models/user-benefits/BenefitEnrollmentStatusEnum';
import { BenefitRequestService } from 'src/app/services/benefits/benefitRequest.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { formatDate } from 'src/app/shared/utils/Formatters';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';

@Component({
  selector: 'trackify-recurrent-modal',
  templateUrl: './recurrent-modal.component.html',
  styleUrls: ['./recurrent-modal.component.scss']
})
export class RecurrentModalComponent extends LoadableComponentBase {

  classType: string;
  benefitCard: BenefitCardModel;
  enrollmentStatus = BenefitCardConstants;
  benefitRequestWithEnrollment: BenefitRequestWithEnrollment;

  constructor(private modalRef: BsModalRef,
    private benefitRequestService: BenefitRequestService,
    private dialogService: DialogService) {
    super();
  }

  hideModal(): void {
    this.modalRef.hide();
  }

  cancelBenefitRequest(): void {
    this.startLoader();
    this.benefitRequestService.cancelBenefitRequest(this.benefitCard.benefitRequest.id)
      .pipe(takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.stopLoader();
          this.modalRef.content = { benefitWithEnrollment: this.benefitRequestWithEnrollment }
          this.modalRef.hide();
        }))
      .subscribe(() => {
        const lastEnrollment = this.benefitCard.benefitRequest.benefitRequestType;
        this.benefitRequestWithEnrollment = <BenefitRequestWithEnrollment>{
          benefitRequest: null
        };

        if (lastEnrollment == BenefitRequestType.ADD) {
          this.benefitCard.enrollmentStatus = BenefitEnrollmentStatusEnum.Invalid;
          this.benefitRequestWithEnrollment.enrollmentStatus = BenefitEnrollmentStatusEnum.Invalid;
        } else {
          this.benefitCard.enrollmentStatus = BenefitEnrollmentStatusEnum.Ongoing;
          this.benefitRequestWithEnrollment.enrollmentStatus = BenefitEnrollmentStatusEnum.Ongoing;
        }
        
        this.benefitCard.benefitRequest = null;
        this.dialogService.showSuccessMessage(
          "Success!",
          "Benefit request has been canceled."
        );
      },
        (err) => {
          this.dialogService.showSimpleDialog("Error", err, "error");
        });

    this.benefitCard.enrollmentStatus = null;
  }

  benefitEnrollRequest(): void {
    const createBenefitRequest: CreateRecurrentBenefitRequestModel = {
      benefitId: this.benefitCard.id,
      benefitRequestType: BenefitRequestType.ADD
    };

    this.startLoader();
    this.benefitRequestService.createRecurrentBenefitRequest(createBenefitRequest)
      .pipe(takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.stopLoader();
          this.modalRef.content = { benefitWithEnrollment: this.benefitRequestWithEnrollment }
          this.modalRef.hide();
        }))
      .subscribe((benefitRequest) => {
        this.benefitRequestWithEnrollment = benefitRequest;

        this.dialogService.showSuccessMessage(
          "Success!",
          `A request to enroll ${this.benefitCard.name} has been created!`
        );
      },
        (err) => {
          this.dialogService.showSimpleDialog("Error", err, "error");
        });
  }

  benefitDeleteRequest(): void {
    const createRecurrentBenefitRequest: CreateRecurrentBenefitRequestModel = {
      benefitId: this.benefitCard.id,
      benefitRequestType: BenefitRequestType.DELETE,
    }

    this.startLoader();

    this.benefitRequestService.createRecurrentBenefitRequest(createRecurrentBenefitRequest)
      .pipe(takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.stopLoader();
          this.modalRef.content = { benefitWithEnrollment: this.benefitRequestWithEnrollment }
          this.modalRef.hide();
        }))
      .subscribe((benefitRequest) => {
        this.benefitRequestWithEnrollment = benefitRequest;

        this.dialogService.showSuccessMessage(
          "Success!",
          `A request to delete ${this.benefitCard.name} has been created!`
        );
      },
        (err) => {
          this.dialogService.showSimpleDialog("Error", err, "error");
        });
  }

  getDeadlineDate(): Date {
    const today = new Date();
    // set deadline as the last say of the month
    let deadlineDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
    // substract the deadline number of days
    deadlineDate.setDate(deadlineDate.getDate() - this.benefitCard.deadline);

    return deadlineDate;
  }

  getDeadlineDateFormatted(): string {
    return formatDate(this.getDeadlineDate().toDateString());
  }

  isBeforeDeadline(): boolean {
    return new Date().getDate() <= this.getDeadlineDate().getDate();
  }
}
