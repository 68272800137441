import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../shared/services/api.service";
import { ApiConstants } from "../constants/api-constants";

import { MonthlyStatsModel } from "../models/monthlyStats/MonthlyStatsModel";
import { MonthlySalaryStatsModel } from "../models/monthlyStats/MonthlySalaryStatsModel";
import { ConfirmHolidayTakenModel } from "../models/monthlyStats/ConfirmHolidayTakenModel";
import { DashboardDataModel } from "../models/dashboardData/DashboardDataModel";

@Injectable({
  providedIn: "root",
})
export class MonthlySalaryService {
  private _apiBaseUrl = ApiConstants.MONTLY_SALARY_API_ENDPOINT;

  constructor(private apiService: ApiService) { }

  getUserMonthlySalary(userId: number): Observable<MonthlyStatsModel[]> {
    return this.apiService.get<MonthlyStatsModel[]>(`${this._apiBaseUrl}/user-monthly-salary/${userId}`);
  }

  getUsersMonthlySalaries(): Observable<MonthlySalaryStatsModel[]> {
    return this.apiService.get<MonthlySalaryStatsModel[]>(`${this._apiBaseUrl}/user-monthly-salary`);
  }

  confirmMonthlyStatus(token: string): Observable<void> {
    return this.apiService.put<void>(`${this._apiBaseUrl}/confirm-status`, { token: token })
  }

  confirmHolidays(confirmDto: ConfirmHolidayTakenModel): Observable<MonthlySalaryStatsModel> {
    return this.apiService.put<MonthlySalaryStatsModel>(`${this._apiBaseUrl}/confirm-holidays`, confirmDto);
  }

  getUserDashboardInfo(): Observable<DashboardDataModel> {
    return this.apiService.get<DashboardDataModel>(`${this._apiBaseUrl}/user-dashboard`);
  }

  getRegularUserMonthlySalaries(): Observable<MonthlySalaryStatsModel[]> {
    return this.apiService.get<MonthlySalaryStatsModel[]>(`${this._apiBaseUrl}/personal-monthly-salary`)
  }

  getRegeneratedMonthlySalary(statId: number): Observable<MonthlySalaryStatsModel> {
    return this.apiService.get<MonthlySalaryStatsModel>(`${this._apiBaseUrl}/refresh-statistic/${statId}`)
  }

  getAvailableToConfirmMonthlyStatus(statusId: number): Observable<boolean> {
    return this.apiService.get<boolean>(`${this._apiBaseUrl}/available-to-confirm/${statusId}`);
  }
}
