import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize, takeUntil } from 'rxjs/operators';
import { Company } from '../../models/companies/Company';
import { CompanyService } from '../../services/company.service';
import { GridComponentBase } from '../../shared/components/base/GridComponentBase';
import { DialogService } from '../../shared/services/dialog.service';
import { AgGridUtils } from '../../shared/utils/AgGridUtils';
import { clone } from '../../shared/utils/Utils';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'trackify-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent extends GridComponentBase<Company> implements OnInit {

  @Input() isInTab: boolean = false;
  constructor(
    private dialogService: DialogService,
    private companyService: CompanyService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      { 
        field: 'fullName', 
        headerName: 'Full Name', 
        width: 150, sort: 'asc', 
        suppressSizeToFit: true 
      },
      { 
        field: 'shortName', 
        headerName: 'Short Name', 
        width: 150, 
        suppressSizeToFit: true 
      },
      { 
        field: 'fiscalCode', 
        headerName: 'Fiscal Code', 
        width: 130, 
        suppressSizeToFit: true 
      },
      { 
        field: 'registrationNumber', 
        headerName: 'Reg. Number', 
        width: 130, 
        suppressSizeToFit: true 
      },
      { 
        field: 'bank', 
        headerName: 'Bank',
        type: ['centerColumn', 'setColumn'], 
        width: 170, 
        suppressSizeToFit: true 
      },
      { 
        field: 'iban', 
        headerName: 'IBAN', 
        width: 170, 
        suppressSizeToFit: true },
      { 
        field: 'address', 
        headerName: 'Address', 
        width: 170, 
        suppressSizeToFit: true 
      },
      { 
        valueGetter: (x) => (x.data.upFoodTicketCompanyCode ? x.data.upFoodTicketCompanyCode : "N/A"), 
        headerName: 'UP Food Tickets Code', 
        width: 170, 
        suppressSizeToFit: true 
      },
      { 
        field: 'isUsedInExports', 
        headerName: 'Is Used In Exports', 
        minWidth: 170, 
        suppressSizeToFit: false 
      }
    ]);

    this.gridOptions.overlayNoRowsTemplate = '<span class="ag-overlay-no-rows-center">No company entries.</span>';
  }

  @ViewChild('companyModal', { static: false }) modal: ModalDirective;
  @ViewChild('companyForm', { static: false }) companyForm: NgForm;
  isAddMode: boolean;
  errorMessage: string;

  ngOnInit(): void {
    this.startLoader();
    this.companyService.getCompanies()
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
      .subscribe(companies => {
        this.data = companies;
      });
  }

  closeModal(): void {
    this.companyForm.form.markAsPristine();
    this.companyForm.form.markAsUntouched();
    this.modal.hide();
  }

  onEdit(): void {
    this.updatedItem = clone(this.currentSelection);
    this.isAddMode = false;
    this.modal.toggle();
  }

  onAdd(): void {
    this.updatedItem = new Company();
    this.isAddMode = true;
    this.modal.toggle();
  }

  onDelete(): void {
    this.dialogService.showDialog({
      title: `Are you sure you want to delete the ${this.currentSelection.fullName}?`,
      text: 'The company will be completely removed and cannot be recovered.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DC3545',
      confirmButtonText: 'Delete'
    }).then(result => {
      if (!!result.value) {
        this.dialogService.showDialog({
          title: 'Processing',
          text: 'Deleting selected company',
          allowOutsideClick: false,
          onOpen: () => {
            this.dialogService.showLoading();
          }
        });
        this.gridOptions.api.showLoadingOverlay();
        this.companyService.removeCompany(this.currentSelection.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            this.updateGridAfterDelete();

            this.currentSelection = null;

            this.dialogService.showSuccessMessage('Success!', 'Selected company deleted successfully.');
          });
      }
    });
  }

  saveChanges(): void {
    if (!this.isModelValid()) return;

    if (this.isAddMode) {
      this.gridOptions.api.showLoadingOverlay()
      this.companyService.addCompany(this.updatedItem)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(company => {
          this.data.push(company);
          this.updateGridAfterAdd(company);
          this.gridOptions.api.hideOverlay();
          this.gridOptions.api.deselectAll();
          this.currentSelection = undefined;

          this.dialogService.showSuccessMessage('Success!', 'Company added successfully.');
          this.modal.hide();
        });
    }
    else {
      this.gridOptions.api.showLoadingOverlay()
      this.companyService.editCompany(this.updatedItem)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(company => {
          this.data[this.currentSelectionIdx] = company;
          this.updateGridAfterEdit(company);
          this.gridOptions.api.hideOverlay();
          this.currentSelection = clone(company);

          this.dialogService.showSuccessMessage('Success!', 'Company details updated successfully.');
          this.modal.hide();
        });
    }
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;

    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(u => u.id == this.currentSelection.id));
  }

  onRowDoubleClicked(_: any): void {
    this.onEdit();
  }

  validateModel(prop: string): string { return prop; }

  isModelValid(): boolean {
    this.errorMessage = '';

    if (!this.updatedItem) {
      this.errorMessage = 'Invalid model, please close the modal and try again!';
      return false;
    }

    return true;
  }

}
