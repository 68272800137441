import { SidenavConstants } from "src/app/constants/sidenav-constants";
import { RoleType } from "src/app/models/users/RoleType";
import { NavMenuItem } from "./NavMenuItem";

export const appMenuItems: NavMenuItem[] = [
  {
    name: SidenavConstants.MENU_DASHBOARD,
    route: { path: "dashboard-user" },
    icon: "text_align-center",
    minRole: RoleType.Regular,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_DASHBOARD,
    route: { path: "dashboard-administrator" },
    icon: "text_align-center",
    minRole: RoleType.Admin,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_MY_STATS,
    route: { path: "dashboard-user" },
    icon: "far fa-clock",
    minRole: RoleType.Admin,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_USERS,
    route: { path: "users" },
    icon: " fa fa-users",
    minRole: RoleType.Admin,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_MONTHLY_STATS,
    route: { path: "monthly-stats" },
    icon: "business_chart-bar-32",
    minRole: RoleType.Admin,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_DAILY_STATS,
    route: { path: "daily-stats" },
    icon: "design_bullet-list-67",
    minRole: RoleType.Admin,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_SETTINGS,
    route: { path: "settings" },
    icon: "ui-2_settings-90",
    minRole: RoleType.Admin,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_HOLIDAY_DROPDOWN,
    route: { path: "/" },
    icon: "fas fa-sun",
    minRole: RoleType.Regular,
    children: [
      {
        name: SidenavConstants.MENU_HOLIDAY_REQUEST,
        route: { path: "holidays" },
        icon: "far fa-calendar-plus",
        minRole: RoleType.Regular,
        children: [],
        isDropDownActive: false,
      },
      {
        name: SidenavConstants.MENU_HOLIDAY_STATISTICS,
        route: { path: "user-holiday-charts" },
        icon: "fas fa-umbrella-beach",
        minRole: RoleType.Regular,
        children: [],
        isDropDownActive: false,
      },
    ],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_HOLIDAY_DROPDOWN,
    route: { path: "/" },
    icon: "fas fa-sun",
    minRole: RoleType.Admin,
    children: [
      {
        name: SidenavConstants.MENU_HOLIDAY_REQUEST,
        route: { path: "holidays" },
        icon: "fas fa-umbrella-beach",
        minRole: RoleType.Admin,
        children: [],
        isDropDownActive: false,
      },
      {
        name: SidenavConstants.MENU_HOLIDAY_STATISTICS,
        route: { path: "holiday-stats" },
        icon: "far fa-calendar-plus",
        minRole: RoleType.Admin,
        children: [],
        isDropDownActive: false,
      },
      {
        name: SidenavConstants.MENU_SORTABLE_HOLIDAY,
        route: { path: "sortable-holiday" },
        icon: "fas fa-plane-departure",
        minRole: RoleType.Admin,
        children: [],
        isDropDownActive: false,
      },
    ],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_REGULAR_USER_REVIEWS,
    route: { path: "regular-user-reviews" },
    icon: "fas fa-clipboard-list",
    minRole: RoleType.Regular,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_MONTHLY_STATS,
    route: { path: "user-monthly-stats" },
    icon: "business_chart-bar-32",
    minRole: RoleType.Regular,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_PLANNER,
    route: { path: "planner" },
    icon: "fas fa-clipboard-list",
    minRole: RoleType.Admin,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_PLANNER,
    route: { path: "monthly-planning" },
    icon: "fas fa-clipboard-list",
    minRole: RoleType.Regular,
    children: [],
    isDropDownActive: false,
  }
];

export const clientsMenuItem: NavMenuItem = {
  name: SidenavConstants.MENU_CLIENTS,
  route: { path: "clients" },
  icon: "fas fa-address-card",
  minRole: RoleType.Admin,
  children: [],
  isDropDownActive: false,
}

export const projectsMenuItem: NavMenuItem = {
  name: SidenavConstants.MENU_PROJECTS,
  route: { path: "projects" },
  icon: "fas fa-project-diagram",
  minRole: RoleType.Admin,
  children: [],
  isDropDownActive: false,
}

export const benefitsMenuItems: NavMenuItem[] = [
  {
    name: SidenavConstants.MENU_BENEFITS_ADMIN,
    route: { path: "admin-benefits" },
    icon: "fa fa-plus-square",
    minRole: RoleType.Admin,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_BENEFITS,
    route: { path: "benefits" },
    icon: "fa fa-gift",
    minRole: RoleType.Regular,
    children: [],
    isDropDownActive: false,
  },
  {
    name: SidenavConstants.MENU_BENEFITS,
    route: { path: "benefits" },
    icon: "fa fa-gift",
    minRole: RoleType.Admin,
    children: [],
    isDropDownActive: false,
  },
]