import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MonthlyStatus } from "../components/monthly-status/MonthlyStatus";
import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";

@Injectable({
  providedIn: "root",
})
export class MonthlyStatsService {
  private _apiBaseUrl = ApiConstants.MONTHLY_STATS_ENDPOINT;

  constructor(private apiService: ApiService) { }

  getMonthlyStatus(token: string): Observable<MonthlyStatus> {
    return this.apiService.post<MonthlyStatus>(`${this._apiBaseUrl}`, { token: token });
  }
}
