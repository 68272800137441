import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ColorsConstants } from 'src/app/constants/colors-constants';
import { StatusConstants } from 'src/app/constants/statuses-constants';
import { BenefitRequestEventModel } from 'src/app/models/user-benefits/BenefitRequestEventModel';
import { ParticipantEventStatus } from 'src/app/models/user-benefits/ParticipantEventStatus';
import { UserEnrollmentInEvent } from 'src/app/models/user-benefits/UserEnrollmentInEvent';
import { UserBriefModel } from 'src/app/models/users/UserBriefModel';
import { UserParticipantModel } from 'src/app/models/users/UserParticipantModel';
import { BenefitRequestService } from 'src/app/services/benefits/benefitRequest.service';
import { UserService } from 'src/app/services/user.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { formatDate } from 'src/app/shared/utils/Formatters';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';

@Component({
  selector: 'trackify-event-details-modal',
  templateUrl: './event-details-modal.component.html',
  styleUrls: ['./event-details-modal.component.scss']
})
export class EventDetailsModalComponent extends LoadableComponentBase implements OnInit {
  benefitRequestId: number;
  isAdmin: boolean = false;
  benefitEvent: BenefitRequestEventModel;
  formatDateForTable = formatDate;
  participantsDetails: string;
  participants: string;
  pricePerParticipant: number;
  currentUser: UserBriefModel;
  userEnrollmentInEvent: UserEnrollmentInEvent = new UserEnrollmentInEvent;

  constructor(private modalRef: BsModalRef,
    private benefitRequestService: BenefitRequestService,
    private userService: UserService,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.startLoader();
    this._loadData();
  }

  private _loadData(): void {
    forkJoin([this.benefitRequestService.getBenefitRequestEventDetails(this.benefitRequestId),
    this.userService.getCurrentUser()])
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
        this.stopLoader();
      }))
      .subscribe(([benefit, user]: [BenefitRequestEventModel, UserBriefModel]) => {
        this.benefitEvent = benefit;
        this._moveArchivedParticipantsToTheEnd();
        this.currentUser = user;
        const numberOfParticipants = this.benefitEvent.participants.length;
        this.pricePerParticipant = this.benefitEvent.benefitPoints / (numberOfParticipants);
        this.userEnrollmentInEvent.isNotInvited = !this.benefitEvent.participants.some((participant: UserParticipantModel) => participant.id == this.currentUser.id);
        this.userEnrollmentInEvent.isJoined = this.benefitEvent.participants.some((participant: UserParticipantModel) => participant.id == this.currentUser.id && participant.status.name == StatusConstants.ACCEPTED_STATUS);
        this.userEnrollmentInEvent.isEventOwner = (this.currentUser.id == this.benefitEvent.owner.id);
      });
  }

  private _moveArchivedParticipantsToTheEnd(): void {
    this.benefitEvent.participants.sort((a,b) => (a.isArchived && !b.isArchived ? 1 : -1));
  }

  hideModal(): void {
    this.modalRef.hide();
  }

  onAccept(): void {
    const confirmText = this.userEnrollmentInEvent.isNotInvited ? `Join` : `Accept`;
    const titleText = this.userEnrollmentInEvent.isNotInvited
      ? `Are you sure you want to join ${this.benefitEvent.benefitName}?`
      : `Are you sure you want to accept ${this.benefitEvent.benefitName} benefit request invitation?`;

    this.dialogService
      .showDialog({
        title: titleText,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: ColorsConstants.CONFIRM_BUTTON_COLOR_GREEN,
        confirmButtonText: confirmText,
      })
      .then((result) => {
        if (!result.value) return;
        this.startLoader();
        this.benefitRequestService
          .joinBenefitEvent(this.benefitEvent.id)
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
            this.stopLoader();
            this.hideModal();
          }))
          .subscribe(() => {
            this.modalRef.content = { enrollmentStatus: ParticipantEventStatus.Joined }
            this.dialogService.showSuccessMessage(
              "Success!",
              `You have accepted benefit ${this.benefitEvent.benefitName} invitation`
            );
          },
            (err) => {
              this.dialogService.showErrorMessage("Error", err);
            });
      });
  }

  onDecline(): void {
    this.dialogService
      .showDialog({
        title: `Are you sure you want to decline ${this.benefitEvent.benefitName} benefit request?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: ColorsConstants.CONFIRM_BUTTON_COLOR_RED,
        confirmButtonText: "Decline",
      })
      .then((result) => {
        if (!result.value) return;
        this.startLoader();
        this.benefitRequestService
          .leaveBenefitEvent(this.benefitEvent.id)
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
            this.stopLoader();
            this.hideModal();
          }))
          .subscribe(() => {
            this.modalRef.content = { enrollmentStatus: ParticipantEventStatus.NotInvited }
            this.dialogService.showSuccessMessage(
              "Success!",
              `You have declined benefit ${this.benefitEvent.benefitName} invitation`
            );
          },
            (err) => {
              this.dialogService.showSimpleDialog("Error", err, "error");
            });
      });
  }

  onComplete(): void {
    this.dialogService
      .showDialog({
        title: `Are you sure you want to complete this benefit event?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#18ce0f",
        confirmButtonText: "Complete",
      })
      .then((result) => {
        if (!result.value) return;
        this.startLoader();
        this.benefitRequestService
          .completeBenefitRequestEvent(this.benefitEvent.id)
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
            this.stopLoader();
            this.hideModal();
          }))
          .subscribe(() => {
            this.modalRef.content = { isCompleted: true }
            this.dialogService.showSuccessMessage(
              "Success!",
              "Selected benefit event has been completed. Admins can now approve this event."
            );
          },
            (err) => {
              this.dialogService.showSimpleDialog("Error", err, "error");
            });
      });
  }

  onDelete(): void {
    this.dialogService
      .showDialog({
        title: `Are you sure you want to delete this benefit event?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DC3545",
        confirmButtonText: "Delete",
      })
      .then((result) => {
        if (!result.value) return;
        this.startLoader();
        this.benefitRequestService
          .cancelBenefitRequest(this.benefitEvent.id)
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
            this.stopLoader();
            this.modalRef.content = { deletedEvent: this.benefitEvent.id }
            this.hideModal();
          }))
          .subscribe(() => {
            this.dialogService.showSuccessMessage(
              "Success!",
              "Selected benefit request has been deleted."
            );
          },
            (err) => {
              this.dialogService.showSimpleDialog("Error", err, "error");
            });
      });
  }
}
