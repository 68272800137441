export class Company {
    id: number;
    fullName: string;
    shortName: string;
    address: string;
    fiscalCode: string;
    registrationNumber: string;
    bank: string;
    iban: string;
    isUsedInExports: boolean;
    upFoodTicketCompanyCode: string;
}