import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BenefitRequestService } from 'src/app/services/benefits/benefitRequest.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';
import { first } from 'rxjs/operators';
import { finalize, takeUntil } from 'rxjs/operators';
import { RequestStatus } from 'src/app/models/statuses/RequestStatus';


@Component({
  selector: 'trackify-benefit-approval',
  templateUrl: './benefit-approval.component.html',
  styleUrls: ['./benefit-approval.component.scss']
})
export class BenefitApprovalComponent extends LoadableComponentBase implements OnInit {

  public requestStatus = RequestStatus.NotOpened;
  RequestStatus = RequestStatus;

  constructor(
    private dialogService: DialogService,
    private benefitRequestService: BenefitRequestService,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.startLoader();
    const benefitRequestId = this.route.snapshot.paramMap.get("id");
    if(!benefitRequestId) {
      this.dialogService.showErrorMessage("Eroor ","there was a problem").then(() => {
        this.requestStatus = RequestStatus.Error;
      });
      this.stopLoader();
    }
    else {
      this.benefitRequestService.getBenefitRequestDetails(parseInt(benefitRequestId))
      .pipe(first(), takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
      .subscribe((result) => {
        this.dialogService.showApprovalBenefitRequest(result.owner.firstName, result.benefit.name).then((result) => {
          if (result.isDismissed) {
            this.requestStatus = RequestStatus.Canceled;
          } else if (result.isConfirmed) {
            this.benefitRequestService.approveBenefitRequest(parseInt(benefitRequestId)).pipe(first(), takeUntil(this.ngUnsubscribe))
            .subscribe((_) => {
              this.requestStatus = RequestStatus.Approved;
            });
          } else if (result.isDenied) {
            this.benefitRequestService.declineBenefitRequest(parseInt(benefitRequestId)).pipe(first(), takeUntil(this.ngUnsubscribe))
            .subscribe((_) => {
              this.requestStatus = RequestStatus.Rejected;
            });
          }
        }, _ => {
          this.requestStatus = RequestStatus.Error;
        });

      },
      (err) => {
        this.dialogService.showErrorMessage("Oops...", err).then(() => {
          this.requestStatus = RequestStatus.Error;
        });
      })
    }
  }
}
