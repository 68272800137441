import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";

import { ApiService } from "../shared/services/api.service";

import { ReviewTypeModel } from "../models/reviewTypes/ReviewTypeModel";

@Injectable({
  providedIn: "root",
})
export class ReviewTypeService {
  private _apiBaseUrl = ApiConstants.REVIEW_TYPE_API_ENDPOINT;

  constructor(private apiService: ApiService) {}

  public getReviewTypes(): Observable<ReviewTypeModel[]> {
    return this.apiService.get<ReviewTypeModel[]>(`${this._apiBaseUrl}`);
  }
}
