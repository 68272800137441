import { Component, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

import { DialogService } from "src/app/shared/services/dialog.service";

import { SettingsService } from "src/app/services/settings.service";
import { UserService } from "src/app/services/user.service";

import { SettingsModel } from "src/app/models/settings/SettingsModel";
import { UserSettingModel } from "src/app/models/settings/UserSettingModel";
import { UsernameSettingModel } from "src/app/models/settings/UsernameSettingModel";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { formatDate } from "src/app/shared/utils/Formatters";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";

@Component({
  selector: "trackify-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent extends GridComponentBase<SettingsModel>
  implements OnInit {
  admins: UserSettingModel[] = [];
  dropdownSelectedAdmin: string;
  dropdownUsernames: UsernameSettingModel[] = [];

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    private dialogService: DialogService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        valueGetter: (x) => `${x.data.firstName}  ${x.data.lastName}`,
        headerName: "Name",
        width: 50,
        suppressSizeToFit: false,
        sort: 'asc'
      },
      {
        headerName: "Remove",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-primary",
          buttonText: "",
          iconClass: "fas fa-trash-alt",
          onClick: this._onDelete.bind(this),
        },
      },
    ]);
    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No settings entries.</span>';
  }

  formatDateForTable = formatDate;

  ngOnInit() {
    this.startLoader();
    this._loadSettingsData();
  }

  private _loadSettingsData() {
    forkJoin([
      this.settingsService.getAllSettings(),
      this.userService.getAdmins(),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader())
      )
      .subscribe(([settings, adminList]) => {
        this.data = settings;
        this.admins = adminList;

        this.admins.forEach((admin) => {
          const settingsName: UsernameSettingModel = {
            id: admin.id,
            name: admin.firstName + " " + admin.lastName,
          };
          const index = this.data.findIndex(
            (setting) => setting.userId == admin.id
          );
          if (index < 0) this.dropdownUsernames.push(settingsName);
        });
      });
  }

  addSetting() {
    if (
      this.dropdownUsernames.find(
        (user) => user.name == this.dropdownSelectedAdmin
      )
    ) {
      if (!this.dropdownSelectedAdmin) {
        this.dialogService.showErrorMessage(
          "You must select an admin from dropdown",
          ""
        );
      } else {
        this.dialogService
          .showDialog({
            title: this.dropdownSelectedAdmin,
            text: "Do you want to add this setting?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Confirm",
          })
          .then((result) => {
            if (!!result.value) {
              if (this.dropdownSelectedAdmin != null) {
                const setting = this.dropdownUsernames.find(
                  (username) => username.name == this.dropdownSelectedAdmin
                );
                this.gridOptions.api.showLoadingOverlay()
                this.settingsService
                  .addSetting(setting.id)
                  .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe(
                    (result) => {
                      this.data.push(result);
                      const index = this.dropdownUsernames.findIndex(
                        (e) => result.userId == e.id
                      );
                      this.dropdownUsernames.splice(index, 1);
                      this.updateGridAfterAdd(result);
                      this.gridOptions.api.hideOverlay();
                    },
                    (_) => {
                      this.dialogService.showServerError();
                    }
                  );
              }
              this.dropdownSelectedAdmin = null;
            }
          });
      }
    } else {
      this.dialogService.showErrorMessage(
        "You entered an invalid input",
        "Try to select an admin from dropdown"
      );
      this.dropdownSelectedAdmin = null;
    }
  }

  showErrorWhenDropdownEmpty() {
    if (this.dropdownUsernames.length == 0)
      this.dialogService.showSimpleDialog("There are no admins left to add");
  }

  private _onDelete(e) {
    this.deleteSetting(e.data.id);
  }

  deleteSetting(settingId: number) {
    this.dialogService
      .showDialog({
        text: "Do you want to delete selected setting?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      })
      .then(
        (result) => {
          if (!!result.value) {
            this.gridOptions.api.showLoadingOverlay()
            this.settingsService
              .deleteSetting(settingId)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((_) => {
                const settingIndex = this.data.findIndex(
                  (s) => s.id == settingId
                );
                const deleteSetting: UsernameSettingModel = {
                  id: this.data[settingIndex].userId,
                  name:
                    this.data[settingIndex].firstName +
                    " " +
                    this.data[settingIndex].lastName,
                };
                this.data.splice(settingIndex, 1);
                this.dropdownUsernames.push(deleteSetting);
                this.updateGridAfterDelete();
                this.gridOptions.api.hideOverlay();
                this.dialogService.showSuccessMessage(
                  "Deleted successfully",
                  ""
                );
              });
          }
        },
        (err) => {
          this.dialogService.showSimpleDialog("Error", err, "error");
          this.gridOptions.api.hideOverlay();
        }
      );
  }

  onRowSelected(): void { }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }
}
