import { HolidayStatsConstants } from './../../../constants/holiday-stats-constants';
import { BarChartModel } from './../../../models/holiday-stats/BarChartModel';
import { forkJoin } from 'rxjs';
import { ConsumedNationalHolidayStatistic } from './../../../models/holiday-stats/ConsumedNationalHolidayStatistic';
import { HolidayTypeTimeStatistic } from 'src/app/models/holiday-stats/HolidayTypeTimeStatistic';
import { finalize, takeUntil } from 'rxjs/operators';
import { PieChartModel } from './../../../models/holiday-stats/PieChartModel';
import { HolidayStatsService } from 'src/app/services/holiday-request/holiday-stats.service';
import { UserInfoModel } from './../../../models/users/UserInfoModel';
import { UserService } from './../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from 'src/app/models/users/UserModel';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';
import * as moment from 'moment';
import { HolidayStatDetailedModel } from 'src/app/models/holiday-stats/HolidayStatDetailedModel';
import { HolidayRequestTypeService } from 'src/app/services/holiday-request/holiday-request-type.service';
import { HolidayRequestTypeModel } from 'src/app/models/holiday-request/HolidayRequestTypeModel';

export const HOLIDAY_TYPES_TITLE = "Taken holiday types";
export const CONSUMED_NATIONAL_HOLIDAYS_TITLE = "Consumed national holidays";
export const VACATION_TAKEN_BY_DAY = "Taken vacations by day";
export const VACATION_TAKEN_BY_MONTH = "Taken vacations by week";
export const WARNING_THREE_MONTHS_LIMIT = HolidayStatsConstants.WARNING_THREE_MONTHS_LIMIT;
export const WARNING_SIX_MONTHS_LIMIT = HolidayStatsConstants.GREEN_SIGNAL_LIMIT;

@Component({
  selector: 'trackify-holiday-charts',
  templateUrl: './holiday-charts.component.html',
  styleUrls: ['./holiday-charts.component.scss']
})
export class HolidayChartsComponent extends LoadableComponentBase implements OnInit {

  user: UserModel | UserInfoModel;

  currentMonthHolidayTypeChartModel: PieChartModel = new PieChartModel(HOLIDAY_TYPES_TITLE);
  previousThreeHolidayTypeChartModel: PieChartModel = new PieChartModel(HOLIDAY_TYPES_TITLE);
  previousSixHolidayTypeChartModel: PieChartModel = new PieChartModel(HOLIDAY_TYPES_TITLE);
  previousTwelveHolidayTypeChartModel: PieChartModel = new PieChartModel(HOLIDAY_TYPES_TITLE);
  nextThreeHolidayTypeChartModel: PieChartModel = new PieChartModel(HOLIDAY_TYPES_TITLE);

  currentNationalChartModel: PieChartModel = new PieChartModel(CONSUMED_NATIONAL_HOLIDAYS_TITLE);
  previousThreeNationalChartModel: PieChartModel = new PieChartModel(CONSUMED_NATIONAL_HOLIDAYS_TITLE);
  previousSixNationalChartModel: PieChartModel = new PieChartModel(CONSUMED_NATIONAL_HOLIDAYS_TITLE);
  previousTwelveNationalChartModel: PieChartModel = new PieChartModel(CONSUMED_NATIONAL_HOLIDAYS_TITLE);
  hadCurrentMonthNationalHolidays: boolean = false;
  hadPastThreeMonthsNationalHolidays: boolean = false;
  hadPastSixMonthsNationalHolidays: boolean = false;
  hadPastTwelveMonthsNationalHolidays: boolean = false;

  hadHolidaysInCurrentmonth: boolean = false;
  hadHolidaysInThePastThreeMonths: boolean = false;
  hadHolidaysInThePastSixMonths: boolean = false;
  hadHolidaysInThePastTwelveMonths: boolean = false;
  hasScheduledHolidays: boolean = false;

  dayModel: BarChartModel;
  pastThreeMonthsModel: BarChartModel;
  pastSixMonthsModel: BarChartModel;
  pastTwelveMonthsModel: BarChartModel;
  nextThreeMonthsModel: BarChartModel;

  chartDays: string[] = [];
  dailyValues: any[] = [];

  chartPastTwelveMonthWeeks: string[] = [];
  chartPastSixMonthWeeks: string[] = [];
  chartPastThreeMonthWeeks: string[] = [];
  chartNextThreeMonthWeeks: string[] = [];

  pastTwelveMonthsValues: any[] = [];
  pastSixMonthsValues: any[] = [];
  pastThreeMonthsValues: any[] = [];
  nextThreeMonthsValues: any[] = [];

  pastThreeMonthsTotal: number = 0;
  pastSixMonthsTotal: number = 0;
  pastTwelveMonthsTotal: number = 0;
  warningLimitThreeMonths = WARNING_THREE_MONTHS_LIMIT;
  warningLimitSixMonths = WARNING_SIX_MONTHS_LIMIT;
  warningLimitTwelveMonths: number = 0;

  noDataOyAxis: any;
  holidayTypes: HolidayRequestTypeModel[] = [];

  constructor(
    private userService: UserService,
    private holidayStatsService: HolidayStatsService,
    private holidayRequestTypeService: HolidayRequestTypeService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this._getUserChartStatistic();
  }

  private _getUserChartStatistic(): void {
    this.startLoader();
    const userId = this.route.snapshot.params.id;

    if (userId) {
      this.userService.getUser(userId).subscribe((result) => {
        this.user = result;
        this.warningLimitTwelveMonths = (80 * this.user.currentHolidayBase) / 100;
        this._getDataForAdmin(userId);
      });
    } else {
      this.userService.getCurrentUserDetails().subscribe((result) => {
        this.user = result;
        this.warningLimitTwelveMonths = (80 * this.user.currentHolidayBase) / 100;
        this._getDataForUser(this.user.id);
      })
    }
  }

  private _getDataForAdmin(userId: number): void {
    forkJoin([this.holidayRequestTypeService.getAll(),
    this.holidayStatsService.getTakenHolidaysTypeStatistic(userId),
    this.holidayStatsService.getCondumedNationalHolidaysStatistic(userId),
    this.holidayStatsService.getHolidaysStatistic(userId)])
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe(([holidayTypes, holidayTypesResult, consumedNationalResult, holidayStatsResult]) => {
        this.holidayTypes = holidayTypes;
        this._generateHolidayTypeChartData(holidayTypesResult);
        this._generateNationalChartData(consumedNationalResult);
        this._generateHolidayStatisticsDayCharData(holidayStatsResult);
        this._generateHolidayStatisticsMonthCharData(holidayStatsResult);
      });
  }

  private _getDataForUser(userId: number): void {
    forkJoin([this.holidayRequestTypeService.getAll(),
    this.holidayStatsService.getTakenHolidaysTypeStatisticCurrentUser(userId),
    this.holidayStatsService.getCondumedNationalHolidaysStatisticForUser(userId),
    this.holidayStatsService.getHolidaysStatisticForUser(userId)])
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe(([holidayTypes, holidayTypesResult, consumedNationalResult, holidayStatsResult]) => {
        this.holidayTypes = holidayTypes;
        this._generateHolidayTypeChartData(holidayTypesResult);
        this._generateNationalChartData(consumedNationalResult);
        this._generateHolidayStatisticsDayCharData(holidayStatsResult);
        this._generateHolidayStatisticsMonthCharData(holidayStatsResult);
      });
  }

  private _generateHolidayTypeChartData(data: HolidayTypeTimeStatistic): void {
    data.currentMonthHolidays.forEach((holiday) => {
      if (holiday.holidayTypeName.length > 20) {
        this.currentMonthHolidayTypeChartModel.labels.push(holiday.holidayTypeName.substring(0, 20) + "...");
      }
      else {
        this.currentMonthHolidayTypeChartModel.labels.push(holiday.holidayTypeName);
      }

      this.currentMonthHolidayTypeChartModel.series.push(holiday.taken);

      if (holiday.taken > 0) {
        this.hadHolidaysInCurrentmonth = true;
      }
    });

    data.pastThreeMonthsHolidays.forEach((holiday) => {
      if (holiday.holidayTypeName.length > 20) {
        this.previousThreeHolidayTypeChartModel.labels.push(holiday.holidayTypeName.substring(0, 20) + "...");
      }
      else {
        this.previousThreeHolidayTypeChartModel.labels.push(holiday.holidayTypeName);
      }

      this.previousThreeHolidayTypeChartModel.series.push(holiday.taken);

      if (holiday.taken > 0) {
        this.hadHolidaysInThePastThreeMonths = true;
      }
    });

    data.pastSixMonthsHolidays.forEach((holiday) => {
      if (holiday.holidayTypeName.length > 20) {
        this.previousSixHolidayTypeChartModel.labels.push(holiday.holidayTypeName.substring(0, 20) + "...");
      }
      else {
        this.previousSixHolidayTypeChartModel.labels.push(holiday.holidayTypeName);
      }

      this.previousSixHolidayTypeChartModel.series.push(holiday.taken);

      if (holiday.taken > 0) {
        this.hadHolidaysInThePastSixMonths = true;
      }
    });

    data.pastTwelveMonthsHolidays.forEach((holiday) => {
      if (holiday.holidayTypeName.length > 20) {
        this.previousTwelveHolidayTypeChartModel.labels.push(holiday.holidayTypeName.substring(0, 20) + "...");
      } else {
        this.previousTwelveHolidayTypeChartModel.labels.push(holiday.holidayTypeName);
      }

      this.previousTwelveHolidayTypeChartModel.series.push(holiday.taken);

      if (holiday.taken > 0) {
        this.hadHolidaysInThePastTwelveMonths = true;
      }
    });

    data.nextThreeMonthsHolidays.forEach((holiday) => {
      if (holiday.holidayTypeName.length > 20) {
        this.nextThreeHolidayTypeChartModel.labels.push(holiday.holidayTypeName.substring(0, 20) + "...");
      }
      else {
        this.nextThreeHolidayTypeChartModel.labels.push(holiday.holidayTypeName);
      }

      this.nextThreeHolidayTypeChartModel.series.push(holiday.taken);

      if (holiday.taken > 0) {
        this.hasScheduledHolidays = true;
      }
    });
  }

  private _generateNationalChartData(data: ConsumedNationalHolidayStatistic): void {
    this.currentNationalChartModel.labels = ["Consumed", "Not consumed"]
    this.currentNationalChartModel.series.push(data.currentMonthConsumedNationalHolidays.consumedDays);
    this.currentNationalChartModel.series.push(data.currentMonthConsumedNationalHolidays.totalDays - data.currentMonthConsumedNationalHolidays.consumedDays);
    this.hadCurrentMonthNationalHolidays = data.currentMonthConsumedNationalHolidays.totalDays != 0;

    this.previousThreeNationalChartModel.labels = ["Consumed", "Not consumed"]
    this.previousThreeNationalChartModel.series.push(data.pastThreeMonthsConsumedNationalHolidays.consumedDays);
    this.previousThreeNationalChartModel.series.push(data.pastThreeMonthsConsumedNationalHolidays.totalDays - data.pastThreeMonthsConsumedNationalHolidays.consumedDays);
    this.hadPastThreeMonthsNationalHolidays = data.pastThreeMonthsConsumedNationalHolidays.totalDays != 0;

    this.previousSixNationalChartModel.labels = ["Consumed", "Not consumed"]
    this.previousSixNationalChartModel.series.push(data.pastSixMonthsConsumedNationalHolidays.consumedDays);
    this.previousSixNationalChartModel.series.push(data.pastSixMonthsConsumedNationalHolidays.totalDays - data.pastSixMonthsConsumedNationalHolidays.consumedDays);
    this.hadPastSixMonthsNationalHolidays = data.pastSixMonthsConsumedNationalHolidays.totalDays != 0;

    this.previousTwelveNationalChartModel.labels = ["Consumed", "Not consumed"]
    this.previousTwelveNationalChartModel.series.push(data.pastTwelveMonthsConsumedNationalHolidays.consumedDays);
    this.previousTwelveNationalChartModel.series.push(data.pastTwelveMonthsConsumedNationalHolidays.totalDays - data.pastTwelveMonthsConsumedNationalHolidays.consumedDays);
    this.hadPastTwelveMonthsNationalHolidays = data.pastTwelveMonthsConsumedNationalHolidays.totalDays != 0;
  }

  private _generateHolidayStatisticsDayCharData(data: HolidayStatDetailedModel): void {
    this.holidayTypes.forEach(type => {
      let valueIndex = this.dailyValues.findIndex(value => value.name == type.name);
      if (valueIndex < 0) {
        this.dailyValues.push({
          name: type.name,
          data: []
        });
      }
    });

    data.currentMonthHolidays.forEach((stat) => {
      this.chartDays.push(moment(stat.holidayDate).format("Do MMM YY"));

      this.dailyValues.forEach((label) => {
        if (label.name == stat.holidayType) {
          label.data.push(stat.hourDuration);
        } else {
          this.noDataOyAxis = 0;
          label.data.push(this.noDataOyAxis);
        }
      });
    });

    this.dayModel = new BarChartModel(VACATION_TAKEN_BY_DAY, this.dailyValues, this.chartDays)
  }

  private _generateHolidayStatisticsMonthCharData(data: HolidayStatDetailedModel): void {
    this.holidayTypes.forEach(type => {
      let pastTwelveValueIndex = this.pastTwelveMonthsValues.findIndex(value => value.name == type.name);
      if (pastTwelveValueIndex < 0) {
        this.pastTwelveMonthsValues.push({
          name: type.name,
          data: []
        });
      }

      let pastSixValueIndex = this.pastSixMonthsValues.findIndex(value => value.name == type.name);
      if (pastSixValueIndex < 0) {
        this.pastSixMonthsValues.push({
          name: type.name,
          data: []
        });
      }

      let pastThreeValueIndex = this.pastThreeMonthsValues.findIndex(value => value.name == type.name);
      if (pastThreeValueIndex < 0) {
        this.pastThreeMonthsValues.push({
          name: type.name,
          data: []
        });
      }

      let nextThreeValueIndex = this.nextThreeMonthsValues.findIndex(value => value.name == type.name);
      if (nextThreeValueIndex < 0) {
        this.nextThreeMonthsValues.push({
          name: type.name,
          data: []
        });
      }
    });

    data.pastTwelveMonthsHolidays.forEach(stat => {
      this.chartPastTwelveMonthWeeks.push(stat.label);

      this.pastTwelveMonthsValues.forEach((label) => {
        stat.takenHolidayByType.forEach(takenHolidays => {
          if (takenHolidays.holidayTypeName == label.name) {
            if (takenHolidays.taken > 0) {
              label.data.push(takenHolidays.taken)
              this.pastTwelveMonthsTotal += takenHolidays.taken;
            } else {
              label.data.push(0);
            }
          }
        });
      });
    });

    data.pastSixMonthsHolidays.forEach(stat => {
      this.chartPastSixMonthWeeks.push(stat.label);

      this.pastSixMonthsValues.forEach((label) => {
        stat.takenHolidayByType.forEach(takenHolidays => {
          if (takenHolidays.holidayTypeName == label.name) {
            if (takenHolidays.taken > 0) {
              label.data.push(takenHolidays.taken)
              this.pastSixMonthsTotal += takenHolidays.taken;
            } else {
              label.data.push(0);
            }
          }
        });
      });
    });

    data.pastThreeMonthsHolidays.forEach(stat => {
      this.chartPastThreeMonthWeeks.push(stat.label);

      this.pastThreeMonthsValues.forEach((label) => {
        stat.takenHolidayByType.forEach(takenHolidays => {
          if (takenHolidays.holidayTypeName == label.name) {
            if (takenHolidays.taken > 0) {
              label.data.push(takenHolidays.taken)
              this.pastThreeMonthsTotal += takenHolidays.taken;
            } else {
              label.data.push(0);
            }
          }
        });
      });
    });

    data.nextThreeMonthsHolidays.forEach(stat => {
      this.chartNextThreeMonthWeeks.push(stat.label);

      this.nextThreeMonthsValues.forEach((label) => {
        stat.takenHolidayByType.forEach(takenHolidays => {
          if (takenHolidays.holidayTypeName == label.name) {
            if (takenHolidays.taken > 0) {
              label.data.push(takenHolidays.taken)
            } else {
              label.data.push(0);
            }
          }
        });
      });
    });

    this.pastThreeMonthsModel = new BarChartModel(VACATION_TAKEN_BY_MONTH, this.pastThreeMonthsValues, this.chartPastThreeMonthWeeks)
    this.pastSixMonthsModel = new BarChartModel(VACATION_TAKEN_BY_MONTH, this.pastSixMonthsValues, this.chartPastSixMonthWeeks)
    this.pastTwelveMonthsModel = new BarChartModel(VACATION_TAKEN_BY_MONTH, this.pastTwelveMonthsValues, this.chartPastTwelveMonthWeeks)
    this.nextThreeMonthsModel = new BarChartModel(VACATION_TAKEN_BY_MONTH, this.nextThreeMonthsValues, this.chartNextThreeMonthWeeks)
  }
}
