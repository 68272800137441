import { clone, compareStringsIgnoreCase } from "../../utils/Utils";
import * as moment from "moment";
import { MomentConstants } from "../../../constants/moment-constants";
import { LoadableComponentBase } from "../../utils/LoadableComponentBase";
import { ColDef, ColGroupDef, GridOptions } from "ag-grid-community";
import { getGridOptions } from "../ag-grid";
import { checkboxCellRenderer } from "../ag-grid/checkbox-cell-renderer";

export abstract class GridComponentBase<T> extends LoadableComponentBase {
  // default grid options

  public columnTypes: any = {};
  public columnDefs: ColDef[] = [];
  public gridOptions: GridOptions = getGridOptions(<GridOptions>{
    columnDefs: this.columnDefs,
    columnTypes: this.columnTypes,
  });

  private _data: T[];
  set data(value: T[]) {
    if (this.currentSelection) this.resetSelection();

    this._data = value;
  }

  get data(): T[] {
    return this._data;
  }

  currentSelection: T;
  updatedItem: T;
  currentSelectionIdx: number = -1;

  /**Called when a row is selected inside the grid. */
  abstract onRowSelected($event: any): void;
  /**Called when a row is doubleClicked. */
  abstract onRowDoubleClicked(_: any): void;
  /**This is used by the input-wrapper to display error messages. Use this in combination with ValidationService. */
  abstract validateModel(prop: string): string;
  /**This should be used when saveChanges() is called. If the returned value is FALSE then save operation should be aborted. */
  abstract isModelValid(): boolean;

  protected resetSelection(): void {}

  /**Used for convenience as it will be binded in template. */
  get modelValidator() {
    return this.validateModel.bind(this);
  }

  protected addColumnDef(colDefs: ColDef) {
    this.columnDefs.push(colDefs);
  }

  protected addColumnDefs(colDefs: ColDef[]) {
    this.columnDefs.push(...colDefs);
  }

  protected addColumnDeff(colDefs: ColGroupDef[]) {
    this.columnDefs.push(...colDefs);
  }

  protected resetColumnDefs() {
    this.columnDefs.splice(0, this.columnDefs.length);
  }

  protected sortAndRefreshDateColumnDefs() {
    const orderedColDefs = this.columnDefs
      .filter(
        (cd) =>
          !cd.pinned &&
          moment(cd.headerName, MomentConstants.PRETTY_MONTH_DAY_YEAR).isValid()
      )
      .sort((c1, c2) => {
        return moment
          .utc(c1.headerName, MomentConstants.PRETTY_MONTH_DAY_YEAR)
          .diff(
            moment.utc(c2.headerName, MomentConstants.PRETTY_MONTH_DAY_YEAR)
          );
      });

    const newColDefs = this.columnDefs.filter((cd) => cd.pinned);
    newColDefs.push(...orderedColDefs);

    this.resetColumnDefs();

    this.columnDefs.push(...newColDefs);

    if (this.gridOptions.api) {
      this.gridOptions.api.setColumnDefs([]);
      this.refreshColumnDefs();
    }
  }

  protected sortAndRefreshColumnDefs() {
    if (this.gridOptions.api) {
      const orderedColDefs = this.columnDefs
        .filter((cd) => !cd.pinned)
        .sort((c1, c2) => {
          return compareStringsIgnoreCase(c1.headerName, c2.headerName);
        });

      const newColDefs = this.columnDefs.filter((cd) => cd.pinned);
      newColDefs.push(...orderedColDefs);

      this.resetColumnDefs();
      this.gridOptions.api.setColumnDefs([]);
      this.columnDefs.push(...newColDefs);
    }

    this.refreshColumnDefs();
  }

  protected refreshColumnDefs() {
    if (this.gridOptions.api)
      this.gridOptions.api.setColumnDefs(this.columnDefs);
  }

  protected addColumnType(key: string, colType: any) {
    this.columnTypes[key] = colType;
  }

  protected setCurrentSelection() {
    const currentIdx = this.data.indexOf(this.currentSelection);
    if (currentIdx < 0) {
      this.currentSelection = clone(this.data[this.currentSelectionIdx]);
    }
  }

  protected selectRowWithCurrentSelection(rowData: T) {
    if (!this.currentSelection) return;

    this.gridOptions.api.forEachNode((node) => {
      if (
        node.data == rowData ||
        (node.data.id &&
          (rowData as any).id &&
          node.data.id == (rowData as any).id)
      ) {
        node.setSelected(true);
      }
    });
  }

  protected _checkboxRenderer(d: { value: any }) {
    if (!d) return checkboxCellRenderer(false);

    return checkboxCellRenderer(d.value);
  }

  protected updateGrid(data: T[]) {
    this.gridOptions.api.applyTransaction({ update: data });
  }

  protected updateGridAfterMultipleAdds(data: T[]) {
    this.gridOptions.api.applyTransaction({ add: data });
  }

  protected updateGridAfterAdd(data: T) {
    this.gridOptions.api.applyTransaction({ add: [data] });
  }

  protected updateGridAfterEdit(data: T) {
    let rowNode = this.gridOptions.api.getSelectedNodes()[0];
    if (!!rowNode) rowNode.setData(data);
    this.gridOptions.api.applyTransaction({ update: [data] });
  }

  protected updateGridAfterDelete() {
    this.gridOptions.api.applyTransaction({
      remove: this.gridOptions.api.getSelectedRows(),
    });
  }

    protected _colorCell(color: string): any {
        return { color: color, 'font-weight': 900, 'font-size': '18px' };
    }

}
