import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router"
import { finalize, takeUntil } from "rxjs/operators";
import { MonthlySalaryService } from "src/app/services/monthly-salary.service";
import { MonthlyStatsService } from "src/app/services/monthly-stats.service";
import { LoadableComponentBase } from "src/app/shared/utils/LoadableComponentBase";
import { MonthlyStatus } from "../monthly-status/MonthlyStatus";

@Component({
  selector: "trackify-thank-you-page",
  templateUrl: "./thank-you-page.component.html",
  styleUrls: ["./thank-you-page.component.scss"],
})
export class ThankYouPageComponent extends LoadableComponentBase implements OnInit {

  errorResponse = false;
  statusNotConfirmed = true;
  monthlySalaryStatus: string = null;

  constructor(
    private monthlySalaryService: MonthlySalaryService,
    private monthlyStatsService: MonthlyStatsService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.startLoader();
    this._checkMonthlyStatus();
  }

  private _checkMonthlyStatus() {

    this.monthlyStatsService.getMonthlyStatus(this.route.snapshot.paramMap.get("token"))
    .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
    .subscribe(
      (stats : MonthlyStatus) => {
        switch (stats) {
          case MonthlyStatus.Invalid: {
            this.monthlySalaryStatus = "Invalid";
            break;
          }
          case MonthlyStatus.Active: {
            this.statusNotConfirmed = false;
            this.monthlySalaryService.confirmMonthlyStatus(this.route.snapshot.paramMap.get("token")).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
            },
            (_) => {
              this.errorResponse = true;
            }
            );

            break;
          }
          case MonthlyStatus.Confirmed: {
            this.monthlySalaryStatus = "already confirmed"
            break;
          }
          case MonthlyStatus.Expired: {
            this.monthlySalaryStatus = "expired"
            break;
          }
          default: {
            break;
          }
        }
      }
    );
  }
}
