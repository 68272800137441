import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../../shared/services/api.service";
import { RouteConstants } from "../../constants/route-constants";

import { HolidayRequestModel } from "../../models/holiday-request/HolidayRequestModel";
import { HoldayRequestResponseModel } from "../../models/holiday-request/HoldayRequestResponseModel";
import { HolidayRequestUserModel } from "src/app/models/holiday-request/HolidayRequestUserModel";

@Injectable({
  providedIn: "root",
})
export class HolidayRequestService {
  private _apiBaseUrl = RouteConstants.HOLIDAY_REQUEST;
  private _uploadDocumentUrl = RouteConstants.UPLOAD_DOCUMENT_ENDPOINT;

  constructor(private apiService: ApiService) { }

  public getAllPending(): Observable<HolidayRequestModel[]> {
    return this.apiService.get<HolidayRequestModel[]>(
      `${this._apiBaseUrl}/pending`
    );
  }

  public getRequests(): Observable<HolidayRequestModel[]> {
    return this.apiService.get<HolidayRequestModel[]>(
      `${this._apiBaseUrl}`);
  }

  public respondeToRequest(
    response: HoldayRequestResponseModel
  ): Observable<HolidayRequestModel> {
    return this.apiService.put<HolidayRequestModel>(
      `${this._apiBaseUrl}/response`,
      response
    );
  }

  public getCurrentUserHolidaysRequests(): Observable<HolidayRequestModel[]> {
    return this.apiService.get<HolidayRequestModel[]>(
      `${this._apiBaseUrl}/current-user-holiday-requests`
    );
  }

  public deleteHolidayRequest(id: number) {
    return this.apiService.delete<any>(`${this._apiBaseUrl}/${id}`);
  }

  public addHolidayRequest(
    holidayRequest: HolidayRequestUserModel
  ): Observable<HolidayRequestModel> {
    return this.apiService.post<HolidayRequestModel>(
      `${this._apiBaseUrl}/current-user-holiday-requests`,
      holidayRequest
    );
  }

  public addHolidayRequestByAdmin(userId: number, holidayRequest: HolidayRequestUserModel): Observable<HolidayRequestModel> {
    return this.apiService.post<HolidayRequestModel>(
      `${this._apiBaseUrl}/add-request-by-admin/${userId}`,
      holidayRequest
    );
  }

  public getAllForUser(userId: number): Observable<HolidayRequestModel[]> {
    return this.apiService.get<HolidayRequestModel[]>(
      `${this._apiBaseUrl}/${userId}`
    );
  }

  public updateHolidayRequest(
    holidayRequest: HolidayRequestUserModel
  ): Observable<HolidayRequestModel> {
    return this.apiService.put<HolidayRequestModel>(
      `${this._apiBaseUrl}/current-user-holiday-requests`,
      holidayRequest
    );
  }

  public getHolidayRequestDetails(id): Observable<HolidayRequestModel> {
    return this.apiService.get<HolidayRequestModel>(
      `${this._apiBaseUrl}/details/${id}`
    );
  }

  public addDocumentForHolidayRequest(holidayRequestId: number, file: FormData): Observable<HolidayRequestModel>{
    return this.apiService.put<HolidayRequestModel>(`${this._uploadDocumentUrl}/${holidayRequestId}`, file);
  }
}
