import { Component, Input, OnInit } from '@angular/core';
import { UserInfoModel } from 'src/app/models/users/UserInfoModel';
import { formatDate } from 'src/app/shared/utils/Formatters';

@Component({
  selector: 'personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {

  @Input() user: UserInfoModel;
  formatDateForTable = formatDate;

  constructor() { }

  ngOnInit() {
  }

}
