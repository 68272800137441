import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../../shared/services/api.service";

import { StatusModel } from "../../models/statuses/StatusModel";

@Injectable({
  providedIn: "root",
})
export class HolidayStatusService {
  private _apiBaseUrl = "api/holidayStatuses";

  constructor(private apiService: ApiService) { }

  public getAll(): Observable<StatusModel[]> {
    return this.apiService.get<StatusModel[]>(`${this._apiBaseUrl}`);
  }
}
