import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {ChartComponent, ChartType } from "ng-apexcharts";
import { ChartOptions } from 'src/app/shared/models/ChartOptions';
import { PieChartModel } from './../../../models/holiday-stats/PieChartModel';

@Component({
  selector: 'trackify-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  @ViewChild("chart", { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  @Input() type: ChartType = "donut";
  @Input() pieChartModel: PieChartModel = new PieChartModel();

  constructor() {
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: this.pieChartModel.series,
      chart: {
        type: this.type,
        height: 280
      },
      labels: this.pieChartModel.labels,
      title: {
        text: this.pieChartModel.title,
        align: 'center',
        style: {
          fontSize: "20px"
        }
      },
      legend: {
        position: 'right',
        offsetY: 50,
        offsetX: 30
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }
}
