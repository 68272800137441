import { finalize, takeUntil } from 'rxjs/operators';
import { HolidayRequestService } from './../../../services/holiday-request/holidayRequest.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { HoldayRequestResponseModel } from 'src/app/models/holiday-request/HoldayRequestResponseModel';

@Component({
  selector: 'app-holiday-approval',
  templateUrl: './holiday-approval.component.html',
  styleUrls: ['./holiday-approval.component.scss']
})
export class HolidayApprovalComponent extends LoadableComponentBase implements OnInit {
  public isCanceled = false;
  public isApproved = false;
  public isRejected = false;
  public isError = false;

  constructor(
    private dialogService: DialogService,
    private holidayRequestService: HolidayRequestService,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.startLoader();
    const holidayId = this.route.snapshot.paramMap.get("id");
    this.holidayRequestService.getHolidayRequestDetails(holidayId)
      .pipe(first(), takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe((result) => {
        const timePeriod = moment(result.startDate).format("DD/MM/yyyy") + "-" + moment(result.endDate).format("DD/MM/yyyy");
        this.dialogService.showApprovalConfirmation(result.userName, timePeriod).then((result) => {
          if (result.isDismissed) {
            this.isCanceled = true;
            this.stopLoader();
          } else if (result.isConfirmed) {
            const requestResponse: HoldayRequestResponseModel = {
              holidayRequestId: +holidayId,
              isAccepted: true,
            };
            //TO DO: To remove the subscribe inside another subscribe
            this.holidayRequestService.respondeToRequest(requestResponse).pipe(first(), takeUntil(this.ngUnsubscribe))
              .subscribe((_) => {
                this.isApproved = true;
              });

          } else if (result.isDenied) {
            const requestResponse: HoldayRequestResponseModel = {
              holidayRequestId: +holidayId,
              isAccepted: false,
            };

            this.holidayRequestService.respondeToRequest(requestResponse).pipe(first(), takeUntil(this.ngUnsubscribe))
              .subscribe((_) => {
                this.isRejected = true;
              });
          }
        }, _ => {
          this.isError = true;
        });
      },
        (err) => {
          this.dialogService.showErrorMessage("Oops...", err).then(() => {
            this.isError = true;
          });
        })
  }

}
