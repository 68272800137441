import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { NextReviewDateModel } from 'src/app/models/review-status/NextReviewDateModel';
import { ReviewStatusModel } from 'src/app/models/review-status/ReviewStatusModel';
import { ReviewModel } from 'src/app/models/reviews/ReviewModel';
import { ReviewPdf } from 'src/app/models/reviews/ReviewPdf';
import { ReviewerRoleEnum } from 'src/app/models/reviews/ReviewerRoleEnum';
import { ReviewService } from 'src/app/services/review.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';


@Component({
  selector: 'trackify-review-status-modal',
  templateUrl: './review-status-modal.component.html',
  styleUrls: ['./review-status-modal.component.scss']
})
export class ReviewStatusModalComponent extends LoadableComponentBase implements OnInit {
  reviewId: number;
  userFullName: string;
  reviewPDF: ReviewPdf;
  reviewStatus: ReviewStatusModel = new ReviewStatusModel;
  confirmReview: NextReviewDateModel = new NextReviewDateModel;
  minDateNextReview: string;
  maxDateNextReview: string;
  currentDate: Date = new Date();
  isCompleted: boolean = false;
  areReviewers: boolean = false;
  displayErrorMessage = false;
  isPdfButtonDisabled: boolean = false;

  constructor(private modalRef: BsModalRef,
    private reviewService: ReviewService,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.startLoader();
    this.getReviewStatusDetails(this.reviewId);
  }

  hide(): void {
    this.modalRef.hide();
  }

  getReviewStatusDetails(reviewId: number): void {
    this.reviewService.getReviewStatusDetails(reviewId)
      .pipe(takeUntil(this.ngUnsubscribe),
        tap((res) => {
          this.areReviewers = res.reviewers.length ? true : false;
        }),
        finalize( () => {
          this.stopLoader();
          this.changeDetectorRef.detectChanges();
        }))
      .subscribe((res) => {
        this.reviewStatus = res;
        this.reviewStatus.questionsAndResponses.sort((a,b) => (a.usersAnswers.some(ua => ua.choice) && !b.usersAnswers.some(ua => ua.choice) ? -1 : 1));
        
        this.confirmReview.nextReviewDate = this.reviewStatus.nextReviewDate;
        this.confirmReview.reviewId = this.reviewStatus.reviewId;
        this.minDateNextReview = this._getMinDateNextReview();
        this.maxDateNextReview = this._getMaxDateNextReview();
        
        this.isPdfButtonDisabled = res.questionsAndResponses.some(item => item.usersAnswers.length > 0);
      }); 
  }

  setIsCompletedFlag(isCompleted: boolean): void {
    if (!this.reviewStatus.questionsAndResponses.length) {
      this.displayErrorMessage = true;
      return;
  }

    this.isCompleted = isCompleted;
  }

  downloadPDF(): void {
    this.reviewService.getReviewPDF(this.reviewId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      this.reviewPDF = result;
      const base64Data = this.reviewPDF.pdfFile;
      const dataUrl = 'data:application/pdf;base64,' + base64Data;

      const link = document.createElement('a');
      link.href = dataUrl;
      link.target = '_blank'; // Open the PDF in a new tab/window
      link.download = `${this.reviewPDF.pdfTitle}`;
      link.click();
    });
  }

  getReviewerRole(role: string): string {
    return role != ReviewerRoleEnum.SELF ? ` as ${role}` : '';
  }

  private _getMinDateNextReview(): string {
    const date = new Date();
    return date.toDateString();
  }

  private _getMaxDateNextReview(): string {
    this.currentDate.setFullYear(this.currentDate.getFullYear() + 2);
    return this.currentDate.toDateString();
  }

  confirm(): void {
    this.reviewService.completeReview(this.confirmReview)
      .pipe(finalize(() => {
        this.hide();
        this.isCompleted = false;
      }))
      .subscribe((nextReview : ReviewModel) => {
        this.modalRef.content = { 
          isCompleted: this.isCompleted, 
          reviewId : this.confirmReview.reviewId, 
          nextReview: nextReview }
        this.dialogService.showSuccessMessage(
          "Success!",
          "Review Completed!"
        );
      },
        (error) => {
          this.dialogService.showErrorMessage(
            "Error",
            error
          );
          this.modalRef.content = { isCompleted: false };
        })
  }
}
