import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ColorsConstants } from 'src/app/constants/colors-constants';
import { LogType } from 'src/app/models/benefitsPointsLogModel/LogType';
import { UserBenefitsPointsLogModel } from 'src/app/models/benefitsPointsLogModel/UserBenefitsPointsLog';
import { MonthSimpleModel } from 'src/app/models/monthhDetails/MonthSimpleModel';
import { MonthlyStats } from 'src/app/constants/monthly-stats'
import { BenefitsPointsLogService } from 'src/app/services/benefits-points-log.service';
import { MonthDetailsService } from 'src/app/services/month-details.service';
import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase';
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils';
import { formatDate } from 'src/app/shared/utils/Formatters';
import { clone } from 'src/app/shared/utils/Utils';
import { ChangeStringFormat } from "src/app/shared/utils/Formatters";

@Component({
  selector: 'trackify-admin-benefits-monthly-logs',
  templateUrl: './admin-benefits-monthly-logs.component.html',
  styleUrls: ['./admin-benefits-monthly-logs.component.scss']
})
export class AdminBenefitsMonthlyLogsComponent extends GridComponentBase<UserBenefitsPointsLogModel> implements OnInit {

  @Input() isInTab: boolean = false;
  formatDateForTable = formatDate;

  months: MonthSimpleModel[] = [];
  years: number[] = [];

  monthToDisplay: number;
  yearToDisplay: number;

  all_months: number = -1;
  all_years: string = MonthlyStats.ALL_DATA;
  
  constructor(
    private benefitsPointsLogService: BenefitsPointsLogService,
    private monthDetailsService: MonthDetailsService
  ) {
    super();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        valueGetter: (x) => (x.data.userName ? x.data.userName : "N/A"),
        headerName: "User Name",
        width: 250,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x: any) => (moment(x.data.date)).format('DD-MMM-YYYY'),
        headerName: "Date",
        sort: "desc",
        width: 180,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Benefit Name',
        valueGetter: (x) => (x.data.benefitName ? x.data.benefitName : "N/A"),
        minWidth: 170,
        suppressSizeToFit: false,
      },
      {
        headerName: 'Benefit Type',
        valueGetter: (x) => (x.data.benefitType ? ChangeStringFormat(x.data.benefitType.name) : "N/A"),
        width: 170,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Log Type',
        valueGetter: function(x) {
          switch(x.data.logType){
            case (LogType.AddBenefit):
              return "Add Points";
            case (LogType.ConsumeBenefit):
              return "Consume Points";
            case (LogType.RevertPoints):
              return "Revert Points";
            default:
              return "Invalid";
          }
        },
        width: 170,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Initial Points',
        valueGetter: (x) => (x.data.initPoints ? x.data.initPoints : "0"),
        width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Action',
        valueGetter: function(x) {
          switch(x.data.logType) {
            case(LogType.AddBenefit):
              return `+${x.data.actionCost}`;
            case(LogType.RevertPoints):
              return `+${x.data.actionCost}`;
            default:
              return `-${x.data.actionCost}`;
          }
        },
        width: 100,
        suppressSizeToFit: true,
        cellStyle: (params) => {
          switch(params.data.logType) {
            case (LogType.AddBenefit):
              return this._colorCell(ColorsConstants.GREEN);
            case (LogType.ConsumeBenefit):
              return this._colorCell(ColorsConstants.RED);
            case (LogType.RevertPoints):
              return this._colorCell(ColorsConstants.BLUE);
            default:
              return this._colorCell(ColorsConstants.BLACK);
          }
        },
      },
      {
        headerName: 'Remaining Points',
        valueGetter: (x) => (x.data.remainingPoints ? x.data.remainingPoints : "0"),
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Category',
        valueGetter: (x) => (x.data.category ? x.data.category : "N/A"),
        width: 170,
        suppressSizeToFit: true,
      }
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No benefit logs entries.</span>';
  }

  ngOnInit(): void {
    this.startLoader();
    this.setCurrentMonthOnInit();
    this.setDataWraper();
  }

  getAllMonthDetails(): Observable<MonthSimpleModel[]> {
    return this.monthDetailsService.getAllSimpleMonths();
  }

  getFilteredBenefitPointLogs(): Observable<UserBenefitsPointsLogModel[]> {
    return this.benefitsPointsLogService.getFilteredBenefitPointLogs(this.monthToDisplay, this.yearToDisplay);
  }

  setCurrentMonthOnInit(): void {
    this.monthToDisplay = moment().month() + 1;
    this.yearToDisplay = moment().year();
    this.onMonthSelection(this.monthToDisplay);
    this.onYearSelection(this.yearToDisplay.toString());
    this.stopLoader();
  }

  setMonthsAndYears(monthsDetails: MonthSimpleModel[]): void {
    //populate the properties months and years
    monthsDetails.forEach((details) => {
      if(!this.months.some((month) => month.name == details.name)){
        this.months.push(details);
      }
      if(!this.years.some((year) => year == details.year)){
        this.years.push(details.year);
      }
    });
    //sort the this.months by the month number
    this.months.sort(function(month1, month2) {
      return month1.monthNumber - month2.monthNumber;
    });
    //sort this.years descending
    this.years.sort(function (year1, year2){
      return year2 - year1;
    });
  }

  private _setData(): Observable<any[]> {
    let logs = this.getFilteredBenefitPointLogs();
    const monthsDetails = this.getAllMonthDetails();
    return forkJoin([monthsDetails, logs]);
  }

  setDataWraper(): void {
    this._setData()
    .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
    .subscribe(([monthsDetails, logs]) => {
      this.setMonthsAndYears(monthsDetails);
      this.data = logs;
    })
  }

  getFilteredData(): void {
    this.getFilteredBenefitPointLogs()
    .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
    .subscribe((logs) => {
      this.data = logs;
    });
  }

  onYearSelection(year: string): void {
    this.yearToDisplay = year == this.all_years ? -1 : +year;
    this.startLoader();
    this.getFilteredData();
  }

  onMonthSelection(month: number): void {
    this.monthToDisplay = month
    this.startLoader();
    this.getFilteredData();
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;
    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(u => u.username == this.currentSelection.username));
  }

  onRowDoubleClicked(_: any): void {

  }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

}
