import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BenefitCardConstants } from 'src/app/constants/benefit-card-constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BenefitCardModel } from 'src/app/models/user-benefits/BenefitCardModel';
import { RecurrentModalComponent } from './modals/recurrent-modal/recurrent-modal.component';
import { BenefitTypeEnum } from 'src/app/models/admin-benefits/BenefitTypeDto';
import { BenefitRequestWithEnrollment } from 'src/app/models/benefit-request/BenefitRequestWithEnrollment';
import { BenefitRequestSimple } from 'src/app/models/admin-benefits/BenefitRequestSimple';
import { EventModalComponent } from './modals/event-modal/event-modal.component';
import { EventDetailsModalComponent } from './modals/event-details-modal/event-details-modal.component';
import { ParticipantEventStatus } from 'src/app/models/user-benefits/ParticipantEventStatus';
import { BenefitCardCornerFlags } from 'src/app/models/user-benefits/BenefitCardCornerFlags';
import { OneTimeModalComponent } from './modals/one-time-modal/one-time-modal.component';
import { BenefitOneTimeCardModel } from 'src/app/models/user-benefits/BenefitOneTimeCardModel';
import { EventBenefitCardDetails } from 'src/app/models/user-benefits/EventBenefitCardDetails';

@Component({
  selector: 'app-benefit-card',
  templateUrl: './benefit-card.component.html',
  styleUrls: ['./benefit-card.component.scss']
})

export class BenefitCardComponent extends LoadableComponentBase implements OnInit {

  enrollment: string;
  enrollmentStatus = BenefitCardConstants;
  classType: string;
  recurrentModalRef: BsModalRef;
  eventModalRef: BsModalRef;
  BenefitTypeEnum: BenefitTypeEnum;
  benefitRequestWithEnrollment: BenefitRequestWithEnrollment;
  isEvent: boolean = false;
  oneTimeModalRef: BsModalRef;

  @Input() benefitCardModel: BenefitCardModel | BenefitOneTimeCardModel;
  @Output() eventAdded = new EventEmitter<EventBenefitCardDetails>();
  @Output() deletedEvent = new EventEmitter<number>();
  @Output() eventModified = new EventEmitter<BenefitCardModel>();
  @Output() oneTimeBenefitModified = new EventEmitter<BenefitOneTimeCardModel>();
  @Output() recurrentBenefitModified = new EventEmitter<BenefitCardModel>();

  constructor(private dialogService: DialogService,
    private bsModalService: BsModalService) {
    super();
  }

  ngOnInit(): void {
    this.benefitCardModel.logo = this.benefitCardModel.logo === undefined || this.benefitCardModel.logo === null
      ? "../../../../assets/img/no-image.png"
      : this.benefitCardModel.logo;

    this.isEvent = this.benefitCardModel.benefitType == BenefitTypeEnum.EVENT;

    if (this.benefitCardModel.benefitType !== BenefitTypeEnum.ONETIME) {
      this.benefitCardModel = this.benefitCardModel as BenefitCardModel;
      if (!this.benefitCardModel.isCompleted) {
        this.classType = this._getBenefitCardType(this.benefitCardModel.enrollmentStatus, this.benefitCardModel.benefitRequest);
      } else {
        this._completedOngoingEventFlag();
      }
      return;
    }
    this._oneTimeBenefitCardFlags();
  }

  openModal(): void {
    const allowedActiveEventStatuses: ParticipantEventStatus[] = Object.values(ParticipantEventStatus);

    switch (this.benefitCardModel.benefitType) {
      case BenefitTypeEnum.RECURRENT:
        this._openRecurrentModal();
        break;
      case BenefitTypeEnum.EVENT:
        this.benefitCardModel = this.benefitCardModel as BenefitCardModel;
        if (allowedActiveEventStatuses.includes(this.benefitCardModel.enrollmentStatus as ParticipantEventStatus)) {
          this._openEventRequestModal();
          break;
        }
        this._openEventModal();
        break;
      case BenefitTypeEnum.ONETIME:
        this._openOneTimeModal();
        break;
      default:
        this.dialogService.showWarningMessage(
          "Warning!",
          "No benefit type found!"
        );
        break;
    }
  }

  private _openRecurrentModal(): void {
    const initialState = {
      benefitCard: this.benefitCardModel as BenefitCardModel
    };
    this._showRecurrentModal(initialState, this._onCloseRecurrentModal.bind(this));
  }

  private _showRecurrentModal(initialState: any, callback: () => void): void {
    this.recurrentModalRef = this.bsModalService.show(RecurrentModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg align-center-modal',
    });

    this.recurrentModalRef.onHidden
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (!!callback) {
          callback();
        }
      });
  }

  private _onCloseRecurrentModal(): void {
    const property = this.recurrentModalRef.content.benefitWithEnrollment;
    if (!property) {
      return;
    }
    this.benefitRequestWithEnrollment = property;
    this.benefitCardModel = this.benefitCardModel as BenefitCardModel;
    this.benefitCardModel.benefitRequest = this.benefitRequestWithEnrollment.benefitRequest;
    this.benefitCardModel.enrollmentStatus = this.benefitCardModel.enrollmentStatus;
    this.classType = this._getBenefitCardType(this.benefitRequestWithEnrollment.enrollmentStatus, this.benefitRequestWithEnrollment.benefitRequest);
    this.recurrentBenefitModified.emit(this.benefitCardModel);
  }

  private _openEventModal(): void {
    const initialState = {
      benefitId: this.benefitCardModel.id
    };

    this._showAddEventRequestModal(initialState, this._onCloseAddEventRequestModal.bind(this))
  }

  private _openEventRequestModal(): void {
    this.benefitCardModel = this.benefitCardModel as BenefitCardModel;
    const initialState = {
      benefitRequestId: this.benefitCardModel.benefitRequest.id
    };
    this._showEventRequestModal(initialState, this._onCloseEventRequestModal.bind(this))
  }

  private _showEventRequestModal(initialState: any, callback: () => void): void {
    this.eventModalRef = this.bsModalService.show(EventDetailsModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg align-center-modal'
    });

    this.eventModalRef.onHidden
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (!!callback) {
          callback();
        }
      });
  }

  private _showAddEventRequestModal(initialState: any, callback: () => void): void {
    this.eventModalRef = this.bsModalService.show(EventModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg align-center-modal',
    });

    this.eventModalRef.onHidden
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (!!callback) {
          callback();
        }
      });
  }

  private _onCloseEventRequestModal(): void {
    const property = this.eventModalRef.content.enrollmentStatus;
    const isCompleted = this.eventModalRef.content.isCompleted;
    const deletedEvent = this.eventModalRef.content.deletedEvent;

    if (deletedEvent) {
      this.deletedEvent.emit(deletedEvent);
      return;
    }

    this.benefitCardModel = this.benefitCardModel as BenefitCardModel;

    if (property) {
      this.classType = this._getBenefitCardType(property);
      this.benefitCardModel.enrollmentStatus = property;
    }

    if (isCompleted) {
      this.benefitCardModel.isCompleted = true;
      this._completedOngoingEventFlag();
    }

    this.eventModified.emit(this.benefitCardModel)
  }

  private _onCloseAddEventRequestModal(): void {
    const property = this.eventModalRef.content.eventAdded;
    const name = this.eventModalRef.content.createdby;
    if (!property) {
      return;
    }

    property.name = `${property.name} by ${name}`
    this.eventAdded.emit(property);
    this.classType = this._getBenefitCardType(BenefitCardCornerFlags.EMPTY, property.benefitRequest);
  }

  private _openOneTimeModal(): void {
    const initialState = {
      benefitCard: this.benefitCardModel
    };
    this._showOneTimeModal(initialState, this._onCloseOneTimeModal.bind(this));
  }

  private _showOneTimeModal(initialState: any, callback: () => void): void {
    this.oneTimeModalRef = this.bsModalService.show(OneTimeModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg align-center-modal',
    });

    this.oneTimeModalRef.onHidden
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (!!callback) {
          callback();
        }
      });
  }

  private _onCloseOneTimeModal(): void {
    const property = this.oneTimeModalRef.content.hasPendingRequest;
    if (!property) {
      return;
    }
    this.benefitCardModel = this.benefitCardModel as BenefitOneTimeCardModel;
    this.benefitCardModel.hasPendingRequest = property;
    this.oneTimeBenefitModified.emit(this.benefitCardModel);
    this._oneTimeBenefitCardFlags();
  }

  private _getBenefitCardType(enrollmentStatus: BenefitCardConstants, benefitRequest?: BenefitRequestSimple): string {
    let classType = 'card benefit-card';
    switch (enrollmentStatus) {
      case BenefitCardConstants.ONGOING_STATUS:
        if (benefitRequest) {
          classType = `${classType} card-for-approval`;
          this.enrollment = BenefitCardCornerFlags.FOR_APPROVAL;
        }
        else {
          classType = `${classType} card-ongoing`;
          this.enrollment = BenefitCardCornerFlags.ONGOING;
        }
        break;
      case BenefitCardConstants.ENROLLMENT_PENDING_STATUS:
        classType = `${classType} card-enrolling`;
        this.enrollment = BenefitCardCornerFlags.ENRLOLLING;
        break;
      case BenefitCardConstants.CANCELLATION_PENDING_STATUS:
        classType = `${classType} card-cancelling`;
        this.enrollment = BenefitCardCornerFlags.CANCELLING;
        break;
      case BenefitCardConstants.INVALID:
        if (benefitRequest && !this.isEvent) {
          classType = `${classType} card-for-approval`;
          this.enrollment = BenefitCardCornerFlags.FOR_APPROVAL;
        }
        else {
          classType = `${classType} card-unknown`;
          this.enrollment = BenefitCardCornerFlags.EMPTY;
        }
        break;
      case ParticipantEventStatus.Invited:
        classType = `${classType} card-for-approval`;
        this.enrollment = BenefitCardCornerFlags.INVITATION;
        break;
      case ParticipantEventStatus.Joined:
        classType = `${classType} card-enrolling`;
        this.enrollment = BenefitCardCornerFlags.JOINED;
        break;
      default:
        classType = `${classType} card-unknown`;
        this.enrollment = BenefitCardCornerFlags.EMPTY;
        break;
    }
    return classType;
  }

  private _oneTimeBenefitCardFlags(): void {
    this.benefitCardModel = this.benefitCardModel as BenefitOneTimeCardModel;
    if (this.benefitCardModel.hasPendingRequest) {
      this.classType = `card benefit-card card-for-approval`;
      this.enrollment = "For Approval";
      return;
    }
    this.classType = 'card benefit-card card-unknown';
    this.enrollment = "";
  }

  private _completedOngoingEventFlag(): void {
    this.benefitCardModel = this.benefitCardModel as BenefitCardModel;
    this.classType = `card benefit-card card-cancelling`;
    this.enrollment = BenefitCardCornerFlags.COMPLETED;
  }
}
