import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { LogType } from 'src/app/models/benefitsPointsLogModel/LogType';

@Component({
  selector: 'trackify-revert-benefit-point-log-renderer',
  templateUrl: './revert-benefit-point-log-renderer.component.html',
  styleUrls: ['./revert-benefit-point-log-renderer.component.scss']
})
export class RevertBenefitPointLogRendererComponent implements ICellRendererAngularComp {
  param: any;

  constructor() { }

  agInit(param: any): void {
   if (!!param.shouldCheckBenefitStatus) {
      var year = parseInt(param.data.date.substring(0, 4));
      var month = parseInt(param.data.date.substring(5, 7));
      var currentMonth = new Date().getMonth() + 1;
      var currentYear = new Date().getFullYear();

      param.isVisible = false;
      // the button of reverting points is not visible if the benefit log's date is before last month
      // isVisible flag set on true means that the button is NOT visible
      if (year < currentYear || (month < currentMonth-1 && year == currentYear)) {
        param.isVisible = true;
      }
      // the button of reverting points is not visible if the benefit type is recurrent or if the log type is not consume benefit
      if (param.data.logType != LogType.ConsumeBenefit || param.data.benefitType.name == "recurrent") {
        param.isVisible = true;
      }
    }

    this.param = param;
  }

  refresh(_: any): boolean {
    return false;
  }

  onClick(): void {
    this.param.onClick(this.param.node);
  }
}
