import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { forkJoin } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

import { DialogService } from "src/app/shared/services/dialog.service";
import { formatLocalTime } from "src/app/shared/utils/Formatters";

import { HolidayRequestService } from "src/app/services/holiday-request/holidayRequest.service";
import { HolidayStatusService } from "src/app/services/holiday-request/holidayStatus.service";

import { HolidayRequestModel } from "src/app/models/holiday-request/HolidayRequestModel";
import { HoldayRequestResponseModel } from "src/app/models/holiday-request/HoldayRequestResponseModel";
import { StatusModel } from "src/app/models/statuses/StatusModel";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { clone, dateComparator } from "src/app/shared/utils/Utils";
import { ModalDirective } from "ngx-bootstrap/modal";
import { HolidayRequestUserModel } from "src/app/models/holiday-request/HolidayRequestUserModel";
import { getUtcDateFromString } from "src/app/shared/utils/MomentUtils";
import { HolidayRequestTypeModel } from "src/app/models/holiday-request/HolidayRequestTypeModel";
import { HolidayRequestTypeService } from "src/app/services/holiday-request/holiday-request-type.service";
import { StatusConstants } from "src/app/constants/statuses-constants";
import { DateTimeModel } from "src/app/models/DateTimeModel";
import { HolidayType } from "src/app/models/holiday-type/HolidayType";

@Component({
  selector: "trackify-holiday-request",
  templateUrl: "./holiday-request.component.html",
  styleUrls: ["./holiday-request.component.scss"],
})
export class HolidayRequestComponent
  extends GridComponentBase<HolidayRequestModel>
  implements OnInit {
  holidayTypes: HolidayRequestTypeModel[];
  specialHolidayTypes: HolidayRequestTypeModel[] = [];
  errorMessage: string;
  @Input() userId: number;
  @Input() isForAll: boolean;
  @Input() isAdminOrHr: boolean = false;
  @Input() isButtonHidden: boolean = false;

  minDate: string;
  maxDate: string;
  newHolidayRequest: HolidayRequestUserModel = new HolidayRequestUserModel();
  pending: string = StatusConstants.PENDING_STATUS;
  holidayStatuses: StatusModel[] = [];

  uploadedDocument: any;
  isSpecialHoliday: boolean = false;
  isMedicalOrSpecialHoliday: boolean = false;

  formatDate = formatLocalTime;
  @ViewChild("staticModal", { static: true }) modal: ModalDirective;

  constructor(
    private dialogService: DialogService,
    private holidayRequestService: HolidayRequestService,
    private holidayStatusService: HolidayStatusService,
    private holidayRequestTypeService: HolidayRequestTypeService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        field: "userName",
        headerName: "User",
        width: 150,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => this.formatDate(x.data.startDate),
        headerName: "Start Date",
        maxWidth: 150,
        sort: "asc",
        comparator: (_, __, nodeA, nodeB) =>
          dateComparator(nodeA.data.startDate, nodeB.data.startDate),
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => this.formatDate(x.data.endDate),
        headerName: "End Date",
        maxWidth: 150,
        suppressSizeToFit: false,
      },
      {
        field: "holidayRequestTypeName",
        headerName: "Type",
        width: 120,
        maxWidth: 160,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => (x.data.statusName == StatusConstants.ACCEPTED_STATUS || x.data.statusName == StatusConstants.DECLINED_STATUS ? 
          `${x.data.statusName} by ${x.data.modifiedStatusBy}` : x.data.statusName),
        headerName: "Status",
        width: 200,
        suppressSizeToFit: false,
      },
      {
        headerName: "Accept",
        pinned: "right",
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-success",
          buttonText: "",
          iconClass: "fas fa-check",
          shouldCheckStatus: true,
          getStatusToCheck: () => StatusConstants.PENDING_STATUS,
          onClick: this._accept.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
      {
        headerName: "Decline",
        pinned: "right",
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-danger",
          buttonText: "",
          iconClass: "fas fa-times",
          shouldCheckStatus: true,
          getStatusToCheck: () => StatusConstants.PENDING_STATUS,
          onClick: this._decline.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
      {
        headerName: "View document",
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-success",
          buttonText: "",
          iconClass: "fa fa-eye",
          shouldCheckStatus: true,
          shouldCheckDocument: true,
          getStatusToCheck: () => [StatusConstants.ACCEPTED_STATUS],
          onClick: this._viewDocument.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No holiday requests entries.</span>';
  }

  ngOnInit() {
    this.startLoader();
    this._loadHolidayRequestData();
    this._setMaxDate();
  }

  addRequest(): void {
    if (!this.isModelValid()) {
      return;
    }

    const start = new Date(this.newHolidayRequest.startDate);
    const end = new Date(this.newHolidayRequest.endDate);
    this.newHolidayRequest.startDateComponents = new DateTimeModel(
      start.getDate(),
      start.getMonth() + 1,
      start.getFullYear()
    );
    this.newHolidayRequest.endDateComponents = new DateTimeModel(
      end.getDate(),
      end.getMonth() + 1,
      end.getFullYear()
    );

    this.gridOptions.api.showLoadingOverlay();
    this.holidayRequestService
      .addHolidayRequestByAdmin(this.userId, this.newHolidayRequest)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.gridOptions.api.hideOverlay()))
      .subscribe(
        (newRequest) => {
          if (this.uploadedDocument != undefined) {
            this._processHolidayWithDoc(newRequest);
          } else {
            this.data.push(newRequest);
            this.updateGridAfterAdd(newRequest);
            this.dialogService.showSimpleDialog(
              "Succes!",
              "Request added successfully",
              "success"
            );
            this.closeModal();
          }
        },
        (error) => {
          this.dialogService.showSimpleDialog("Error!", error, "error");
        }
      );
  }

  _accept(e): void {
    this.updateHolidayRequestStatus(e.data.id, true);
  }

  _decline(e): void {
    this.updateHolidayRequestStatus(e.data.id, false);
  }

  public updateHolidayRequestStatus(requestId: number, isAccepted: boolean): void {
    const dialogMessage = isAccepted ? "Accept request" : "Decline request";
    this.dialogService.confirmationDialog(dialogMessage).then((result) => {
      if (result.value) {
        const requestResponse: HoldayRequestResponseModel = {
          holidayRequestId: requestId,
          isAccepted: isAccepted,
        };
        this.gridOptions.api.showLoadingOverlay();
        this.holidayRequestService
          .respondeToRequest(requestResponse)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            (result) => {
              this.data[this.currentSelectionIdx] = result;
              this.data[this.currentSelectionIdx].isActionsAvailable = false;
              this.updateGridAfterEdit(this.data[this.currentSelectionIdx]);
              this.gridOptions.api.hideOverlay();
              this.gridOptions.api.redrawRows({
                rowNodes: [
                  this.gridOptions.api.getDisplayedRowAtIndex(
                    this.currentSelectionIdx
                  ),
                ],
              });
              this._loadHolidayRequestData();
            },
            (err) => {
              this.dialogService.showSimpleDialog("Error", err, "error");
            }
          );
      }
    });
  }

  updateHolidayRequestDocument(files: any): void {
    this.uploadedDocument = files;
  }

  onAddHoliday(): void {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const currentYear = currentDate.getFullYear();

    const isAfter6thDay = currentDay > 6;

    let minMonth = currentMonth;
    let minYear = currentYear;

    if (!isAfter6thDay && currentMonth === 0) {
      minMonth = 11;
      minYear = currentYear - 1;
    } else if (!isAfter6thDay) {
      minMonth = currentMonth - 1;
    }

    const minDate = new Date(minYear, minMonth, 1);

    this.minDate = minDate.toISOString();
    this.maxDate = getUtcDateFromString().add(1, "years").toISOString();
    this.newHolidayRequest = new HolidayRequestUserModel();
    this.uploadedDocument = undefined;
    this.modal.show();
  }

  closeModal(): void {
    this.isSpecialHoliday = false;
    this.isMedicalOrSpecialHoliday = false;
    this.errorMessage = null;
    this.modal.hide();
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;
    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(
      this.data.find((u) => u.id == this.currentSelection.id)
    );
  }
  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    this.errorMessage = "";

    if (!this.newHolidayRequest.startDate || !this.newHolidayRequest.endDate) {
      this.errorMessage = "Missing date!";
      return false;
    }

    const start = this.newHolidayRequest.startDate.slice(0, 10);
    const end = this.newHolidayRequest.endDate.slice(0, 10);
    
    if (end < start) {
      this.errorMessage = "Check dates again!";
      return false;
    }
    
    if (this.isSpecialHoliday && !this.newHolidayRequest.specialHolidayRequestTypeId) {
      this.errorMessage = "Select holiday type!";
      return false;
    }

    return true;
  }

  updateHolidayType(selection): void {
    const selectedType = selection.options[selection.selectedIndex].text;
    this.isMedicalOrSpecialHoliday =
      selectedType != HolidayType.Paid && selectedType != HolidayType.NotPaid;

    this.isSpecialHoliday = this._checkForSpecialHoliday(selectedType);

    this.newHolidayRequest.specialHolidayRequestTypeId = null;
  }

  private _loadHolidayRequestData(): void {
    if (this.isForAll) {
      forkJoin([
        this.holidayRequestService.getAllPending(),
        this.holidayStatusService.getAll(),
        this.holidayRequestTypeService.getAll(),
      ])
        .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
        .subscribe(
          ([requests, statuses, types]) => {
            this.data = requests;
            this.holidayStatuses = statuses;
            this.holidayTypes = types;
            this.holidayTypes.forEach((holiday) => {
              if (
                holiday.name != HolidayType.Paid &&
                holiday.name != HolidayType.NotPaid &&
                holiday.name != HolidayType.Medical
              ) {
                let special: HolidayRequestTypeModel = {
                  id: holiday.id,
                  name: holiday.name,
                  isBillable: holiday.isBillable
                };
                this.specialHolidayTypes.push(special);
                this.holidayTypes = this.holidayTypes.filter(
                  (item) => item.id != holiday.id
                );
              }
            })
          });
    } else {
      forkJoin([
        this.holidayRequestService.getAllForUser(this.userId),
        this.holidayStatusService.getAll(),
        this.holidayRequestTypeService.getAll(),
      ])
        .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
        .subscribe(
          ([requests, statuses, types]) => {
            this.data = requests;
            this.holidayStatuses = statuses;
            this.holidayTypes = types;

            this.holidayTypes.forEach((holiday) => {
              if (
                holiday.name != HolidayType.Paid &&
                holiday.name != HolidayType.NotPaid &&
                holiday.name != HolidayType.Medical
              ) {
                let special: HolidayRequestTypeModel = {
                  id: holiday.id,
                  name: holiday.name,
                  isBillable: holiday.isBillable
                };
                this.specialHolidayTypes.push(special);
                this.holidayTypes = this.holidayTypes.filter(
                  (item) => item.id != holiday.id
                );
              }
            })
          });
    }
  }

  private _processHolidayWithDoc(newRequest: HolidayRequestModel): void {
    let fileToUpload = <File>this.uploadedDocument[0];
    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    this.holidayRequestService
      .addDocumentForHolidayRequest(newRequest.id, formData)
      .subscribe((holidayRequestWithFile) => {
        this.data.push(holidayRequestWithFile);
        this.updateGridAfterAdd(holidayRequestWithFile);
        this.dialogService.showSimpleDialog(
          "Succes!",
          "Request added successfully",
          "success"
        );
        this.gridOptions.api.hideOverlay();
        this.closeModal();
      });
  }

  private _checkForSpecialHoliday(value: string): boolean {
    return (
      value != HolidayType.Medical &&
      value != HolidayType.Paid &&
      value != HolidayType.NotPaid
    );
  }

  private _viewDocument(e): void {
    if (e.data.filePath) {
      this.openDoc(e.data.filePath);
    } else {
      this.dialogService.showErrorMessage("Oops...", "No files uploaded!");
    }
  }

  private openDoc(documentUrl: string): void {
    window.open(documentUrl);
  }

  private _setMaxDate(): void {
    this.maxDate = getUtcDateFromString().add(1, "years").toISOString();
  }

}
