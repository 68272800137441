export class ClientDetailsModel {
    id: number;

    name: string;
    microsoftName: string;
    smartBillName: string;
    smartBillCif: string;
    
    isBimonthlyBillable: boolean;

    currency: number;
    tva: number;

    generateAzureProforma: boolean;
    generateOfficeProforma: boolean;
    generateTimesheetProforma: boolean;
}