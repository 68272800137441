import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";

import { ApiService } from "../shared/services/api.service";

import { UsefulLinkModel } from "../models/usefulLinks/UsefulLinkModel";

@Injectable({
  providedIn: "root",
})
export class UsefulLinkService {
  private _apiBaseUrl = ApiConstants.USEFULL_LINK_API_ENDPOINT;

  constructor(private apiService: ApiService) { }

  public getUserUsefulLinks(): Observable<UsefulLinkModel[]> {
    return this.apiService.get<UsefulLinkModel[]>(`${this._apiBaseUrl}`);
  }

  public addUsefulLinks(model: UsefulLinkModel): Observable<UsefulLinkModel> {
    return this.apiService.post<UsefulLinkModel>(`${this._apiBaseUrl}`, model);
  }

  public editUsefulLinks(model: UsefulLinkModel): Observable<UsefulLinkModel> {
    return this.apiService.put<UsefulLinkModel>(`${this._apiBaseUrl}`, model);
  }

  public deleteUsefulLinks(id: number): Observable<UsefulLinkModel> {
    return this.apiService.delete<UsefulLinkModel>(`${this._apiBaseUrl}/${id}`);
  }
}
