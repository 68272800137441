import { Component, OnInit } from '@angular/core';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';
import { FormBuilder, FormGroup, FormArray, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { ReviewFormDataToSubmit } from 'src/app/models/reviews/ReviewFormDataToSubmit';
import { QuestionResponse } from 'src/app/models/reviews/QuestionResponse';
import { ReviewQuestion } from 'src/app/models/reviews/ReviewQuestion';
import { ReviewFormService } from 'src/app/services/review-form-service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, finalize } from "rxjs/operators";
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ReviewFormModel } from 'src/app/models/reviews/ReviewFormModel';
import { ReviewResponseChoice } from 'src/app/models/reviews/ReviewResponseChoice';
import { PatternConstants } from 'src/app/constants/pattern-constants';
import { ReviewQuestionsByCategory } from 'src/app/models/reviews/ReviewQuestionsByCategory';


@Component({
  selector: 'trackify-review-form-page',
  templateUrl: './review-form-page.component.html',
  styleUrls: ['./review-form-page.component.scss']
})
export class ReviewFormPageComponent extends LoadableComponentBase implements OnInit {
  userFullName: string;
  isSelfReview: boolean;
  reviewForm: FormGroup;
  message: string;
  choices: ReviewResponseChoice[];
  formAnswer: ReviewFormDataToSubmit = new ReviewFormDataToSubmit();

  constructor(
    private formBuilder: FormBuilder,
    private reviewFormService: ReviewFormService,
    private route: ActivatedRoute,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.startLoader();

    this.reviewFormService
      .getReviewFormData(this.route.snapshot.paramMap.get("token"))
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader())
      )
      .subscribe(
        (reviewForm: ReviewFormModel) => {
          this.userFullName = reviewForm.userToBeReviewedFullName;
          this.isSelfReview = reviewForm.isSelfReview;
          if(this._checkFormPreconditions(reviewForm)){
            this.choices = reviewForm.reviewResponseChoices;
            this._createFormGroup(reviewForm.reviewQuestionsByCategory);
          }
        },
        (errorMessage: string) => {
          this.message = errorMessage;
        }
      );
  }

  getQuestionsForm(index: number): FormArray {
    return this.questionsByCategory.at(index)['controls']["questionsForm"] as FormArray;
  }

  get questionsByCategory(): FormArray {
    return this.reviewForm.controls["questionsByCategory"] as FormArray;
  }

  public submitForm(): void {
    if (this.reviewForm.invalid) {
      this._handleInvalidForm();
      return;
    }

    this.startLoader();
    this._createFormAnswer();

    this.reviewFormService.saveReviewFormResponses(this.formAnswer)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader()))
      .subscribe(() => {
        this.message = this.isSelfReview ? `Your responses have been submitted. Thank you for your feedback!` : `Your responses have been submitted. Thank you for reviewing your colleague!`;
      },
        (errorMessage: string) => {
          this.dialogService.showSimpleDialog("Error", errorMessage, "error");
          this.message = errorMessage;
        });
  }

  private _createFormGroup(questionsByCategory: ReviewQuestionsByCategory[]): void {
    this.reviewForm = this.formBuilder.group({
      questionsByCategory : this.formBuilder.array([]),
      isAnonymous: false
    });

    questionsByCategory.sort((a, b) => a.reviewQuestions.some(rq => rq.hasResponseChoices) && !b.reviewQuestions.some(rq => rq.hasResponseChoices) ? -1 : 1)

    questionsByCategory.forEach((questionsByCategory: ReviewQuestionsByCategory, index:number) => {
      
      const categoryGroup = this.formBuilder.group({
        category: questionsByCategory.reviewQuestionCategory,
        questionsForm: this.formBuilder.array([])
      })

      this.questionsByCategory.push(categoryGroup);

      questionsByCategory.reviewQuestions.forEach((question: ReviewQuestion) => {
        const group = this.formBuilder.group({
          question: question,
          response: '',
          choice: null
        }, {
          validators: [this._questionGroupValidator()]
        });

        this.getQuestionsForm(index).push(group);
      });
    });
  }

  // Validator works like this: 
  //    if a question is multiple choice type then it is mandatory to have a choice assigned 
  //                     but is not mandatory to have a response as a note
  //    if a question is free response type then it is mandatory to have a response text
  // 
  // Returns null if no errors were found and true otherwise
  private _questionGroupValidator(): ValidatorFn {
    return (questionForm: FormGroup): ValidationErrors | null => {
      const question = questionForm.get("question").value;
      let isResponseValid = true;
      let isChoiceValid = true;

      if (question.hasResponseChoices) {
        const choice = questionForm.get("choice").value;
        if (choice == null) {
          isChoiceValid = false;
        }
      }
      else {
        const response = questionForm.get("response").value;
        const regex = new RegExp(PatternConstants.NON_EMPTY_TEXT_PATTERN);
        if (!regex.test(response)) {
          isResponseValid = false;
        }
      }
      return isChoiceValid && isResponseValid ? null : { isChoiceInvalid: !isChoiceValid, isResponseInvalid: !isResponseValid };
    }
  }

  private _handleInvalidForm(): void {
    this.questionsByCategory.controls.forEach((category: AbstractControl) => {
      category.get('questionsForm')['controls'].forEach((control: AbstractControl) => {
        if (control.errors != null) {
          // if the form is invalid then all the response controls from questionForm FromArray are maked as touched
          // so the text areas will be displayed red
          if (control.errors.isResponseInvalid) {
            control.get("response").markAsTouched();
          }
  
          // if the form is invalid then all the choice controls from questionForm FromArray are maked as dirty
          // so the choice text will be displayed red
          if (control.errors.isChoiceInvalid) {
            control.get("choice").markAsDirty();
          }
        }
      })
    });   
  }

  private _createFormAnswer(): void {
    this.formAnswer.token = this.route.snapshot.paramMap.get("token");
    this.formAnswer.responses = [];
    this.formAnswer.isAnonymous = this.reviewForm.value.isAnonymous;
    const numberOfCategories = this.questionsByCategory.length; 

    for(let index = 0; index < numberOfCategories; index++)
    {
      this.getQuestionsForm(index).value.forEach((group: any) => {
        const questionId = group.question.id;
        const choiceId = group.choice != null ? +group.choice : null;
        const resposeText = group.response;
        const response: QuestionResponse = {
          questionId: questionId,
          choiceId: choiceId,
          responseText: resposeText
        }
        this.formAnswer.responses.push(response);
      });
    };  
  }

  private _checkFormPreconditions(form: ReviewFormModel): boolean {
    if (!form.isAvailable) {
      this.message = `You have already submitted your responses for this review on ${form.submissionDate.substring(0, 10)}.`;
      return false;
    }

    if (!form.reviewQuestionsByCategory.some((reviewQuestionsByCategory: ReviewQuestionsByCategory) => reviewQuestionsByCategory.reviewQuestions.length)) {
      this.message = "There are no questions for this review.";
      return false;
    }
    
    return true;
  }
}