export class PieChartModel {
    series: number[] = [];
    labels: string[] = [];
    title: string = "";

    constructor(_title: string = "", _series: number[] = [], _labels: string[] = []) {
        this.series = _series;
        this.labels = _labels;
        this.title = _title;
    }
}