import { Injectable } from "@angular/core";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { SharedModule } from "../shared-module.module";


@Injectable({
  providedIn: SharedModule,
})
export class DialogService {
  private _swalNotificationCorner: typeof Swal;

  constructor() {
    this._swalNotificationCorner = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  }

  showDialog(options): Promise<any> {
    return Swal.fire(options);
  }

  showSimpleDialog(
    title: string,
    message?: string,
    icon?: SweetAlertIcon
  ): Promise<any> {
    return Swal.fire({
      title: title,
      text: message,
      icon: icon,
    });
  }

  showErrorMessage(title: string, text: string): Promise<any> {
    return this.showDialog({
      title: title,
      text: text,
      icon: "error",
      allowOutsideClick: false,
    });
  }

  showWarningMessage(title: string, text: string): Promise<any> {
    return this.showDialog({
      title: title,
      text: text,
      icon: "warning",
      allowOutsideClick: false,
    });
  }

  showSuccessMessage(title: string, text: string): Promise<any> {
    return this.showDialog({
      title: title,
      text: text,
      icon: "success",
      allowOutsideClick: false,
    });
  }

  public confirmationDialog(confirmButtonMessage: string) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmButtonMessage,
    });
  }

  public showServerError(_ = "Error") {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text:
        "Something went wrong, this admin may be already added, please refresh the page!",
    });
  }

  public showSuccessDelete(): void {
    this._swalNotificationCorner.fire({
      icon: "success",
      title: "Added successfully",
    });
  }

  showLoading() {
    Swal.showLoading();
    const cancelButton = Swal.getCancelButton();
    if (cancelButton) {
      cancelButton.style.display = 'none';
    }
  }

  showApprovalConfirmation(userName: string, period: string) {
    return this._showRequestConfirmationDialog(`Do you want to approve ${userName}'s holiday request between ${period}?`);
  }

  showApprovalBenefitRequest(userName: string, benefitName: string) {
    return this._showRequestConfirmationDialog(`Do you want to approve ${userName}'s benefit request for ${benefitName}?`);
  }

  private _showRequestConfirmationDialog(title: string) {
    return Swal.fire({
      title: title,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Accept",
      denyButtonText: "Decline",
      confirmButtonColor: '#22BC42'
    });
  }
}
