export class RouteConstants {
  public static readonly ACCESS_RESTRICTED = "/";
  public static readonly SIGN_IN = "/sign-in";
  public static readonly USER_DASHBOARD = "/dashboard-user";
  public static readonly ADMIN_DASHBOARD = "/dashboard-administrator";
  public static readonly REVIEW_TYPE = "api/reviewTypes";
  public static readonly USER = "/api/users";
  public static readonly COMPANy = "/api/companies";
  public static readonly USEFULL_LINK = "api/usefulLinks";
  public static readonly REVIEW = "api/reviews";
  public static readonly MONTLY_SALARY = "api/monthlySalaries";
  public static readonly HOLIDAY_REQUEST = "api/holidayrequests";
  public static readonly UNAUTHORIZED_PAGE = "/unauthorized-page";
  public static readonly HOLIDAY_STATS = "api/holidayStatistics";
  public static readonly UPLOAD_DOCUMENT_ENDPOINT = "api/holidayDocuments";
}
