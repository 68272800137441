import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { RouteConstants } from "../constants/route-constants";
import { UserService } from "../services/user.service";

@Injectable({ providedIn: "root" })
export class ConfirmationAuthGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return new Observable<boolean>((permisionStatus) => {
      this.userService.getUserRole().subscribe(
        (role) => {
          if (role) {
            if (route.data.minRole)
            {
              if(role.id >= route.data.minRole) {
                permisionStatus.next(true);
              }else {
                permisionStatus.next(false);
                this.router.navigate([RouteConstants.UNAUTHORIZED_PAGE]);
              }
            } else {
              permisionStatus.next(true);
            }
          } else {
            const baseUrl = route.url[0];
            const routeParam = route.url[1];
            permisionStatus.next(false);
            routeParam == undefined 
              ? window.localStorage.setItem("returnUrl", baseUrl.toString()) 
              : window.localStorage.setItem("returnUrl", baseUrl + "/" + routeParam);
            this.router.navigate([RouteConstants.SIGN_IN]);
          }
        },
        (_) => {
          const baseUrl = route.url[0];
          const routeParam = route.url[1];
          permisionStatus.next(false);
          routeParam == undefined 
            ? window.localStorage.setItem("returnUrl", baseUrl.toString())
            : window.localStorage.setItem("returnUrl", baseUrl + "/" + routeParam);
          this.router.navigate([RouteConstants.SIGN_IN]);
        }
      );
    });
  }
}
