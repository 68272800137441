import { EventEmitter, Output, SimpleChanges } from "@angular/core";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import * as moment from "moment";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: "trackify-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() minDate: string;
  @Input() maxDate: string;

  @Input() currentDate: string;
  @Output() currentDateChange = new EventEmitter<string>();

  selectedDate: Date;
  minDateRestriction: Date;
  maxDateRestriction: Date;

  bsConfiguration: BsDatepickerConfig;

  constructor() { }

  ngOnInit() {
    this.bsConfiguration = new BsDatepickerConfig();

    this.bsConfiguration.isAnimated = true;
    this.bsConfiguration.showWeekNumbers = false;
    this.bsConfiguration.useUtc = false;
    this.bsConfiguration.dateInputFormat = "DD-MMM-YY";
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentDateSimpleChange = changes.currentDate;
    if (!!currentDateSimpleChange) {
      this.selectedDate = !!currentDateSimpleChange.currentValue
        ? new Date(currentDateSimpleChange.currentValue)
        : undefined;
    }

    const minDateChange = changes.minDate;
    if (!!minDateChange && !!minDateChange.currentValue) {
      this.minDateRestriction = new Date(minDateChange.currentValue);
    }

    const maxDateChange = changes.maxDate;
    if (!!maxDateChange && !!maxDateChange.currentValue) {
      this.maxDateRestriction = new Date(maxDateChange.currentValue);
    }
  }

  onDateChanged($event: Date) {
    this.currentDateChange.emit(moment($event).startOf('day').toISOString(true));
  }
}
