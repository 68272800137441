import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ClientModel } from 'src/app/models/clients/ClientModel';
import { ClientsService } from 'src/app/services/clients.service';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';
@Component({
  selector: 'trackify-clients-details',
  templateUrl: './clients-details.component.html',
  styleUrls: ['./clients-details.component.scss']
})

export class ClientsDetailsComponent extends LoadableComponentBase implements OnInit {

  client: ClientModel;
  constructor(
    private route: ActivatedRoute,
    private clientService: ClientsService
  ) {
    super();
  }

  ngOnInit() {
    const clientId = this.route.snapshot.params.id;
    this._getClientById(clientId);
  }

  private _getClientById(clientId: number) {
    this.clientService.getClient(clientId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((client) => {
        this.client = client;

      });
  }
}
