import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';
import { formatDate } from '../../../shared/utils/Formatters';
import { UserService } from "src/app/services/user.service";
import { UserBriefModel } from 'src/app/models/users/UserBriefModel';
import { takeUntil, finalize } from "rxjs/operators";
import { MomentConstants } from 'src/app/constants/moment-constants';
@Component({
  selector: 'trackify-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent extends LoadableComponentBase implements OnInit {
  currentDate: string;
  currentUser: UserBriefModel
  constructor(private userService: UserService) {
    super();
  }

  ngOnInit() {
    this.currentDate = moment().toISOString();
    this.startLoader();
    this._getCurrentUser();
  }

  private _getCurrentUser() {
    this.userService.getCurrentUser()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.stopLoader();
        }))
      .subscribe((data) => {
        this.currentUser = data;
      })
  }

  getFormattedDate(date: string): string {
    return formatDate(date, MomentConstants.DAY_MONTH_DAY_YEAR);
  };
}
