import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiConstants } from "../constants/api-constants";
import { MenuItemsSettings } from "../models/menuItemsSettings";
import { ApiService } from "../shared/services/api.service";

@Injectable({
    providedIn: 'root'
  })
  export class MenuItemsSettingsService {
    private _apiBaseUrl = ApiConstants.MENU_ITEMS_SETTINGS_ENDPOINT;
    
    constructor(private apiService: ApiService) { }

    public getMenuItemsSettings(): Observable<MenuItemsSettings> {
        return this.apiService.get<MenuItemsSettings>(`${this._apiBaseUrl}`);
      }
  }