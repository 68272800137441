import { Component, Input, OnInit, ViewChild, ElementRef } from "@angular/core";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { ModalDirective } from "ngx-bootstrap/modal";
import { clone } from "src/app/shared/utils/Utils";
import { BenefitCategoryDto } from "src/app/models/admin-benefits/BenefitCategoryDto";
import { DialogService } from "src/app/shared/services/dialog.service";
import { BenefitService } from "src/app/services/benefits/benefit.service";
import { switchMap, takeUntil, finalize } from "rxjs/operators";
import { PatternConstants } from "src/app/constants/pattern-constants";
import { forkJoin, of } from "rxjs";
import { NgForm } from "@angular/forms";
import { BenefitTypeEnum } from "src/app/models/admin-benefits/BenefitTypeDto";
import { ChangeStringFormat } from "src/app/shared/utils/Formatters";
import { BenefitWithLogo } from "src/app/models/admin-benefits/BenefitWithLogo";
import { BenefitWithoutLogoDto } from "src/app/models/admin-benefits/BenefitWithoutLogoDto";

@Component({
  selector: "trackify-admin-benefits-management",
  templateUrl: "./admin-benefits-management.component.html",
  styleUrls: ["./admin-benefits-management.component.scss"],
})
export class AdminBenefitsManagementComponent
  extends GridComponentBase<BenefitWithLogo>
  implements OnInit {

  benefitCategories: BenefitCategoryDto[];
  benefitTypes: BenefitCategoryDto[];
  maxNameLength = 25;
  nameValidationPattern = PatternConstants.NAME_WITH_ANY_CHARACTERS_PATTERN;
  numberValidationPattern = PatternConstants.NUMBER_PATTERN;
  categoryDto: BenefitCategoryDto;
  message: string;
  uploadedLogo: File;
  logoUrl: string | ArrayBuffer;
  isAddMode: boolean;

  @Input() isInTab: boolean = false;

  @ViewChild("benefitModal", { static: false }) modal: ModalDirective;
  @ViewChild('benefitForm', { static: false }) myForm: NgForm;
  @ViewChild('logoInput', { static: false }) logoInput: ElementRef;

  constructor(
    private benefitService: BenefitService,
    private dialogService: DialogService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        valueGetter: (x) => (x.data.name ? x.data.name : "N/A"),
        headerName: "Name",
        width: 170,
        sort: "asc",
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.benefitCategory.name ? x.data.benefitCategory.name : "N/A"),
        headerName: "Category",
        width: 170,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (ChangeStringFormat(x.data.benefitType.name)),
        headerName: 'Benefit Type',
        width: 170,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.description ? x.data.description : "N/A"),
        headerName: "Description",
        width: 260,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => (x.data.points ? x.data.points.toFixed(2) : "0"),
        headerName: "Points",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.minPeople ? x.data.minPeople : "N/A"),
        headerName: "Min People",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.deadline ? x.data.deadline : "N/A"),
        headerName: "Deadline",
        width: 110,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.availableForPFA),
        headerName: "Available for PFA/SRL",
        width: 140,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.isPublished),
        headerName: "Published",
        width: 120,
        suppressSizeToFit: true,
      }
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No benefits entries.</span>';
  }

  ngOnInit() {
    this.startLoader();
    this._loadData();
  }

  private _loadData(): void {
    this.benefitService.getBenefits()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader())
      )
      .subscribe((benefits: BenefitWithLogo[]) => {
        this.data = benefits;
      });
  }

  private _onHide(): void {
    this.gridOptions.api.hideOverlay();
    this.modal.hide();
  }

  openModal(isAddMode: boolean): void {
    this._clearLogoInputText();
    if (this.myForm && isAddMode) {
      this.myForm.form.markAsPristine();
      this.myForm.form.markAsUntouched();
    }

    this.startLoader();
    forkJoin({
      requestCategories: this.benefitService.getBenefitCategories(),
      requestTypes: this.benefitService.getBenefitTypes()
    })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader())
      )
      .subscribe(({ requestCategories, requestTypes }) => {
        this.benefitCategories = requestCategories;
        this.benefitTypes = requestTypes;
        this.isAddMode = isAddMode;

        this.updatedItem = isAddMode
          ? <BenefitWithLogo>{
            benefitCategory: this.benefitCategories[0],
            availableForPFA: true,
            isPublished: false,
          }
          : clone(this.currentSelection);

        this.logoUrl = this.updatedItem.logoPath === undefined || this.updatedItem.logoPath === null
          ? "../../../../assets/img/no-image.png"
          : this.updatedItem.logoPath;

        this.modal.toggle();
      });

    this.uploadedLogo = undefined;
  }

  compareSelectedBenefit(benefitCategoryFirst, benefitCategorySecond): boolean {
    return benefitCategoryFirst && benefitCategorySecond && benefitCategoryFirst.id === benefitCategorySecond.id;
  }

  compareSelectedBenefitType(benefitTypeFirst, benefitTypeSecond): boolean {
    return benefitTypeFirst && benefitTypeSecond && benefitTypeFirst.id === benefitTypeSecond.id;
  }

  onDelete(): void {
    this.dialogService
      .showDialog({
        title: `Are you sure you want to archive this benefit?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DC3545",
        confirmButtonText: "Archive",
      })
      .then((result) => {
        if (!result.value) {
          return;
        }
        this.gridOptions.api.showLoadingOverlay();
        this.startLoader();
        this.benefitService
          .deleteBenefit(this.currentSelection.id)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => this.stopLoader())
          )
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            this.updateGridAfterDelete();
            this.currentSelection = null;
            this.dialogService.showSuccessMessage(
              "Success!",
              "Selected benefit archived successfully."
            );
          });
      });
  }

  saveChanges(): void {
    this.gridOptions.api.showLoadingOverlay();
    this.updatedItem.benefitCategory = this.benefitCategories[0];

    const formData = new FormData();
    if (this.uploadedLogo != undefined) {
      formData.append('file', this.uploadedLogo, this.uploadedLogo.name);
    }

    this.startLoader();
    if (this.isAddMode) {
      this.benefitService.addBenefit(this.updatedItem)
        .pipe(takeUntil(this.ngUnsubscribe),
          finalize(() => this.stopLoader()),
          switchMap((benefitWithoutLogo: BenefitWithoutLogoDto) => {
            this.updatedItem.minPeople = benefitWithoutLogo.minPeople;
            this.updatedItem.id = benefitWithoutLogo.id;

            // if there is an uploaded file then the file is saved to the benefit
            if (this.uploadedLogo) {
              return this.benefitService.addBenefitLogo(benefitWithoutLogo.id, formData);
            }

            return of(this._mapToBenefitWithLogo(benefitWithoutLogo, this.updatedItem.logoPath));
          }))
        .subscribe((benefit: BenefitWithLogo) => {
          this.updatedItem.logoPath = benefit.logoPath;
          this.data.push(this.updatedItem);
          this.updateGridAfterAdd(clone(benefit));
          this.gridOptions.api.deselectAll();
          this.currentSelection = undefined;
          this.dialogService.showSuccessMessage('Success!', 'Benefit added successfully.');
          this._onHide();
        },
          (errorMessage) => {
            this.dialogService.showSimpleDialog("Error", errorMessage, "error");
          });
    }
    else {
      this.benefitService.editBenefit(this.updatedItem)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => this.stopLoader()),
          switchMap((benefitWithoutLogo: BenefitWithoutLogoDto) => {
            this.updatedItem.minPeople = benefitWithoutLogo.minPeople;

            // if there is an uploaded file then the file is saved to the benefit
            if (this.uploadedLogo) {
              return this.benefitService.addBenefitLogo(benefitWithoutLogo.id, formData);
            }

            return of(this._mapToBenefitWithLogo(benefitWithoutLogo, this.updatedItem.logoPath));
          }))
        .subscribe((benefit: BenefitWithLogo) => {
          this.updatedItem.logoPath = benefit.logoPath;
          this.updateGridAfterEdit(this.updatedItem);
          this.gridOptions.api.deselectAll();
          this.currentSelection = undefined;
          this.dialogService.showSuccessMessage('Success!', 'Benefit updated successfully.');
          this._onHide();
        },
          (errorMessage) => {
            this.dialogService.showSimpleDialog("Error", errorMessage, "error");
          });
    }
  }

  onRowDoubleClicked(_: any): void {
    this.openModal(false);
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) {
      return;
    }
    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(
      this.data.find((u) => u.id == this.currentSelection.id)
    );
  }

  onPublish(): void {
    this.dialogService
      .showDialog({
        title: `Are you sure you want to publish this benefit? Everyone will be notified about this action.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#18ce0f",
        confirmButtonText: "Publish",
      })
      .then((result) => {
        if (!result.value) {
          return;
        }
        this.gridOptions.api.showLoadingOverlay();
        this.startLoader();
        this.benefitService.publishBenefit(this.currentSelection.id)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => this.stopLoader())
          )
          .subscribe((benefit: BenefitWithLogo) => {
            this.updateGridAfterEdit(benefit);
            this.currentSelection = null;
            this.dialogService.showSuccessMessage(
              "Success!",
              "Selected benefit has been published!"
            );
          });
      });
  }

  onConceal(): void {
    this.dialogService
      .showDialog({
        title: `Are you sure you want to conceal this benefit? Everyone will be notified about this action.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FFB236",
        confirmButtonText: "Conceal",
      })
      .then((result) => {
        if (!result.value) {
          return;
        }
        this.gridOptions.api.showLoadingOverlay();
        this.startLoader();
        this.benefitService.concealBenefit(this.currentSelection.id)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => this.stopLoader())
          )
          .subscribe((benefit: BenefitWithLogo) => {
            this.updateGridAfterEdit(benefit);
            this.currentSelection = null;
            this.dialogService.showSuccessMessage(
              "Success!",
              "Selected benefit has been concealed!"
            );
          });
      });
  }

  isEvent(benefitType: string): boolean {
    return benefitType === BenefitTypeEnum.EVENT;
  }

  isRecurrent(benefitType: string): boolean {
    return benefitType === BenefitTypeEnum.RECURRENT;
  }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  onCloseModal(): void {
    this.updatedItem = null;
  }

  /*
    Updates the logoUrl depending on the file uploaded so the chosen image can be displayed.
  */
  updateItemLogo(event: Event): void {
    const target = event.target as HTMLInputElement;
    const uploadedFile: File = target.files[0];

    if (!uploadedFile) {
      return;
    }

    const reader: FileReader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = (_) => {
      this.logoUrl = reader.result;
    }

    this.uploadedLogo = uploadedFile;
  }

  private _mapToBenefitWithLogo(benefitWithoutLogo: BenefitWithoutLogoDto, logoPath: string): BenefitWithLogo {
    const benefitWithLogo: BenefitWithLogo = {
      id: benefitWithoutLogo.id,
      name: benefitWithoutLogo.name,
      points: benefitWithoutLogo.points,
      description: benefitWithoutLogo.description,
      minPeople: benefitWithoutLogo.minPeople,
      benefitCategory: benefitWithoutLogo.benefitCategory,
      benefitType: benefitWithoutLogo.benefitType,
      logoPath: logoPath,
      isPublished: benefitWithoutLogo.isPublished,
      availableForPFA: benefitWithoutLogo.availableForPFA,
      deadline: benefitWithoutLogo.deadline
    }
    return benefitWithLogo;
  }

  private _clearLogoInputText(): void {
    if (this.logoInput)
      this.logoInput.nativeElement.value = "";
  }
}
