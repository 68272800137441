import { Component, OnInit } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AllBenefitsByTypeModel } from 'src/app/models/user-benefits/AllBenefitsByTypeModel';
import { BenefitBriefByCategoryModel } from 'src/app/models/user-benefits/BenefitBriefByCategoryModel';
import { BenefitCardModel } from 'src/app/models/user-benefits/BenefitCardModel';
import { BenefitOneTimeCardModel } from 'src/app/models/user-benefits/BenefitOneTimeCardModel';
import { EventBenefitCardDetails } from 'src/app/models/user-benefits/EventBenefitCardDetails';
import { UserBriefModel } from 'src/app/models/users/UserBriefModel';
import { UserGeneralInfo } from 'src/app/models/users/UserGeneralnfo';
import { BenefitService } from 'src/app/services/benefits/benefit.service';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';

@Component({
  selector: 'trackify-user-benefits',
  templateUrl: './user-benefits.component.html',
  styleUrls: ['./user-benefits.component.scss']
})
export class UserBenefitsComponent extends LoadableComponentBase implements OnInit {
  currentTab: string = "Recurrent";
  reccurentBenefitsDetails: BenefitBriefByCategoryModel[];
  eventBenefitsDetails: BenefitBriefByCategoryModel[];
  oneTimeBenefitsDetails: BenefitBriefByCategoryModel[];
  activeEventsDetails: BenefitBriefByCategoryModel[];
  currentUser: UserBriefModel;
  users: UserGeneralInfo[];
  isRecurrent: boolean = false;
  isOneTime: boolean = false;
  isEvent: boolean = false;
  isActiveEvent: boolean = false;
  nonExistentId: number = -1;

  constructor(private benefitService: BenefitService) {
    super();
  }

  ngOnInit(): void {
    this._getData();
  }

  onSelect(data: TabDirective): void {
    this.currentTab = data.heading;
  }

  onEventAdded(data: EventBenefitCardDetails): void {
    const categoryIndex = this.activeEventsDetails.findIndex(
      (category: BenefitBriefByCategoryModel) => category.categoryId === data.benefitCategoryId);

    if (categoryIndex !== this.nonExistentId) {
      const updatedCategory = { ...this.activeEventsDetails[categoryIndex] };
      updatedCategory.benefits = [...updatedCategory.benefits, data];
      const updatedActiveEventsDetails = [...this.activeEventsDetails];
      updatedActiveEventsDetails[categoryIndex] = updatedCategory;
      this.activeEventsDetails = updatedActiveEventsDetails;
    }
  }

  onEventDeleted(data: number): void {
    this.activeEventsDetails = this.activeEventsDetails.map((items: BenefitBriefByCategoryModel) => ({
        ...items,
        benefits: items.benefits.filter((benefit: BenefitCardModel) => benefit.benefitRequest.id !== data),
      }));
  }

  onEventModified(data: BenefitCardModel): void {
  for (const category of this.activeEventsDetails) {
      for (let i = 0; i < category.benefits.length; i++) {
        const benefit = category.benefits[i] as BenefitCardModel;
        if (benefit.benefitRequest.id === data.benefitRequest.id) {
          category.benefits[i] = data;
          break; 
        }
      }
    }
  }

  onRecurrentBenefitModified(data: BenefitCardModel): void {
    this._updateListAfterModifiedBenefit(data, this.reccurentBenefitsDetails);
  }

  onOneTimeBenefitModified(data: BenefitOneTimeCardModel): void {
    this._updateListAfterModifiedBenefit(data, this.oneTimeBenefitsDetails);
  }

  private _updateListAfterModifiedBenefit(data: BenefitCardModel | BenefitOneTimeCardModel, benefits: BenefitBriefByCategoryModel[]): void {
    for (const category of benefits) {
      for (let i = 0; i < category.benefits.length; i++) {
        const benefit = category.benefits[i];
        if (benefit.id === data.id) {
          category.benefits[i] = data;
          break; 
        }
      }
    }
  }
  
  private _checkBenefitsPresence(benefits: AllBenefitsByTypeModel): void {
    this.isRecurrent = benefits.recurrentBenefits.some(item => item.benefits.length > 0);
    this.isEvent = benefits.eventBenefits.some(item => item.benefits.length > 0);
    this.isActiveEvent = benefits.activeEvents.some(item => item.benefits.length > 0);
    this.isOneTime = benefits.oneTimeBenefits.some(item => item.benefits.length > 0);
  }

  private _getData(): void {
    this.startLoader();
    this.benefitService.getAllBenefitsGroupedByType()
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
      .subscribe((benefits: AllBenefitsByTypeModel) => {
        this.reccurentBenefitsDetails = benefits.recurrentBenefits;
        this.eventBenefitsDetails = benefits.eventBenefits;
        this.oneTimeBenefitsDetails = benefits.oneTimeBenefits;
        this.activeEventsDetails = benefits.activeEvents;
        this._checkBenefitsPresence(benefits);
      });
  }
}