import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConstants } from '../constants/api-constants';
import { PlannerModel } from '../models/planner/PlannerModel';
import { SetPlannerProjectModel } from '../models/planner/SetPlannerProjectModel';
import { WeeklyPlannerDetailedModel } from '../models/planner/WeeklyPlannerDetailedModel';
import { ProjectModel } from '../models/projectModels/ProjectModel';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class PlannerService {
  private _apiBaseUrl = ApiConstants.PROJECTS_ENDPOINT;
  constructor(private apiService: ApiService) { }

  public getAllProjects(): Observable<ProjectModel[]> {
    return this.apiService.get<ProjectModel[]>(`${this._apiBaseUrl}`);
  }

  public getPlannerForOneMonth(): Observable<WeeklyPlannerDetailedModel[]> {
    return this.apiService.get<WeeklyPlannerDetailedModel[]>(`${ApiConstants.WEEKLY_PLANNERS_ENDPOINT}`)
  }

  public setProjectForPlanner(projects: SetPlannerProjectModel): Observable<SetPlannerProjectModel> {
    return this.apiService.put<SetPlannerProjectModel>(`${ApiConstants.WEEKLY_PLANNERS_ENDPOINT}`, projects)
  }

  public copyProjectFromPastDay(currentPlanningId: number): Observable<PlannerModel> {
    return this.apiService.put<PlannerModel>(`${ApiConstants.WEEKLY_PLANNERS_ENDPOINT}/repeat-day/${currentPlanningId}`, currentPlanningId)
  }

  public copyProjectFromPastWeek(currentPlanningId: number): Observable<PlannerModel[]> {
    return this.apiService.put<PlannerModel[]>(`${ApiConstants.WEEKLY_PLANNERS_ENDPOINT}/repeat-week/${currentPlanningId}`, currentPlanningId)
  }

  public readPlannerForOneMonth(): Observable<WeeklyPlannerDetailedModel[]> {
    return this.apiService.get<WeeklyPlannerDetailedModel[]>(`${ApiConstants.WEEKLY_PLANNERS_ENDPOINT}/regular`)
  }
}
