import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConstants } from '../constants/api-constants';
import { ApiService } from '../shared/services/api.service';
import { BenefitsPointsLogModel } from '../models/benefitsPointsLogModel/BenefitsPointsLog';
import { UserBenefitsPointsLogModel } from '../models/benefitsPointsLogModel/UserBenefitsPointsLog';

@Injectable({
    providedIn: 'root'
})

export class BenefitsPointsLogService {

    private _apiUrl = ApiConstants.BENEFITS_POINTS_LOG_API_ENDPOINT;
    constructor(private apiService: ApiService) { }

    getBenefitsPointsLog(): Observable<BenefitsPointsLogModel[]> {
        return this.apiService.get<BenefitsPointsLogModel[]>(`${this._apiUrl}`);
    }

    getBenefitsPointsLogById(id: number): Observable<BenefitsPointsLogModel[]> {
        return this.apiService.get<BenefitsPointsLogModel[]>(`${this._apiUrl}/${id}`);
    }

    getUserBenefitPointLogs(): Observable<UserBenefitsPointsLogModel[]> {
        return this.apiService.get<UserBenefitsPointsLogModel[]>(`${this._apiUrl}/allUsersBenefitPointLogs`);
    }

    getFilteredBenefitPointLogs(month: number, year: number): Observable<UserBenefitsPointsLogModel[]> {
        return this.apiService.get<UserBenefitsPointsLogModel[]>(`${this._apiUrl}/allUsersFiltered/${month}/${year}`);
    }

    getAvailablePointsForCategory(categoryId: number): Observable<BenefitsPointsLogModel> {
        return this.apiService.get<BenefitsPointsLogModel>(`${this._apiUrl}/categoryPoints/${categoryId}`);
    }

    retrievePointsFromBenefitLogs(benefitLogId: number): Observable<BenefitsPointsLogModel> {
        return this.apiService.get<BenefitsPointsLogModel>(`${this._apiUrl}/retrieve/${benefitLogId}`);
    }
}