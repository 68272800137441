import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { formatDate } from 'src/app/shared/utils/Formatters';

@Component({
  selector: 'trackify-admin-benefits',
  templateUrl: './admin-benefits.component.html',
  styleUrls: ['./admin-benefits.component.scss']
})
export class AdminBenefitsComponent implements OnInit {
  currentDate: string;
  formatDateForTable = formatDate;
  constructor() { }

  ngOnInit() {
    this.currentDate = moment().toISOString();
  }

}
