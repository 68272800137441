import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { UserNameModel } from 'src/app/models/users/UserNameModel';

@Injectable({
  providedIn: 'root'
})
export class NameNotificationService {

  private _nameRetrievedSource = new Subject<UserNameModel>();
 
  nameRetrieved$ = this._nameRetrievedSource.asObservable();

  notifyNameRetrieved(name: UserNameModel){
      this._nameRetrievedSource.next(name);
  }
  
}
