import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ChildrenModel } from 'src/app/models/users/ChildrenModel';

@Component({
  selector: 'children-details',
  templateUrl: './children-details.component.html',
  styleUrls: ['./children-details.component.scss']
})
export class ChildrenDetailsComponent implements OnInit {

  @Input() childrenLeftSide: ChildrenModel[];
  @Input() childrenRightSide: ChildrenModel[];
  formatDateForTable = formatDate;

  constructor() { }

  ngOnInit() { }
}
