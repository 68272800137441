import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {ChartComponent } from "ng-apexcharts";
import { ChartOptions } from 'src/app/shared/models/ChartOptions';
import { BarChartModel } from './../../../models/holiday-stats/BarChartModel';

@Component({
  selector: 'trackify-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  @ViewChild("chart", { static: false }) chart: ChartComponent;
  @Input() barChartModel: BarChartModel;
  public chartOptions: Partial<ChartOptions>;

  constructor() { }

  ngOnInit() {
    this.chartOptions = {
      series: this.barChartModel.series,
      chart: {
        type: "bar",
        height: 250,
        stacked: true
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        type: "category",
        categories: this.barChartModel.labels
      },
      legend: {
        position: "right",
        offsetY: 50
      },
      fill: {
        opacity: 1
      },
      title: {
        text: this.barChartModel.title,
        align: 'center',
        style: {
          fontSize: "20px"
        }
      }
    };
  }
}
