import { Component, Input, OnInit } from '@angular/core';
import { PatternConstants } from 'src/app/constants/pattern-constants';
import { ChildrenModel } from 'src/app/models/users/ChildrenModel';
import { UserInfoModel } from 'src/app/models/users/UserInfoModel';

@Component({
  selector: 'children-edit-details',
  templateUrl: './children-edit-details.component.html',
  styleUrls: ['./children-edit-details.component.scss']
})
export class ChildrenEditDetailsComponent implements OnInit {

  maxBirthdate = new Date();
  namePattern = PatternConstants.NAME_PATTERN;

  @Input() user: UserInfoModel;
  @Input() missingDataForChild: string[] = [];

  ngOnInit() {
  }

  createChildField() {
    let item = new ChildrenModel();
    this.user.children.push(item);
  }

  removeChildField(index: number) {
    if (index !== -1) {
      this.user.children.splice(index, 1);
    }
  }

  validateChildData(i: number) {
    return this.missingDataForChild.includes(i.toString());
  }
}
