import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConstants } from 'src/app/constants/api-constants';
import { ApiService } from 'src/app/shared/services/api.service';
import { BenefitRequest } from 'src/app/models/admin-benefits/BenefitRequest';
import { CreateBenefitRequestDto } from 'src/app/models/admin-benefits/CreateBenefitRequestDto';
import { CreateRecurrentBenefitRequestModel } from 'src/app/models/benefit-request/CreateRecurrentBenefitRequestModel';
import { BenefitRequestWithEnrollment } from 'src/app/models/benefit-request/BenefitRequestWithEnrollment';
import { BenefitRequestEventModel } from 'src/app/models/user-benefits/BenefitRequestEventModel';

@Injectable({
    providedIn: 'root'
})
export class BenefitRequestService {
    private _apiUrl = ApiConstants.BENEFIT_REQUESTS_ENDPOINT;

    constructor(private apiService: ApiService) { }

    getAddBenefitRequests(): Observable<BenefitRequest[]> {
        return this.apiService.get<BenefitRequest[]>(`${this._apiUrl}/enrollingRequests`);
    }

    getDeleteBenefitRequests(): Observable<BenefitRequest[]> {
        return this.apiService.get<BenefitRequest[]>(`${this._apiUrl}/cancellingRequests`);
    }

    declineBenefitRequest(benefitRequestId: number): Observable<BenefitRequest> {
        return this.apiService.get<BenefitRequest>(`${this._apiUrl}/decline/${benefitRequestId}`)
    }

    approveBenefitRequest(benefitRequestId: number): Observable<BenefitRequest> {
        return this.apiService.get<BenefitRequest>(`${this._apiUrl}/approve/${benefitRequestId}`)
    }

    joinBenefitEvent(benefitRequestId: number): Observable<BenefitRequest> {
        return this.apiService.get<BenefitRequest>(`${this._apiUrl}/join/${benefitRequestId}`);
    }

    leaveBenefitEvent(benefitRequestId: number): Observable<BenefitRequest> {
        return this.apiService.get<BenefitRequest>(`${this._apiUrl}/leave/${benefitRequestId}`);
    }

    cancelBenefitRequest(benefitRequestId: number): Observable<BenefitRequest> {
        return this.apiService.get<BenefitRequest>(`${this._apiUrl}/cancel/${benefitRequestId}`);
    }

    createBenefitRequest(createBenefitRequest: CreateBenefitRequestDto): Observable<BenefitRequest> {
        return this.apiService.post<BenefitRequest>(`${this._apiUrl}`, createBenefitRequest);
    }

    createRecurrentBenefitRequest(createRecurrentBenefitRequest: CreateRecurrentBenefitRequestModel): Observable<BenefitRequestWithEnrollment> {
        return this.apiService.post<BenefitRequestWithEnrollment>(`${this._apiUrl}/createRecurrentRequest`, createRecurrentBenefitRequest);
    }

    completeBenefitRequestEvent(benefitRequestId: number): Observable<BenefitRequest> {
        return this.apiService.get<BenefitRequest>(`${this._apiUrl}/complete/${benefitRequestId}`);
    }

    getBenefitRequestDetails(id: number): Observable<BenefitRequest> {
        return this.apiService.get<BenefitRequest>(`${this._apiUrl}/details/${id}`);
    }

    getBenefitRequestEventDetails(benefitRequestId: number): Observable<BenefitRequestEventModel> {
        return this.apiService.get<BenefitRequestEventModel>(`${this._apiUrl}/invitation/${benefitRequestId}`);
    }

    createOneTimeBenefitRequest(benefitId: number): Observable<void> {
        return this.apiService.post(`${this._apiUrl}/createOneTimeRequest`, benefitId); 
    }
}