export class UsefulLinkModel {
  id: number;
  name: string;
  url: string;
  isGlobal: boolean;
  author: string;

  constructor() {
    this.url = "";
  }
}
