export class EmployeeConstants {
    public static readonly statuses = [
        { value: 1, name: "Activ" },
        { value: 2, name: "CCC - Concediu creștere copil" },
        { value: 3, name: "CFSS - Concediu fără salariu pentru studii" },
    ]
    public static readonly cimTypes = [
        { value: 1, name: "Perioadă determinată" },
        { value: 2, name: "Perioadă nedeterminată" },
    ]
}
