import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { LocalStorageConstants } from "../constants/local-storage-constants";
import { RouteConstants } from "../constants/route-constants";
import { RoleType } from "../models/users/RoleType";
import { UserService } from "../services/user.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return new Observable<boolean>((permisionStatus) => {
      this.userService.getUserRole().subscribe(
        (role) => {
          if (role) {
            if (!!localStorage.getItem("returnUrl")) {
              permisionStatus.next(false);
              this.router.navigate([localStorage.getItem("returnUrl")]);
              localStorage.clear();
            }
            if (route.data.minRole) {
              if(role.id == RoleType.DeliveryManager) {
                localStorage.setItem(LocalStorageConstants.IS_DELIVERY_MANAGER, "true");
              }
              else{
                localStorage.removeItem(LocalStorageConstants.IS_DELIVERY_MANAGER);
              }

              if(role.id >= route.data.minRole) {
                permisionStatus.next(true);
              }
              else {
                permisionStatus.next(false);
                this.router.navigate([RouteConstants.UNAUTHORIZED_PAGE]);
              }
            } else {
              permisionStatus.next(true);
            }
          } else {
            permisionStatus.next(false);
            this.router.navigate([RouteConstants.SIGN_IN]);
          }
        },
        (_) => {
          permisionStatus.next(false);
          this.router.navigate([RouteConstants.SIGN_IN]);
        }
      );
    });
  }
}
