import { DateTimeModel } from "src/app/models/DateTimeModel";
import { CurrencyEnum } from "src/app/models/clients/CurrencyEnum";

export function clone(data) {
  return data != null ? JSON.parse(JSON.stringify(data)) : data;
}

export function compareEntitiesById(obj1: any, obj2: any) {
  return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
}

export function createUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line:no-bitwise
    const r = Math.random() * 16 | 0;
    // tslint:disable-next-line:no-bitwise
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function sortNumbersArray(data: number[]) {
  data.sort((v1, v2) => v1 > v2 ? -1 : 1);
}

export function isPastCurrentDate(inputDate: string): boolean {
  const currentDate = new Date();
  const parsedInputDate = new Date(inputDate);
  return parsedInputDate < currentDate;
}

export function compareStringsIgnoreCase(s1: string, s2: string): number {
  const v1 = s1 ? s1.toLowerCase() : '';
  const v2 = s2 ? s2.toLowerCase() : '';
  return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0);
}

export function dateComparator(date1, date2) {
  var date1Number = date1 && new Date(date1).getTime();
  var date2Number = date2 && new Date(date2).getTime();

  if (date1Number == null && date2Number == null) {
    return 0;
  }

  if (date1Number == null) {
    return -1;
  } else if (date2Number == null) {
    return 1;
  }

  return date1Number - date2Number;
}

export function getYesOrNo(value) {
  return value ? "Yes" : "No";
}

export function getValue(value) {
  return value != null ? value : "-";
}

export function getCurrencyName(value: number) {
  return CurrencyEnum[value];
}

export function createDateComponent(stringDate?: string): DateTimeModel {
  if (stringDate == null) {
    return null;
  }
  const date = new Date(stringDate);
  return new DateTimeModel(date.getDate(), date.getMonth() + 1, date.getFullYear());
}