import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiConstants } from 'src/app/constants/api-constants';
import { HolidayRequestTypeModel } from 'src/app/models/holiday-request/HolidayRequestTypeModel';

import { ApiService } from 'src/app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class HolidayRequestTypeService {
  private _apiBaseUrl = ApiConstants.HOLIDAY_REQUEST_TYPES_API_ENDPOINT;

  constructor(private apiService: ApiService) {}

  public getAll(): Observable<HolidayRequestTypeModel[]> {
    return this.apiService.get<HolidayRequestTypeModel[]>(`${this._apiBaseUrl}`);
  }

  public addHolidayRequestType(holidayRequestType: HolidayRequestTypeModel): Observable<HolidayRequestTypeModel> {
    return this.apiService.post<HolidayRequestTypeModel>(this._apiBaseUrl, holidayRequestType);
  }

  public editHolidayRequestType(holidayRequestType: HolidayRequestTypeModel): Observable<HolidayRequestTypeModel> {
    return this.apiService.put<HolidayRequestTypeModel>(this._apiBaseUrl, holidayRequestType);
  }

  public deleteHolidayRequestType(id: number): Observable<HolidayRequestTypeModel> {
    return this.apiService.delete<HolidayRequestTypeModel>(`${this._apiBaseUrl}/${id}`);
  }
}
