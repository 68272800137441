import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../shared/services/api.service";

import { MonthDetailModel } from "../models/monthDetailsModel/MonthDetailModel";
import { DashboardDataModel } from "../models/dashboardData/DashboardDataModel";
import { MonthSimpleModel } from '../models/monthhDetails/MonthSimpleModel';
import { FreeDayModel } from "../models/monthDetailsModel/FreeDayModel";

@Injectable({
  providedIn: "root",
})
export class MonthDetailsService {
  private _apiMontlyDetails = "/api/monthdetails";

  private _apiBaseUrl = "api/monthdetails";

  constructor(private apiService: ApiService) { }

  public getAllMonthDetails(): Observable<MonthDetailModel[]> {
    return this.apiService.get<MonthDetailModel[]>(`${this._apiBaseUrl}`);
  }

  public getMonthDetailsByYear(year: number): Observable<MonthDetailModel[]> {
    return this.apiService.get<MonthDetailModel[]>(`${this._apiBaseUrl}/by-year/${year}`);
  }

  public getYears(): Observable<number[]> {
    return this.apiService.get<number[]>(`${this._apiBaseUrl}/years`);
  }

  public addYear(year: number): Observable<MonthDetailModel[]> {
    return this.apiService.post<MonthDetailModel[]>(
      `${this._apiBaseUrl}/year/${year}`,
      null
    );
  }

  public updateFreeDaysForMonth(monthDetailsId: number, freeDays: FreeDayModel[]): Observable<MonthDetailModel> {
    return this.apiService.put<MonthDetailModel>(
      `${this._apiBaseUrl}/${monthDetailsId}`,
      freeDays
    );
  }

  getMonthHolidays() {
    return this.apiService.get<DashboardDataModel>(
      this._apiMontlyDetails + "/current"
    );
  }

  getAllSimpleMonths(): Observable<MonthSimpleModel[]> {
    return this.apiService.get<MonthSimpleModel[]>(this._apiMontlyDetails + "/simple");
  }
}
