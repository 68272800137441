import * as moment from "moment";
import { MonthDetailModel } from "../../models/monthDetailsModel/MonthDetailModel";

export function getUtcMoment(m: moment.Moment): moment.Moment {
    return moment.utc(m.add(m.utcOffset(), 'm'));
}

export function getUtcDate(d: Date): moment.Moment {
    const m = moment.utc(d);
    return getUtcMoment(m);
}

export function getUtcDateFromString(d?: string): moment.Moment {
    const m = d ? moment.utc(d) : moment.utc();
    return getUtcMoment(m);
}

export function isSameDate(date1: string, date2: string): boolean {
    const firstDate = getUtcDateFromString(date1);
    const secondDate = getUtcDateFromString(date2);

    return firstDate.isSame(secondDate, 'd');
}

export function getISODate(date: string): string {
    return getUtcDateFromString(date).toISOString();
}

export function getMomentFromMonthDetails(monthDetails: MonthDetailModel): moment.Moment {
    const momentDate = moment();

    momentDate.set('year', monthDetails.year);
    momentDate.set('month', monthDetails.monthNumber - 1);
    momentDate.set('date', 1);

    return momentDate;
}

export function dateHasPassed(d: string, m: moment.Moment): boolean {
    const holidayDate = getUtcDateFromString(d);
    const currentDate = getUtcMoment(m);

    if (currentDate.date() < 6) {
        return !holidayDate.isSameOrAfter(moment().subtract(1, 'months').startOf('month'));
    } else {
        return !holidayDate.isSameOrAfter(moment().startOf('month'));
    }
}