export class SidenavConstants {
  public static readonly MENU_REVIWES = "Reviews";
  public static readonly MENU_MONTH_DETAILS = "Month Details";
  public static readonly MENU_SETTINGS = "Settings";
  public static readonly MENU_DASHBOARD = "Dashboard";
  public static readonly MENU_USERS = "Users";
  public static readonly MENU_COMPANIES = "Companies";
  public static readonly MENU_USER_DETAILS = "User Details";
  public static readonly MENU_MONTHLY_STATS = "Monthly Stats";
  public static readonly MENU_REGULAR_USER_REVIEWS = "Reviews";
  public static readonly MENU_DAILY_STATS = "Daily Stats";
  public static readonly MENU_PLANNER = "Planner";
  public static readonly MENU_MY_STATS = "My Stats";
  public static readonly MENU_CLIENTS = "Clients";
  public static readonly MENU_PROJECTS = "Projects";

  public static readonly MENU_HOLIDAY_DROPDOWN = "Holiday";
  public static readonly MENU_HOLIDAY_REQUEST = "Request";
  public static readonly MENU_HOLIDAY_STATISTICS = "Stats";
  public static readonly MENU_SORTABLE_HOLIDAY = "Monthly";

  public static readonly MENU_BENEFITS_ADMIN = "Manage Benefits";
  public static readonly MENU_BENEFITS = "Benefits";
}
