import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";

import { UserRoleModel } from "../models/users/UserRoleModel";
import { UserModel } from "../models/users/UserModel";
import { UserBriefModel } from "../models/users/UserBriefModel";
import { UserInfoModel } from "../models/users/UserInfoModel";
import { UserSettingModel } from "../models/settings/UserSettingModel";
import { UserBirthday } from "../models/users/UserBirthday";
import { UserAnniversary } from './../models/users/UserAnniversary';
import { UsersByRole } from "../models/users/UsersByRole";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private _apiUserUrl = ApiConstants.USER_API_ENDPOINT;

  constructor(private apiService: ApiService) { }

  getUserRoles(): Observable<UserRoleModel[]> {
    return this.apiService.get<UserRoleModel[]>(`${this._apiUserUrl}/roles`);
  }

  getUserRole(): Observable<UserRoleModel> {
    return this.apiService.get<UserRoleModel>(this._apiUserUrl + "/current-user-role");
  }

  getCurrentUser(): Observable<UserBriefModel> {
    return this.apiService.get<UserBriefModel>(`${this._apiUserUrl}/current-user`);
  }

  getCurrentUserDetails(): Observable<UserInfoModel> {
    return this.apiService.get<UserInfoModel>(`${this._apiUserUrl}/current-user-details`);
  }

  getUser(userId: number): Observable<UserModel> {
    return this.apiService.get<UserModel>(`${this._apiUserUrl}/${userId}`);
  }

  getUsers(): Observable<UserModel[]> {
    return this.apiService.get<UserModel[]>(`${this._apiUserUrl}`);
  }

  editUserDetails(model: UserInfoModel): Observable<UserInfoModel> {
    return this.apiService.put<UserInfoModel>(`${this._apiUserUrl}`, model);
  }

  editUser(model: UserModel): Observable<UserModel> {
    return this.apiService.put<UserModel>(`${this._apiUserUrl}/updateUser`, model);
  }

  getAdmins(): Observable<UserSettingModel[]> {
    return this.apiService.get<UserSettingModel[]>(`${this._apiUserUrl}/admins`)
  }

  archiveUsers(userId: number): Observable<UserModel> {
    return this.apiService.delete(`${this._apiUserUrl}/${userId}`);
  }

  getUpcomingUserBirthday(): Observable<UserBirthday[]> {
    return this.apiService.get(`${this._apiUserUrl}/upcomingBirthDays`);
  }

  getUpcomingUserAnniversaries(): Observable<UserAnniversary[]> {
    return this.apiService.get(`${this._apiUserUrl}/upcomingAnniversaries`);
  }

  getUsersByRole(): Observable<UsersByRole> {
    return this.apiService.get(`${this._apiUserUrl}/usersByRole`);
  }
}
