import { Component, OnInit } from "@angular/core";
import { finalize, takeUntil } from "rxjs/operators";
import { formatLocalTime } from "src/app/shared/utils/Formatters";
import { ReviewService } from "src/app/services/review.service";
import { ColorsConstants } from "src/app/constants/colors-constants";
import { ReviewModel } from "src/app/models/reviews/ReviewModel";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { dateComparator, isPastCurrentDate} from "src/app/shared/utils/Utils";
import { LocalStorageConstants } from "src/app/constants/local-storage-constants";

@Component({
  selector: "regular-user-reviews",
  templateUrl: "./regular-user-reviews.component.html",
  styleUrls: ["./regular-user-reviews.component.scss"],
})
export class RegularUserReviewsComponent extends GridComponentBase<ReviewModel>
  implements OnInit {
  formatDateForTable = formatLocalTime;
  constructor(private reviewService: ReviewService) {
    super();
    
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        valueGetter: (x) =>
          this.formatDateForTable(x.data.reviewDate),
        headerName: "Date",
        width: 150,
        suppressSizeToFit: false,
        sort:'desc',
        comparator: (_, __, nodeA, nodeB) => (dateComparator(nodeA.data.reviewDate, nodeB.data.reviewDate)),
      },
      {
        valueGetter: (x) =>
          x.data.nextReviewDate == null
            ? "N/A"
            : this.formatDateForTable(x.data.nextReviewDate),
        headerName: "Next Review Date",
        width: 150,
        suppressSizeToFit: false,
      },
      {
        field: "reviewType",
        headerName: "Type",
        width: 130,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => (x.data.reviewStatus ? x.data.reviewStatus : "N/A"),
        headerName: "Status",
        width: 130,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => (x.data.description ? x.data.description : "N/A"),
        headerName: "Description",
        width: 300,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.newDailyHours ? x.data.newDailyHours : "N/A"),
        headerName: "New Daily Hours",
        width: 140,
        suppressSizeToFit: true,
      },
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No review entries.</span>';
  }

  ngOnInit(): void {
    this.startLoader();
    this._loadReviewData();   
  }

  onRowSelected(): void { }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  isDeliveryManager(): boolean {
    let role = localStorage.getItem(LocalStorageConstants.IS_DELIVERY_MANAGER);
    if(role && role.toString() === "true"){
      return true;      
    }
    return false;
  }

  getRowStyle = (params: any) => {
    const reviewDate = params.data.reviewDate;
    if (isPastCurrentDate(reviewDate)) {
      return { background: ColorsConstants.LIGHT_RED};
    }
  };

  private _loadReviewData(): void {
    this.reviewService
      .getReviewsForUser()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => { this.stopLoader()}))
      .subscribe(
        (results) => {
          this.data = results;
        }
      );
  }
}