import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize, takeUntil } from "rxjs/operators";
import * as moment from "moment";

import { DialogService } from "src/app/shared/services/dialog.service";
import { clone, createDateComponent } from "src/app/shared/utils/Utils";
import { LoadableComponentBase } from "src/app/shared/utils/LoadableComponentBase";
import { formatLocalTime } from "src/app/shared/utils/Formatters";

import { UserService } from "src/app/services/user.service";

import { UserInfoModel } from "src/app/models/users/UserInfoModel";
import { Company } from "../../../models/companies/Company";
import { CompanyService } from "../../../services/company.service";
import { forkJoin } from "rxjs";
import { PatternConstants } from "src/app/constants/pattern-constants";
import { ChildrenModel } from "src/app/models/users/ChildrenModel";

@Component({
  selector: "trackify-user-general-details",
  templateUrl: "./user-general-details.component.html",
  styleUrls: ["./user-general-details.component.scss"],
})
export class UserGeneralDetailsComponent extends LoadableComponentBase implements OnInit {

  user: UserInfoModel;
  userClone: UserInfoModel;
  minDate = moment.utc("2014-12-01").toDate();
  isModalButtonEnabled = true;
  formatEmploymentDate = formatLocalTime;
  childrenLeftSide: ChildrenModel[];
  childrenRightSide: ChildrenModel[];

  phonePattern = PatternConstants.PHONE_NUMBER_PATTERN;
  emailPattern = PatternConstants.EMAIL_PATTERN;
  namePattern = PatternConstants.NAME_PATTERN;
  cnpPattern = PatternConstants.CNP_PATTERN;
  positionPattern = PatternConstants.POSITION_PATTERN;
  numberPattern = PatternConstants.OPTIONAL_NUMBER_PATTERN;
  idCardNumberPattern = PatternConstants.ID_CARD_NUMBER_PATTERN;
  missingDataForChild: string[] = [];
  companies: Company[] = []
  errorMessage: string;
  @ViewChild("detailsModal", { static: true }) modal: ModalDirective;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit(): void {
    this._getUser();
  }

  private _getUser() {
    this.startLoader();
    const userId = this.route.snapshot.params.id;
    forkJoin([
      this.companyService.getCompanies(),
      this.userService.getUser(userId)
    ]).pipe(takeUntil(this.ngUnsubscribe),
      finalize(() => {
        this.stopLoader();
      }))
      .subscribe(([companies, user]) => {
        this.companies = companies;
        this.user = user;
        this._initializeChildrenSides();
      });
  }

  public openEditModal() {
    this.userClone = clone(this.user);
    this.modal.show();
  }

  public closeEditModal() {
    this.missingDataForChild = [];
    this.modal.hide();
  }

  getCompany(companyId: number) {
    return companyId ? this.companies.find(c => c.id == companyId).fullName : 'N/A';
  }

  saveChanges() {
    if (this._validateChildren()) {
      return;
    }

    this.isModalButtonEnabled = false;
    this.errorMessage = "";

    this.userClone.birthdayDateComponent = createDateComponent(this.userClone.birthday);
    this.userClone.idCardExpirationDateComponent = createDateComponent(this.userClone.idCardExpirationDate);
    this.userClone.medicalExaminationDateComponent = createDateComponent(this.userClone.medicalExaminationDate);
    this.userClone.employmentDateComponent = createDateComponent(this.userClone.employmentDate);
    this.userClone.leavingDateComponent = createDateComponent(this.userClone.leavingDate);
    if (!this._isUserValid()) {
      this.isModalButtonEnabled = true;
      return;
    }

    this.userService
      .editUserDetails(this.userClone)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result) => {
          this.user = result;
          this._initializeChildrenSides();
          this.closeEditModal();
          this.userClone = null;
          this.dialogService.showSimpleDialog(
            "Success",
            "Details were updated successfully",
            "success"
          );
          this.isModalButtonEnabled = true;
        },
        (err) => {
          this.closeEditModal();
          this.userClone = null;
          this.dialogService.showSimpleDialog("Error!", err, "error");
          this.isModalButtonEnabled = true;
        }
      );
  }

  increaseCurrentDateWithYears(yearsToAdd: number): string {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() + yearsToAdd);
    return currentDate.toDateString();
  }

  private _isUserValid(): boolean {
    if (!this.userClone.employmentDate) {
      this.errorMessage = "Missing or invalid Employment Date\n";
      return false;
    }
    return true;
  }

  private _initializeChildrenSides() {
    if (this.user) {
      this.childrenLeftSide = this.user.children.filter((_, index) => { return (index % 2 === 0) });
      this.childrenRightSide = this.user.children.filter((_, index) => { return (index % 2 != 0) });
    }
  }

  private _validateChildren(): boolean {
    let existsMissingData = false;
    this.missingDataForChild = [];
    this.userClone.children.forEach((value, index) => {
      if (!Object.keys(value).length || Object.keys(value).length < 2) {
        this.missingDataForChild.push(index.toString());
        existsMissingData = true;
      }
    });
    return existsMissingData;
  }
}
