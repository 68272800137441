import { NgModule } from "@angular/core";
import { TypeaheadConfig, TypeaheadModule } from "ngx-bootstrap/typeahead";
import { TooltipModule, TooltipConfig } from 'ngx-bootstrap/tooltip';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
  BsDaterangepickerConfig,
} from "ngx-bootstrap/datepicker";
import { TabsetConfig, TabsModule } from "ngx-bootstrap/tabs";
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { NumberValidatoPipe } from "./pipes/numberValidator.pipe";
import { MinValueValidator } from "./utils/MinValueValidator";
import { MaxValueValidator } from "./utils/MaxValueValidator";
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ButtonCellRendererComponent } from './components/ag-grid/button-cell-renderer/button-cell-renderer.component';
import { SetFilterComponent } from './components/ag-grid/set-filter/set-filter.component';
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { ChildrenEditDetailsComponent } from "./components/children-edit-details/children-edit-details.component";
import { RevertBenefitPointLogRendererComponent } from "./components/ag-grid/revert-benefit-point-log-renderer/revert-benefit-point-log-renderer.component";
import { AssignReviewersCellRendererComponent } from "./components/ag-grid/assign-reviewers-cell-renderer/assign-reviewers-cell-renderer.component";


@NgModule({
 declarations: [
    NumberValidatoPipe,
    MinValueValidator,
    MaxValueValidator,
    DatePickerComponent,
    ButtonCellRendererComponent,
    RevertBenefitPointLogRendererComponent,
    SetFilterComponent,
    ChildrenEditDetailsComponent,
    AssignReviewersCellRendererComponent,
    // ContextMenuCellRendererComponent,   
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    AngularMultiSelectModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  exports: [
    BrowserAnimationsModule,
    ModalModule,
    TypeaheadModule,
    BsDatepickerModule,
    TabsModule,
    BsDropdownModule,
    TooltipModule,
    AngularMultiSelectModule, 

    NumberValidatoPipe,
    MinValueValidator,
    MaxValueValidator,

    ButtonCellRendererComponent,
    RevertBenefitPointLogRendererComponent,
    AssignReviewersCellRendererComponent,

    DatePickerComponent,
    ChildrenEditDetailsComponent
  ],
  providers: [
    BsDatepickerConfig, 
    BsDaterangepickerConfig, 
    TabsetConfig, TabsetConfig, 
    BsDropdownConfig, 
    TypeaheadConfig, 
    TooltipConfig, 
    BsModalService]
})
export class SharedModule { }
