import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';

interface ISetFilterParams extends IFilterParams {
  displayField: string;
  blankValueLabel: string;
}

@Component({
  selector: 'trackify-set-filter',
  templateUrl: './set-filter.component.html',
  styleUrls: ['./set-filter.component.scss']
})
export class SetFilterComponent implements OnInit, IFilterAngularComp {

  constructor(private changeDetector: ChangeDetectorRef) { }

  private params: ISetFilterParams;

  public dataSet: any[];
  public selectedItems: any[];
  private valueGetter: (rowNode: RowNode) => any;
  public text: string = '';

  multiSelectSettings = {
    badgeShowLimit: 5,
    enableCheckAll: true,
    enableSearchFilter: true,
    enableFilterSelectAll: true,
  };

  ngOnInit() {
  }

  agInit(params: ISetFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  afterGuiAttached?(_?: { hidePopup?: Function }): void {
    this._initData();
  }

  private _initData() {
    this.dataSet = [];
    this.params.rowModel.forEachNode(r => {
      // Discard any rows already filtered out through other filters
      if (!this.params.doesRowPassOtherFilter(r)) return;

      const val = this._displayValueGetter(this.valueGetter(r));
      const result = {
        id: val,
        itemName: val
      };
      if (!!this.dataSet.find(e => e.id === val)) {
        return;
      }
      this.dataSet.push(result);
    });
    this.dataSet.sort(this._compareData);
    this.changeDetector.detectChanges();
  }

  private _compareData(a: any, b: any): number {
    if (!!a.itemName && !!b.itemName) return a.itemName > b.itemName ? 1 : (a.itemName < b.itemName ? - 1 : 0);
    // Scenario for either or both values being empty
    return !a.itemName ? 1 : (!b.nitemNameame ? -1 : 0);
  }

  private _displayValueGetter(value) {
    if (!value) {
      return !!this.params.blankValueLabel ? this.params.blankValueLabel : value;
    }
    if (!this.params || !this.params.displayField) {
      return value;
    }
    return value[this.params.displayField];
  }

  isFilterActive(): boolean {
    return this.selectedItems && !!this.selectedItems.length;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const value = this.valueGetter(params.node);
    return !!this.selectedItems ? this.selectedItems.some(item => item.itemName === this._displayValueGetter(value)) : true;
  }

  getModel() {
    return { selectedItems: this.selectedItems };
  }

  setModel(model: any): void {
    this.selectedItems = !!model ? model.selectedItems : undefined;
  }

  onSelectedItemsChange(_) {
    this.params.filterChangedCallback();
    this.changeDetector.detectChanges();
  }
}
