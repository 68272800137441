import { HolidayApprovalComponent } from './components/holiday-request/holiday-approval/holiday-approval.component';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";



import { LayoutComponent } from "./components/layout/layout/layout.component";
import { MonthDetailsComponent } from "./components/month-details/month-details.component";
import { NotFoundPageComponent } from "./components/not-found-page/not-found-page.component";
import { SignInPageComponent } from "./components/sign-in-page/sign-in-page.component";
import { UserDashboardComponent } from "./components/dashboard/user-dashboard/user-dashboard.component";
import { UserDetailsComponent } from "./components/user-details/user-details.component";
import { UsersComponent } from "./components/users/users.component";
import { MonthlyStatusComponent } from "./components/monthly-status/monthly-status.component";
import { UserHolidayRequestComponent } from "./components/holiday-request/user-holiday-request/user-holiday-request.component";
import { AdminDashboardComponent } from "./components/dashboard/admin-dashboard/admin-dashboard.component";
import { ThankYouPageComponent } from "./components/thank-you-page/thank-you-page.component";
import { UnauthorizedPageComponent } from "./components/unauthorized-page/unauthorized-page.component";
import { RegularUserReviewsComponent } from "./components/user-details/regular-user-reviews/regular-user-reviews.component";
import { DailyStatsComponent } from "./components/daily-stats/daily-stats.component";

import { HomeComponent } from "./components/home/home.component";
import { UserMonthlyStatusComponent } from "./components/monthly-status/user-monthly-status/user-monthly-status/user-monthly-status.component";
import { SettingsTabsComponent } from "./components/settings-tabs/settings-tabs.component";
import { MyDetailsComponent } from "./components/my-details/my-details.component";
import { CompaniesComponent } from "./components/companies/companies.component";
import { PlannerComponent } from "./components/companies/planner/planner.component";
import { HolidayStatsComponent } from "./components/holiday-stats/holiday-stats.component";
import { HolidayChartsComponent } from "./components/holiday-stats/holiday-charts/holiday-charts.component";
import { ProjectComponent } from './components/project/project.component';
import { ClientsComponent } from './components/clients/clients.component';
import { AdminBenefitsComponent } from './components/admin-benefits/admin-benefits.component';
import { UserBenefitsComponent } from './components/user-benefits/user-benefits.component';
import { ClientsDetailsComponent } from './components/clients/clients-details/clients-details.component';
import { RoleType } from './models/users/RoleType';
import { BenefitApprovalComponent } from './components/admin-benefits-requests/benefit-approval/benefit-approval.component';
import { SortableHolidayComponent } from './components/holiday-request/sortable-holiday/sortable-holiday.component';
import { AuthGuard } from './AuthGuard/auth.guard';
import { ConfirmationAuthGuard } from './AuthGuard/confirmation.auth.guard';
import { ReviewFormPageComponent } from './components/review-form-page/review-form-page.component';

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "dashboard-administrator",
        component: AdminDashboardComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "users",
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "companies",
        component: CompaniesComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "monthly-stats",
        component: MonthlyStatusComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "month-details",
        component: MonthDetailsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "daily-stats",
        component: DailyStatsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "user-details/:id",
        component: UserDetailsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "dashboard-user",
        component: UserDashboardComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Regular },
      },
      {
        path: "regular-user-reviews",
        component: RegularUserReviewsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Regular },
      },
      {
        path: "holidays",
        component: UserHolidayRequestComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Regular },
      },
      {
        path: "sortable-holiday",
        component: SortableHolidayComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "settings",
        component: SettingsTabsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "user-monthly-stats",
        component: UserMonthlyStatusComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Regular },

      },
      {
        path: "planner",
        component: PlannerComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "benefits",
        component: UserBenefitsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Regular },
      },
      {
        path: "monthly-planning",
        component: PlannerComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Regular },
      },
      {
        path: "holiday-stats",
        component: HolidayStatsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "admin-benefits",
        component: AdminBenefitsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "holiday-charts/:id",
        component: HolidayChartsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "user-holiday-charts",
        component: HolidayChartsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Regular },
      },
      {
        path: "clients",
        component: ClientsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Admin },
      },
      {
        path: "client-details/:id",
        component: ClientsDetailsComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Admin },
      },
      {
        path: "my-details",
        component: MyDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "projects",
        component: ProjectComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Hr },
      },
      {
        path: "",
        component: HomeComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
      },
    ],
  },
  { path: "sign-in", component: SignInPageComponent },
  {
    path: "thank-you-page/:token",
    component: ThankYouPageComponent,
    canActivate: [ConfirmationAuthGuard]
  },
  {
    path: "holiday-approval/:id",
    component: HolidayApprovalComponent,
    canActivate: [ConfirmationAuthGuard]
  },
  {
    path: "benefit-approval/:id",
    component: BenefitApprovalComponent,
    canActivate: [ConfirmationAuthGuard]
  },
  {
    path: "perform-review/:token",
    component: ReviewFormPageComponent,
    canActivate: [ConfirmationAuthGuard],
  },
  { path: "unauthorized-page", component: UnauthorizedPageComponent },
  { path: "**", component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
