import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize, takeUntil } from 'rxjs/operators';
import { HolidayRequestTypeModel } from 'src/app/models/holiday-request/HolidayRequestTypeModel';
import { HolidayType } from 'src/app/models/holiday-type/HolidayType';
import { HolidayRequestTypeService } from 'src/app/services/holiday-request/holiday-request-type.service';
import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils';
import { clone } from 'src/app/shared/utils/Utils';

@Component({
  selector: 'trackify-admin-holiday-type',
  templateUrl: './admin-holiday-type.component.html',
  styleUrls: ['./admin-holiday-type.component.scss']
})
export class AdminHolidayTypeComponent extends GridComponentBase<HolidayRequestTypeModel>implements OnInit {

  @Input() isInTab: boolean = false;
  constructor(
    private holidayRequestTypeService: HolidayRequestTypeService,
    private dialogService: DialogService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      { 
        field: 'name', 
        headerName: 'Name', 
        width: 150, 
        sort: 'asc', 
        suppressSizeToFit: false
      },
      {
        field: 'isBillable', 
        headerName: 'Billable', 
        width: 150,  
        suppressSizeToFit: false
      }
    ]);

    this.gridOptions.overlayNoRowsTemplate = '<span class="ag-overlay-no-rows-center">No holiday type entries.</span>';
  }

  @ViewChild('holidayTypeModal', { static: false }) modal: ModalDirective;
  errorMessage: string;
  isAddMode: boolean;
  currentSelectionNotSpecialType: number = null;

  ngOnInit() {
    this.currentSelectionNotSpecialType = null;
    this.startLoader();
    this.holidayRequestTypeService.getAll()
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
      .subscribe(types => {
        this.data = types;
      });
  }

  onAdd() {
    this.updatedItem = new HolidayRequestTypeModel();
    this.isAddMode = true
    this.modal.toggle();
  }

  onEdit() {
    this.updatedItem = clone(this.currentSelection);
    this.isAddMode = false;
    this.modal.toggle();
  }

  onDelete() {
    this.dialogService.showDialog({
      title: `Are you sure you want to delete the ${this.currentSelection.name}?`,
      text: 'Selected Holiday Type will be completely removed and cannot be recovered.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DC3545',
      confirmButtonText: 'Delete'
    }).then(result => {
      if (!!result.value) {
        this.dialogService.showDialog({
          title: 'Processing',
          text: 'Deleting selected Holiday Type',
          allowOutsideClick: false,
          onOpen: () => {
            this.dialogService.showLoading();
          }
        });
        this.gridOptions.api.showLoadingOverlay();
        this.holidayRequestTypeService.deleteHolidayRequestType(this.currentSelection.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            this.updateGridAfterDelete();

            this.currentSelection = null;

            this.dialogService.showSuccessMessage('Success!', 'Selected Holiday Type deleted successfully.');
          });
      }
    });
  }

  saveChanges() {
    if (!this.isModelValid()) return;

    if (this.isAddMode) {
      this.gridOptions.api.showLoadingOverlay()
      this.holidayRequestTypeService.addHolidayRequestType(this.updatedItem)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(type => {
          this.data.push(type);
          this.updateGridAfterAdd(type);
          this.gridOptions.api.hideOverlay();
          this.gridOptions.api.deselectAll();
          this.currentSelection = undefined;
          this.currentSelectionNotSpecialType = null;

          this.dialogService.showSuccessMessage('Success!', 'New type of holiday added successfully.');
          this.modal.hide();
        });
    }
    else {
      this.gridOptions.api.showLoadingOverlay()
      this.holidayRequestTypeService.editHolidayRequestType(this.updatedItem)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(type => {
          this.data[this.currentSelectionIdx] = type;
          this.updateGridAfterEdit(type);
          this.gridOptions.api.hideOverlay();
          this.currentSelection = clone(type);

          this.dialogService.showSuccessMessage('Success!', 'Holiday Type details updated successfully.');
          this.modal.hide();
        });
    }
  }

  validateModel(prop: string): string { return prop; }

  isModelValid(): boolean {
    this.errorMessage = '';

    if (!this.updatedItem) {
      this.errorMessage = 'Invalid model, please close the modal and try again!';
      return false;
    }

    if (!this.updatedItem.name) this.errorMessage += 'Name is required.\n';

    return !this.errorMessage;
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;

    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(u => u.id == this.currentSelection.id));
    if(!(this.currentSelection.name == HolidayType.Medical) && !(this.currentSelection.name == HolidayType.Paid) && !(this.currentSelection.name == HolidayType.NotPaid)){
      this.currentSelectionNotSpecialType = 1;
    }
    else {
      this.currentSelectionNotSpecialType = null;
    }
  }

  onRowDoubleClicked(_: any): void {
    this.onEdit();
  }
}
