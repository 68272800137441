import { HolidayApprovalComponent } from './components/holiday-request/holiday-approval/holiday-approval.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { SharedModule } from "./shared/shared-module.module";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { HomeComponent } from "./components/home/home.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { SidenavComponent } from "./components/layout/sidenav/sidenav.component";
import { LayoutComponent } from "./components/layout/layout/layout.component";
import { MonthDetailsComponent } from "./components/month-details/month-details.component";
import { SettingsComponent } from "./components/settings/settings.component";

import { UserDetailsComponent } from "./components/user-details/user-details.component";
import { UserGeneralDetailsComponent } from "./components/user-details/user-general-details/user-general-details.component";
import { UserMonthlyStatsComponent } from "./components/user-details/user-monthly-stats/user-monthly-stats.component";
import { AdminDashboardComponent } from "./components/dashboard/admin-dashboard/admin-dashboard.component"
import { HolidayRequestComponent } from "./components/holiday-request/holiday-request.component";
import { UsersComponent } from "./components/users/users.component";

import { UserDashboardComponent } from './components/dashboard/user-dashboard/user-dashboard.component';
import { SignInPageComponent } from './components/sign-in-page/sign-in-page.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';


import { MonthlyStatusComponent } from './components/monthly-status/monthly-status.component';
import { UserReviewsComponent } from './components/user-details/user-reviews/user-reviews.component';
import { LoaderComponent } from "./components/layout/loader/loader.component";
import { RegularUserReviewsComponent } from "./components/user-details/regular-user-reviews/regular-user-reviews.component";
import { UserHolidayRequestComponent } from './components/holiday-request/user-holiday-request/user-holiday-request.component';
import { ThankYouPageComponent } from "./components/thank-you-page/thank-you-page.component";
import { UnauthorizedPageComponent } from "./components/unauthorized-page/unauthorized-page.component";
import { UsefullLinksComponent } from './components/usefull-links/usefull-links.component';
import { UserMonthlyStatusComponent } from './components/monthly-status/user-monthly-status/user-monthly-status/user-monthly-status.component';
import { DailyStatsComponent } from './components/daily-stats/daily-stats.component';
import { MyDetailsComponent } from "./components/my-details/my-details.component";
import { SettingsTabsComponent } from "./components/settings-tabs/settings-tabs.component";
import { SettingUsefulLinksComponent } from "./components/setting-useful-links/setting-useful-links.component";
import { AgGridModule } from "ag-grid-angular";
import { GridComponentBase } from "./shared/components/base/GridComponentBase";
import { ButtonCellRendererComponent } from "./shared/components/ag-grid/button-cell-renderer/button-cell-renderer.component";
import { SetFilterComponent } from "./shared/components/ag-grid/set-filter/set-filter.component";
import { CompaniesComponent } from "./components/companies/companies.component";
import { PlannerComponent } from "./components/companies/planner/planner.component";
import { ContextMenuCellRendererComponent } from "./shared/components/ag-grid/context-menu-cell-renderer/context-menu-cell-renderer.component";
import { NextReviewComponent } from './components/next-review/next-review.component';
import { NextBirthdayComponent } from './components/next-birthday/next-birthday.component';
import { WorkDetailsComponent } from './components/my-details/work-details/work-details.component';
import { PersonalDetailsComponent } from './components/my-details/personal-details/personal-details.component';
import { EmergencyDetailsComponent } from './components/my-details/emergency-details/emergency-details.component';
import { HolidayStatsComponent } from './components/holiday-stats/holiday-stats.component';
import { HolidayChartsComponent } from './components/holiday-stats/holiday-charts/holiday-charts.component';
import { NextAnniversariesComponent } from './components/next-anniversaries/next-anniversaries.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { ProjectComponent } from './components/project/project.component';
import { ClientsComponent } from './components/clients/clients.component';
import { MonthYearPickerComponent } from './shared/components/utils/month-year-picker/month-year-picker.component';
import { UserBenefitsComponent } from './components/user-benefits/user-benefits.component';
import { CurrentUserBenefitsLogComponent } from './components/user-benefits/current-user-benefits-log/current-user-benefits-log.component';
import { AdminBenefitsCancellingRequestComponent } from './components/admin-benefits-cancelling-request/admin-benefits-cancelling-request.component'
import { AdminBenefitsComponent } from './components/admin-benefits/admin-benefits.component';
import { AdminBenefitsManagementComponent } from './components/admin-benefits-management/admin-benefits-management.component';
import { AdminBenefitsRequestsComponent } from './components/admin-benefits-requests/admin-benefits-requests.component';
import { ClientsDetailsComponent } from './components/clients/clients-details/clients-details.component';
import { CurrentUserConfigureBenefitsComponent } from './components/user-benefits/current-user-configure-benefits/current-user-configure-benefits.component';
import { BenefitCardComponent } from './components/user-benefits/benefit-card/benefit-card.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AdminBenefitsMonthlyLogsComponent } from './components/admin-benefits-monthly-logs-request/admin-benefits-monthly-logs.component';
import { AdminHolidayTypeComponent } from './components/holiday-request/admin-holiday-type/admin-holiday-type.component';
import { ChildrenDetailsComponent } from './components/my-details/children-details/children-details.component';
import { BenefitApprovalComponent } from './components/admin-benefits-requests/benefit-approval/benefit-approval.component';
import { SortableHolidayComponent } from './components/holiday-request/sortable-holiday/sortable-holiday.component';
import { ReviewFormPageComponent } from './components/review-form-page/review-form-page.component';
import { RevertBenefitPointLogRendererComponent } from './shared/components/ag-grid/revert-benefit-point-log-renderer/revert-benefit-point-log-renderer.component';
import { ReviewStatusModalComponent } from './components/review-status-modal/review-status-modal.component';
import { RecurrentModalComponent } from './components/user-benefits/benefit-card/modals/recurrent-modal/recurrent-modal.component';
import { EventModalComponent } from './components/user-benefits/benefit-card/modals/event-modal/event-modal.component';
import { EventDetailsModalComponent } from './components/user-benefits/benefit-card/modals/event-details-modal/event-details-modal.component';
import { OneTimeModalComponent } from './components/user-benefits/benefit-card/modals/one-time-modal/one-time-modal.component';
import { ReviewAssignModalComponent } from './components/review-assign-modal/review-assign-modal.component';
import { AssignReviewersCellRendererComponent } from './shared/components/ag-grid/assign-reviewers-cell-renderer/assign-reviewers-cell-renderer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SidenavComponent,
    LayoutComponent,
    MonthDetailsComponent,
    SettingsComponent,
    UserDetailsComponent,
    UserGeneralDetailsComponent,
    UserDetailsComponent,
    UserMonthlyStatsComponent,
    HeaderComponent,
    UserDashboardComponent,
    UserDashboardComponent,
    SignInPageComponent,
    NotFoundPageComponent,
    UserReviewsComponent,
    MonthlyStatusComponent,
    AdminDashboardComponent,
    HolidayRequestComponent,
    UsersComponent,
    CompaniesComponent,
    LoaderComponent,
    RegularUserReviewsComponent,
    ThankYouPageComponent,
    UnauthorizedPageComponent,
    UserHolidayRequestComponent,
    UsefullLinksComponent,
    UserMonthlyStatusComponent,
    DailyStatsComponent,
    MyDetailsComponent,
    SettingsTabsComponent,
    SettingUsefulLinksComponent,
    DailyStatsComponent,
    PlannerComponent,
    ContextMenuCellRendererComponent,
    NextReviewComponent,
    NextBirthdayComponent,
    WorkDetailsComponent,
    PersonalDetailsComponent,
    EmergencyDetailsComponent,
    NextAnniversariesComponent,
    ChildrenDetailsComponent,
    HolidayStatsComponent,
    HolidayChartsComponent,
    PieChartComponent,
    BarChartComponent,
    HolidayApprovalComponent,
    ProjectComponent,
    ClientsComponent,
    AdminBenefitsCancellingRequestComponent,
    ReviewFormPageComponent,

    ClientsDetailsComponent,
    MonthYearPickerComponent,
    UserBenefitsComponent,
    AdminBenefitsComponent,
    AdminBenefitsManagementComponent,
    UserBenefitsComponent,
    CurrentUserBenefitsLogComponent,
    AdminBenefitsRequestsComponent,
    CurrentUserConfigureBenefitsComponent,
    BenefitCardComponent,
    AdminBenefitsMonthlyLogsComponent,
    AdminHolidayTypeComponent,
    SortableHolidayComponent,
    BenefitApprovalComponent,
    ReviewStatusModalComponent,
    ReviewAssignModalComponent,
    RecurrentModalComponent,
    EventModalComponent,
    EventDetailsModalComponent,
    OneTimeModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedModule,
    NgApexchartsModule,
    AgGridModule.withComponents([
      GridComponentBase,
      SetFilterComponent,
      ButtonCellRendererComponent,
      RevertBenefitPointLogRendererComponent,
      ContextMenuCellRendererComponent,
      AssignReviewersCellRendererComponent
    ]),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    ReviewStatusModalComponent,
    ReviewAssignModalComponent,
    RecurrentModalComponent,
    EventModalComponent,
    EventDetailsModalComponent,
    OneTimeModalComponent
  ]
})
export class AppModule { }
