import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MomentConstants } from 'src/app/constants/moment-constants';
import { PlannerModel } from 'src/app/models/planner/PlannerModel';
import { ProjectModel } from 'src/app/models/projectModels/ProjectModel';
import { SetPlannerProjectModel } from 'src/app/models/planner/SetPlannerProjectModel';
import { WeeklyPlannerDetailedModel } from 'src/app/models/planner/WeeklyPlannerDetailedModel';
import { RoleType } from 'src/app/models/users/RoleType';import { UserRoleModel } from 'src/app/models/users/UserRoleModel';
import { PlannerService } from 'src/app/services/planner.service';
import { UserService } from 'src/app/services/user.service';
import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils';
import { clone } from 'src/app/shared/utils/Utils';

@Component({
  selector: 'trackify-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss']
})
export class PlannerComponent extends GridComponentBase<WeeklyPlannerDetailedModel> implements OnInit {
  projects: ProjectModel[];
  plannerData: PlannerModel;
  selectedProject: ProjectModel;
  customProjectValue: string;
  params: any;

  currentUserRole: UserRoleModel;

  @ViewChild("projectsModal", { static: true }) modal: ModalDirective;

  constructor(
    private plannerService: PlannerService,
    private dialogService: DialogService,
    private userService: UserService) {
    super();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addUsernames();

    const date = new Date();
    const lastDay = this.getMonday(date);
    const numberOfDaysToAdd = 25;
    lastDay.setDate(lastDay.getDate() + numberOfDaysToAdd);
    
    this.gridOptions.suppressRowTransform = true;

    for (let day = this.getMonday(date); day <= lastDay; day.setDate(day.getDate() + 1)) {
      const date = moment(day).format(MomentConstants.YEAR_MONTH_DAY);

      if (this.getWeekend(day)) {
        this.columnDefs.push({
          field: date,
          headerName: moment(new Date(date)).format(MomentConstants.DAY_MONTH),
          suppressSizeToFit: true,
          width: 45,
          cellRenderer: "contextMenuCellRenderer",
          cellStyle: function () {
            return { overflow: 'visible', padding: "0" };
          },
        });
      } else {
        this.columnDefs.push({
          field: date,
          minWidth: 180,
          headerName: moment(new Date(date)).format(MomentConstants.DAY_MONTH),
          suppressSizeToFit: true,
          width: 180,

          cellRendererParams: {
            currentDate: date,
            isContextMenuDisplayed: false,
            onContextMenuStateChanged: this._onContextMenuStateChanged.bind(this),
            shouldDisplayContextMenuTop: this._shouldDisplayContextMenuTop.bind(this),
            onOpenModal: this._onOpenModal.bind(this),
            onCopyPastDayClick: this._onCopyPastDayWeek.bind(this),
            onCopyPastWeekClick: this._onCopyPastWeekClick.bind(this),
          },

          cellStyle: function () {
            return { overflow: 'visible', padding: "0" };
          },
          cellRenderer: "contextMenuCellRenderer"
        });
      }
    }

    if (!this.gridOptions.api) return;
    this.gridOptions.api.setColumnDefs(this.columnDefs);
    this.gridOptions.overlayNoRowsTemplate = '<span class="ag-overlay-no-rows-center">No planner entries.</span>';
  }

  private _onContextMenuStateChanged(plannerId: number) {
    for (let planner of this.data) {
      for (let dailyPlanner of planner.weeklyPlanners) {
        if (dailyPlanner.id != plannerId) {
          dailyPlanner.showContextMenu = false;
        }
      }
    }
  }

  private _onOpenModal(selectedCell: PlannerModel) {
    this.modal.show();
    this.plannerData = selectedCell;
  }

  private _shouldDisplayContextMenuTop(rowNode) {
    if (rowNode.rowIndex >= this.data.length / 2) {
      return true;
    }
    return false;
  }

  private _onCopyPastDayWeek(selectedCell: PlannerModel) {
    this.copyPlannerFromPastDay(selectedCell);
  }

  private _onCopyPastWeekClick(selectedCell: PlannerModel) {
    this.copyPlannerFromPastWeek(selectedCell);
  }

  ngOnInit(): void {
    this.startLoader();

    forkJoin([
      this.plannerService.getAllProjects(),
      this.userService.getUserRole()
    ])
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe(([plannerProjects, role]) => {

        const isAdminOrHr = role.id == (RoleType.Admin || RoleType.Hr);

        let plannerRequest$ = isAdminOrHr
          ? this.plannerService.getPlannerForOneMonth()
          : this.plannerService.readPlannerForOneMonth();

        plannerRequest$
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(plannerData => {
            this._setPlannerData(plannerData, isAdminOrHr);
          });

        this.projects = plannerProjects;
      });
  }

  private _setPlannerData(plannerData: any, isAdminOrHr: boolean) {
    plannerData.forEach(dataItem => {
      dataItem.weeklyPlanners.forEach(wPlanner => {
        dataItem[moment(new Date(wPlanner.date)).format(MomentConstants.YEAR_MONTH_DAY)] = wPlanner.project.name;
        wPlanner.editablePlannerModel = isAdminOrHr;
      });
    });

    this.data = plannerData;
  }

  addUsernames() {
    this.addColumnDefs([
      {
        field: 'userFullName',
        headerName: 'Employees',
        suppressSizeToFit: true,
        pinned: true,
        width: 150,
        sort: 'asc'
      },
    ])
  }

  copyPlannerFromPastDay(planner: PlannerModel) {
    this.plannerService.copyProjectFromPastDay(planner.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(pastDayPlanner => {

        if (!pastDayPlanner.project.name && !pastDayPlanner.project.custom) {
          return this.dialogService.showDialog("No project assigned on last week this day")
        }

        let index = this.data[this.currentSelectionIdx].weeklyPlanners.findIndex(x => x.id == pastDayPlanner.id);
        this.data[this.currentSelectionIdx].weeklyPlanners[index] = pastDayPlanner
        this.gridOptions.api.redrawRows({ rowNodes: this.gridOptions.api.getSelectedNodes() });
        this.updateGridAfterEdit(this.data[this.currentSelectionIdx]);
        this.gridOptions.api.hideOverlay();
        this.dialogService.showSimpleDialog('Succes!', 'This day from past week copied successfully', 'success');

      }, err => {
        this.dialogService.showSimpleDialog('Error', err);
        this.gridOptions.api.hideOverlay();
      })
  }

  plannerDataRowMap(lastWeekPlanner: PlannerModel[]) {
    let map = new Map<number, PlannerModel>();
    lastWeekPlanner.forEach(wp => map.set(wp.id, wp));
    return map;
  }

  copyPlannerFromPastWeek(weekPlanner: PlannerModel) {
    this.gridOptions.api.showLoadingOverlay();
    this.plannerService.copyProjectFromPastWeek(weekPlanner.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((lastWeekPlanner) => {
        const rowMap = this.plannerDataRowMap(lastWeekPlanner);
        this.data[this.currentSelectionIdx].weeklyPlanners.forEach(plannerData => {
          const rowCellValue = rowMap.get(plannerData.id);
          if (rowCellValue) {
            plannerData.id = rowCellValue.id;
            plannerData.correspondingWeek = rowCellValue.correspondingWeek;
            plannerData.date = rowCellValue.date;
            plannerData.project.name = rowCellValue.project.name;
            plannerData.project.color = rowCellValue.project.color;
            plannerData.project.custom = rowCellValue.project.custom;
            plannerData.showContextMenu = rowCellValue.showContextMenu;
          }
        })
        this.updateGridAfterEdit(this.data[this.currentSelectionIdx]);
        this.gridOptions.api.hideOverlay();
        this.dialogService.showSimpleDialog('Succes!', 'Past week planner copied successfully', 'success');
      }, (_) => {
        this.dialogService.showSimpleDialog('Error!', "Something went wrong", 'error');
        this.gridOptions.api.hideOverlay();
      })
  }

  isProjectNameValid() {
    if (this.customProjectValue == null && this.selectedProject == null) {
      this.dialogService.showWarningMessage("No project selected or custom project introduced", "");
      return false;
    }

    if (this.customProjectValue) {
      if (!this.customProjectValue.replace(/\s/g, '').length) {
        this.dialogService.showErrorMessage("Please enter a value that is not empty", "");
        this.customProjectValue = null;
        return false;
      }
    }
    return true;
  }

  isValidSelectedOption() {
    if (this.selectedProject != null && this.customProjectValue != null) {
      this.dialogService.showWarningMessage("Please choose between selected project or custom project, not both", "");
      this.selectedProject = null;
      this.customProjectValue = null;
      return false;
    }
    return true;
  }

  setProject() {
    this.gridOptions.api.showLoadingOverlay();

    if (!this.isProjectNameValid()) {
      this.gridOptions.api.hideOverlay();
      return;
    }

    if (!this.isValidSelectedOption()) {
      this.gridOptions.api.hideOverlay();
      return;
    }

    let project: SetPlannerProjectModel = new SetPlannerProjectModel();
    if (this.selectedProject) {
      project.projectId = this.selectedProject.id;
      project.plannerId = this.plannerData.id;
      project.customPlan = null;
    }

    if (this.customProjectValue) {
      let id: number;
      project.plannerId = this.plannerData.id;
      project.customPlan = this.customProjectValue;
      project.projectId = id;
    }

    this.plannerService.setProjectForPlanner(project)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((_) => {
        let foundCell = this.data[this.currentSelectionIdx].weeklyPlanners.find(x => x.id == this.plannerData.id);
        if (this.customProjectValue) {
          foundCell.project.name = null;
          foundCell.project.custom = this.customProjectValue;
        }

        if (this.selectedProject) {
          let changedProject = this.projects.find(x => x.id == this.selectedProject.id);
          foundCell.project.custom = null;
          foundCell.project.name = changedProject.name;
          foundCell.project.color = changedProject.color;
        }

        this.updateGridAfterEdit(this.data[this.currentSelectionIdx]);
        this.plannerData.showContextMenu = !this.plannerData.showContextMenu;
        this.gridOptions.api.hideOverlay();
        this.dialogService.showSimpleDialog('Succes!', 'Project edited successfully', 'success');
        this.modal.hide();
        this.customProjectValue = null;
        this.selectedProject = null;
      }, (_) => {
        this.dialogService.showSimpleDialog('Error!', "Something went wrong", 'error');
        this.modal.hide();
        this.gridOptions.api.hideOverlay();
      })
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;
    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find((u) => u.userFullName == this.currentSelection.userFullName));
  }

  getMonday(inputDate: Date): Date {
    const date = new Date(inputDate);
    const day = date.getDay(),
    // Calculate the difference between the current date and the Monday of the week
      diff = date.getDate() - day + (day == 0 ? -6 : 1);
    // Set the date to the calculated Monday
    date.setDate(diff)
    return date;
  }

  getWeekend(day: Date): boolean {
    if (day.getDay() == 0 || day.getDay() == 6){
      return true;
    }
    return false; 
  }

  onRowDoubleClicked(_: any): void {
  }

  validateModel(prop: string): string { return prop; }

  isModelValid(): boolean {
    return true;
  }
}
