import { ApexAxisChartSeries } from "ng-apexcharts";

export class BarChartModel {
    series: ApexAxisChartSeries;
    labels: string[] = [];
    title: string = "";

    constructor(_title: string = "", _series: ApexAxisChartSeries = [], _labels: string[] = []) {
        this.series = _series;
        this.labels = _labels;
        this.title = _title;
    }
}