import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { forkJoin } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

import { DialogService } from "src/app/shared/services/dialog.service";
import { formatDate } from "src/app/shared/utils/Formatters";
import { clone, dateComparator } from "src/app/shared/utils/Utils";

import { UserService } from "src/app/services/user.service";

import { EmployeeConstants } from "src/app/constants/employee-status.constants";
import { PatternConstants } from "src/app/constants/pattern-constants";
import { ChildrenModel } from "src/app/models/users/ChildrenModel";
import { UserInfoModel } from "src/app/models/users/UserInfoModel";
import { UserModel } from "src/app/models/users/UserModel";
import { UserRoleModel } from "src/app/models/users/UserRoleModel";
import { Company } from "../../models/companies/Company";
import { CompanyService } from "../../services/company.service";
import { GridComponentBase } from "../../shared/components/base/GridComponentBase";
import { AgGridUtils } from "../../shared/utils/AgGridUtils";

@Component({
  selector: "trackify-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent extends GridComponentBase<UserModel> implements OnInit {

  userRoles: UserRoleModel[];
  companies: Company[];
  currentUser: UserInfoModel;
  employeeStatuses = EmployeeConstants.statuses;
  cimTypes = EmployeeConstants.cimTypes;
  children: ChildrenModel[];
  maxBirthdate: number;
  namePattern = PatternConstants.NAME_PATTERN;

  user: UserModel;
  formatDateForTable = formatDate;

  @ViewChild("userModal", { static: true }) modal: ModalDirective;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    public router: Router,
    private dialogService: DialogService,
  ) {
    super();
    this.maxBirthdate = Date.now();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      { valueGetter: x => `${x.data.firstName} ${x.data.lastName}`, headerName: 'Name', width: 150, sort: 'asc', suppressSizeToFit: true },
      { field: 'email', headerName: 'Email', width: 150, suppressSizeToFit: true },
      { field: 'position', headerName: 'Position', width: 130, suppressSizeToFit: true },
      {
        valueGetter: x => x.data.companyId ? this.companies.find(c => c.id == x.data.companyId).shortName : 'N/A',
        type: ['setColumn'], headerName: 'Company', width: 130, suppressSizeToFit: true
      },
      {
        valueGetter: x => x.data.roleId ? this.userRoles.find(c => c.id == x.data.roleId).name : 'N/A',
        headerName: 'Role', type: ['centerColumn', 'setColumn'], width: 130, suppressSizeToFit: true
      },
      {
        valueGetter: (x) => x.data.nextReview ? this.formatDateForTable(x.data.nextReview) : 'N/A',
        headerName: "Next Review",
        width: 170,
        comparator: (_, __, nodeA, nodeB) =>
          dateComparator(nodeA.data.startDate, nodeB.data.startDate),
        suppressSizeToFit: true,
      },
      {
        valueGetter: x => x.data.status ? this.employeeStatuses.find(c => c.value == x.data.status).name : 'N/A',
        headerName: 'Status',
        width: 100,
        suppressSizeToFit: true
      },
      {
        valueGetter: x => x.data.cimType ? this.cimTypes.find(c => c.value == x.data.cimType).name : 'N/A',
        headerName: 'CIM Type',
        width: 170,
        suppressSizeToFit: true
      },
      {
        valueGetter: x => x.data.cimCor ? x.data.cimCor : 'N/A',
        headerName: 'CIM-COR',
        minWidth: 100,
        suppressSizeToFit: false
      },
      {
        headerName: 'Edit',
        pinned: 'right',
        headerClass: 'text-center',
        type: ['buttonColumn'],
        cellRendererParams: {
          buttonClass: 'btn btn-primary btn-xs',
          buttonText: '',
          iconClass: 'fas fa-edit white-color',
          onClick: this._onEdit.bind(this)
        },
      },
      {
        headerName: 'View',
        pinned: 'right',
        headerClass: 'text-center',
        type: ['buttonColumn'],
        cellRendererParams: {
          buttonClass: 'btn btn-primary btn-xs',
          buttonText: '',
          iconClass: 'fas fa-eye white-color',
          onClick: this._onView.bind(this)
        },
      },
      {
        headerName: 'Archive',
        pinned: 'right',
        headerClass: 'text-center',
        type: ['buttonColumn'],
        cellRendererParams: {
          buttonClass: 'btn btn-primary btn-xs',
          buttonText: '',
          iconClass: 'fas fa-ban white-color',
          onClick: this._onArchive.bind(this)
        },
      }
    ]);

    this.gridOptions.overlayNoRowsTemplate = '<span class="ag-overlay-no-rows-center">No user entries.</span>';
  }

  ngOnInit() {
    this.startLoader();
    this._loadData();
  }

  private _loadData() {
    forkJoin([
      this.userService.getUsers(),
      this.userService.getUserRoles(),
      this.companyService.getCompanies(),
      this.userService.getCurrentUserDetails()
    ]).pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
      .subscribe(
        ([users, roles, companies, currentUser]) => {
          this.data = users;
          this.userRoles = roles;
          this.companies = companies;
          this.currentUser = currentUser;
        });
  }

  private _onEdit(e) {
    this._openEditUserModal(e.data);
  }

  private _onView(e) {
    this._openDetails(e.data);
  }

  private _onArchive() {
    this._archiveUser();
  }


  private _openEditUserModal(user: UserModel) {
    this.user = clone(user);
    this.modal.show();
  }

  private _openDetails(user: UserModel) {
    if (!user) return;

    this.router.navigateByUrl("user-details/" + user.id);
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;
    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(u => u.id == this.currentSelection.id));
  }

  onRowDoubleClicked(_: any): void {
    this._openDetails(this.currentSelection);
  }

  saveChanges() {
    this.gridOptions.api.showLoadingOverlay()
    this.userService.editUser(this.user)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        this.data[this.currentSelectionIdx] = result;
        this.updateGridAfterEdit(result);
        this.gridOptions.api.hideOverlay();
        this.modal.hide();
        this.dialogService.showSimpleDialog('Succes!', 'User edited successfully', 'success');
      }, (_) => {
        this.dialogService.showSimpleDialog('Error!', "You can't edit your details, please ask another admin", 'error');
        this.modal.hide();
        this.currentSelection = null;
        this.gridOptions.api.hideOverlay();
      })
  }

  private _archiveUser() {
    this.dialogService.showDialog({
      title: `Are you sure you want to archive this user?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DC3545',
      confirmButtonText: 'Archive'
    }).then(result => {
      if (this.currentUser.id == this.currentSelection.id && result.isConfirmed) {
        this.dialogService.showSimpleDialog('Error!', "You can't archive yourself", 'error');
        return false;
      }
      if (!!result.value) {
        this.gridOptions.api.showLoadingOverlay()
        this.userService.archiveUsers(this.currentSelection.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            this.updateGridAfterDelete();
            this.currentSelection = null;
            this.dialogService.showSuccessMessage('Success!', 'Selected user archived successfully.');
          });
      }
    });
  }

  validateModel(_: string): string { return ''; }

  isModelValid(): boolean { return true; }
}
