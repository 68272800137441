import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize, takeUntil } from 'rxjs/operators';
import { ReviewModel } from 'src/app/models/reviews/ReviewModel';
import { ReviewService } from 'src/app/services/review.service';
import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase';
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils';
import { formatLocalTime } from 'src/app/shared/utils/Formatters';
import { dateComparator, isPastCurrentDate } from 'src/app/shared/utils/Utils';
import { ReviewStatusModalComponent } from '../review-status-modal/review-status-modal.component';
import { RowNode } from 'ag-grid-community';
import { ColorsConstants } from 'src/app/constants/colors-constants';
import { ReviewAssignModalComponent } from '../review-assign-modal/review-assign-modal.component';

@Component({
  selector: 'trackify-next-review',
  templateUrl: './next-review.component.html',
  styleUrls: ['./next-review.component.scss']
})
export class NextReviewComponent extends GridComponentBase<ReviewModel> implements OnInit {
  @Input() isAdminOrUser: boolean;
  @Input() isDeliveryManager: boolean;

  formatDateForTable = formatLocalTime;
  reviewModalRef: BsModalRef;

  constructor(
    private reviewService: ReviewService,
    private bsModalService: BsModalService) {
    super();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        field: "userName",
        headerName: "User",
        width: 150,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) =>
          !x.data.reviewDate
            ? "N/A"
            : this.formatDateForTable(x.data.reviewDate),
        headerName: "Review Date",
        width: 150,
        sort: 'asc',
        comparator: (_, __, nodeA, nodeB) => (dateComparator(nodeA.data.reviewDate, nodeB.data.reviewDate)),
        suppressSizeToFit: false,
      },
      {
        field: "reviewType",
        headerName: "Type",
        width: 130,
        suppressSizeToFit: false,
      }
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span>No users to show</span>';
  }

  ngOnInit(): void {
    if (this.isDeliveryManager) {
      this._addDeliveryManagerColumns();
    }
    else if (this.isAdminOrUser) {
      this._addAdminOrUserColumns();
    }

    this.startLoader();
    this._loadReviewData();
  }

  getRowStyle = (params: any) => {
    const reviewDate = params.data.reviewDate;
    if (isPastCurrentDate(reviewDate)) {
      return { background: ColorsConstants.LIGHT_RED };
    }
  };

  onRowSelected(_: any): void { }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  private _loadReviewData(): void {
    this.reviewService
      .getReviewsWithTypeReview()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader())
      )
      .subscribe(
        (results: ReviewModel[]) => {
          this.data = results;
        }
      );
  }
  
  private _addDeliveryManagerColumns(): void {
    this.addColumnDefs([
      {
        valueGetter: (x) => (x.data.reviewStatus ? x.data.reviewStatus : "N/A"),
        headerName: "Status",
        width: 130,
        suppressSizeToFit: false
      },
      {
        headerName: "Assign",
        pinned: 'right',
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-primary",
          buttonText: "",
          iconClass: "fas fa-user-plus",
          onClick: this._onAssignResponsibles.bind(this),
        }
      },
      {
        headerName: "Perform",
        pinned: 'right',
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-success btn-xs",
          buttonText: "",
          iconClass: "fas fa-check-double",
          onClick: this._onPerformViewAssignee.bind(this)
        }
      }
    ]);
  }

  private _addAdminOrUserColumns(): void {
    this.addColumnDefs([
      {
        valueGetter: (x) => (x.data.reviewStatus ? x.data.reviewStatus : "N/A"),
        headerName: "Status",
        width: 100,
        suppressSizeToFit: false
      }
    ]);
  }

  // Starting ASSIGN functionality:
  private _onAssignResponsibles(review: RowNode): void {
    const initialState = {
      reviewId: review.data.id,
      userFullName: review.data.userName,
      reviewStatus: review.data.reviewStatus
    };

    this.bsModalService.show(ReviewAssignModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg align-center-modal'
    });
  }

  // Starting PERFORM functionality:
  private _onPerformViewAssignee(review: RowNode): void {
    const initialState = {
      reviewId: review.data.id,
      userFullName: review.data.userName
    };
    this.bsModalService.show(ReviewStatusModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg align-center-modal'
    });
  }
}
