import { Component, OnInit} from "@angular/core";
import { takeUntil } from "rxjs/operators";

import { NameNotificationService } from "src/app/shared/services/name-notification.service";
import { LoadableComponentBase } from "src/app/shared/utils/LoadableComponentBase";

import { UserService } from "src/app/services/user.service";

import { UserBriefModel } from "src/app/models/users/UserBriefModel";

@Component({
  selector: "trackify-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent extends LoadableComponentBase implements OnInit {
  user: UserBriefModel;
  constructor(
    private userService: UserService,
    private nameNotificationService: NameNotificationService
  ) {
    super();
    this.subscriptions.push(
      this.nameNotificationService.nameRetrieved$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((name) => {
        this.user.name = name.firstName + " " + name.lastName;
      })
    );
  }

  ngOnInit() {
    this._getCurrentUser();
  }

  private _getCurrentUser() {
    this.userService.getCurrentUser().pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
      this.user = result;
    });
  }

  public clearLocalStorage(): void {
    localStorage.clear();
  }

  public openUserGuide() {
    window.open('api/Help', '_blank');
  }
}
