import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import * as moment from 'moment'
import { MomentConstants } from "src/app/constants/moment-constants";
import { finalize, takeUntil } from "rxjs/operators";

import { DashboardDataModel } from "src/app/models/dashboardData/DashboardDataModel";
import { MonthlySalaryService } from "src/app/services/monthly-salary.service";
import { formatDate } from "../../../shared/utils/Formatters";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";

import { WeekDays } from "src/app/shared/week-days.enum";
import { WeeklyPlannerService } from "src/app/services/weekly-planner.service";
import { WeeklyPlannerBasicModel } from "src/app/models/planner/WeeklyPlannerBasicModel";
import { UserService } from "src/app/services/user.service";
import { UserBriefModel } from "src/app/models/users/UserBriefModel";
import { ColorsConstants } from "src/app/constants/colors-constants";

@Component({
  selector: "trackify-user-dashboard",
  templateUrl: "./user-dashboard.component.html",
  styleUrls: ["./user-dashboard.component.scss"],
})
export class UserDashboardComponent

  extends GridComponentBase<WeeklyPlannerBasicModel>
  implements OnInit {

  dashboardData: DashboardDataModel = new DashboardDataModel();
  currentDate: string;
  recoveryOrOvertime: string = "No overtime or hours to recover";
  monthHolidaysDays: string = "";
  userId: number;
  displayedPlanning: WeeklyPlannerBasicModel[] = [];
  currentDay = new Date().getDay();
  weekDays = WeekDays;
  isCurrentWeekPlanning: boolean = false;
  currentUser: UserBriefModel;
  @ViewChild("staticModal", { static: true }) modal: ModalDirective;

  constructor(private monthlySalaryService: MonthlySalaryService, private weeklyPlannerService: WeeklyPlannerService, private userService: UserService) {
    super();

  }

  ngOnInit(): void {
    this.startLoader();
    this.currentDate = moment().toISOString();
    this._getUserDashboardInfo();
  }

  getFormattedDate(date): string {
    return formatDate(date, MomentConstants.DAY_MONTH_DAY_YEAR);
  };

  private _getUserDashboardInfo(): void {
    this.monthlySalaryService.getUserDashboardInfo()
    .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
    .subscribe((result) => {
      if (result == null) {
        return;
      }

      this.dashboardData = result;
      this.monthHolidaysDays = result.dailyHours > 0
        ? (this.dashboardData.paidTypeHolidaysInYear / result.dailyHours).toFixed(2)
        : "N/A";

      if (this.dashboardData.workedHours + this.dashboardData.paidTypeHolidaysInMonth + this.dashboardData.notPaidTypeHolidaysInMonth
        + this.dashboardData.medicalTypeHolidaysInMonth + this.dashboardData.specialTypeHolidaysInMonth > this.dashboardData.targetHours)
        this.recoveryOrOvertime = "Overtime";
      else this.recoveryOrOvertime = "To Recover";

      this.dashboardData.overtime = Number(
        Math.abs(result.overtime).toFixed(2)
      );
      //TO DO: To remove the subscribe inside another subscribe
      this._getCurrentUser();
      this._loadWeeklyPlanning();
    });
  }
  private _getCurrentUser(): void {
    this.userService.getCurrentUser()
      .pipe(
        takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.currentUser = data;
      })
  }

  private _loadWeeklyPlanning(): void {
    this.weeklyPlannerService.getWeeklyPlanning()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (weeklyPlan) => {
        let date = new Date();
        if (date.getDay() <= 5) {
          this.isCurrentWeekPlanning = true;
        }
        this.displayedPlanning = weeklyPlan;
        this.sortByDatePlanning();
      }
    );
  }

  sortByDatePlanning(): void {
    this.displayedPlanning.sort(function compare(a, b) {
      let dateA: any = new Date(a.planningDate);
      let dateB: any = new Date(b.planningDate);
      return dateA - dateB;
    });
  }

  getBackgroundColor(day: WeekDays): string {
    this.displayedPlanning.forEach(planner => {
      if (planner.customPlan) {
        planner.projectColor = null;
      }
    })
    return `${this.displayedPlanning[day - 1].projectColor}AA`
  }

  onRowSelected(): void {
  }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  setBorder(day: WeekDays): string {
    if (this.currentDay != day) {
      return '';
    }
    
    return ColorsConstants.RED_BORDER;
  }
}
