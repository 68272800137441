export class ApiConstants {
  public static readonly REVIEW_TYPE_API_ENDPOINT = "api/reviewTypes";
  public static readonly USER_API_ENDPOINT = "api/users";
  public static readonly COMPANY_API_ENDPOINT = "api/companies";
  public static readonly USEFULL_LINK_API_ENDPOINT = "api/usefulLinks";
  public static readonly REVIEW_API_ENDPOINT = "api/reviews";
  public static readonly REVIEW_FORM_API_ENDPOINT = "api/reviewForms";
  public static readonly MONTLY_SALARY_API_ENDPOINT = "api/monthlySalaries";
  public static readonly HOLIDAY_REQUEST_TYPES_API_ENDPOINT = "api/holidayRequestTypes";
  public static readonly HOLIDAY_REQUEST_API_ENDPOINT = "api/holidayRequests";
  public static readonly SETTINGS_API_ENDPOINT = "api/settings";
  public static readonly DAILY_STATS_API_ENDPOINT = "api/dailystats";
  public static readonly EXPORT_DATA_API_ENDPOINT = "api/dataExport";
  public static readonly USER_WEEKLY_PLANNER_ENDPOINT = "api/weeklyPlanners";
  public static readonly PROJECTS_ENDPOINT = "api/projects";
  public static readonly WEEKLY_PLANNERS_ENDPOINT = "api/weeklyPlanners";
  public static readonly BENEFITS_ENDPOINT = "api/benefits";
  public static readonly BENEFITS_POINTS_LOG_API_ENDPOINT = "api/benefitPointLogs";
  public static readonly BENEFIT_REQUESTS_ENDPOINT = "api/benefitRequests";
  public static readonly UPLOAD_LOGO_ENDPOINT = "api/image";
  public static readonly CLIENTS_API_ENDPOINT = "api/clients";
  public static readonly MONTHLY_STATS_ENDPOINT = "api/monthlyStats";
  public static readonly MENU_ITEMS_SETTINGS_ENDPOINT = "api/menuItemsSettings";
}
