import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiConstants } from "src/app/constants/api-constants";
import { BenefitWithoutLogoDto } from 'src/app/models/admin-benefits/BenefitWithoutLogoDto';
import { BenefitCategoryDto } from 'src/app/models/admin-benefits/BenefitCategoryDto';
import { ApiService } from 'src/app/shared/services/api.service';
import { BenefitWithLogo } from "src/app/models/admin-benefits/BenefitWithLogo";
import { BenefitTypeDto } from "src/app/models/admin-benefits/BenefitTypeDto";
import { AllBenefitsByTypeModel } from "src/app/models/user-benefits/AllBenefitsByTypeModel";
import { EventBenefitCardDetails } from "src/app/models/user-benefits/EventBenefitCardDetails";

@Injectable({
  providedIn: "root",
})
export class BenefitService {
  private _apiUrl = ApiConstants.BENEFITS_ENDPOINT;
  private _uploadLogoUrl = ApiConstants.UPLOAD_LOGO_ENDPOINT;

  constructor(private apiService: ApiService) { }

  getBenefits(): Observable<BenefitWithLogo[]> {
    return this.apiService.get<BenefitWithLogo[]>(this._apiUrl);
  }

  deleteBenefit(benefitId: number): Observable<BenefitWithLogo> {
    return this.apiService.delete(`${this._apiUrl}/${benefitId}`);
  }

  editBenefit(benefit: BenefitWithoutLogoDto): Observable<BenefitWithLogo> {
    return this.apiService.put<BenefitWithLogo>(this._apiUrl, benefit);
  }

  getBenefitCategories(): Observable<BenefitCategoryDto[]> {
    return this.apiService.get<BenefitCategoryDto[]>(`${this._apiUrl}/benefitCategories`);
  }

  getAllBenefitsGroupedByType(): Observable<AllBenefitsByTypeModel> {
    return this.apiService.get<AllBenefitsByTypeModel>(`${this._apiUrl}/allBenefitsGroupedByType`)
  }

  getBenefitTypes(): Observable<BenefitTypeDto[]> {
    return this.apiService.get<BenefitTypeDto[]>(`${this._apiUrl}/benefitTypes`);
  }

  addBenefit(benefit: BenefitWithoutLogoDto): Observable<BenefitWithoutLogoDto> {
    return this.apiService.post<BenefitWithoutLogoDto>(this._apiUrl, benefit);
  }

  addBenefitLogo(benefitId: number, file: FormData): Observable<BenefitWithLogo> {
    return this.apiService.put<BenefitWithLogo>(`${this._uploadLogoUrl}/${benefitId}`, file);
  }

  publishBenefit(benefitId: number): Observable<BenefitWithLogo> {
    return this.apiService.get(`${this._apiUrl}/publish/${benefitId}`);
  }

  concealBenefit(benefitId: number): Observable<BenefitWithLogo> {
    return this.apiService.get(`${this._apiUrl}/conceal/${benefitId}`);
  }

  getEventBenefitById(benefitId: number): Observable<EventBenefitCardDetails> {
    return this.apiService.get<EventBenefitCardDetails>(`${this._apiUrl}/getEventBenefitById/${benefitId}`);
  }
}
