export class PatternConstants {
    public static readonly PHONE_NUMBER_PATTERN = "((\\+?[0-9]+)|([0-9]*))";
    public static readonly EMAIL_PATTERN = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}";
    public static readonly NAME_PATTERN = "[a-zÀ-žA-Z-\\-a-zA-Z\\s]*";
    public static readonly CNP_PATTERN = "[0-9]{13}";
    public static readonly POSITION_PATTERN = "[a-zÀ-žA-Z-\\-a-zA-Z\\s]*";
    public static readonly OPTIONAL_NUMBER_PATTERN = "\\d*";
    public static readonly NAME_WITH_ANY_CHARACTERS_PATTERN = ".{1,25}";
    public static readonly ID_CARD_NUMBER_PATTERN = "^(([A-Z]{2}) ([0-9]{6,7}))$";
    public static readonly NUMBER_PATTERN = "[1-9][0-9]*";
    public static readonly NON_EMPTY_TEXT_PATTERN = /^\s*\S.*\s*$/m;
}
