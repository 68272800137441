import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import * as moment from "moment";

import { MonthDetailsService } from "src/app/services/month-details.service";
import { DialogService } from "src/app/shared/services/dialog.service";

import { MonthDetailModel } from "src/app/models/monthDetailsModel/MonthDetailModel";
import { FreeDayModel } from "src/app/models/monthDetailsModel/FreeDayModel";
import {
  getMomentFromMonthDetails,
  isSameDate,
} from "src/app/shared/utils/MomentUtils";
import { finalize, takeUntil } from "rxjs/operators";
import { sortNumbersArray } from "../../shared/utils/Utils";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { DateTimeModel } from "src/app/models/DateTimeModel";
import { forkJoin } from "rxjs";

@Component({
  selector: "trackify-month-details",
  templateUrl: "./month-details.component.html",
  styleUrls: ["./month-details.component.scss"],
})
export class MonthDetailsComponent extends GridComponentBase<MonthDetailModel>
  implements OnInit {
  private _futureYear: number;

  overlayLoadingTemplate: string;

  selectedMonth: MonthDetailModel;
  datepickerModel: string;
  selectedYear: number;
  currentYear: number;

  years: number[];

  minDate: string;
  maxDate: string;
  @Input() isInTab: boolean = false;
  @ViewChild("nationalHolidayModal", { static: true })
  holidayModal: ModalDirective;
  modalFreeDays: FreeDayModel[];

  constructor(
    private monthDetailService: MonthDetailsService,
    private dialogService: DialogService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        field: "name",
        headerName: "Month",
        width: 100
      },
      {
        field: "workingDays",
        headerName: "Number of working days",
        width: 100,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => {
          var result = "";
          result += `${x.data.freeDays.length > 0 ? `${x.data.freeDays.length + "-"}` : 0
            } `;

          x.data.freeDays.forEach((freeDay) => {
            result += " " + new Date(freeDay.freeDayDate).getDate();
          });
          return result;
        },
        headerName: "Number of national holidays",
        width: 300,
        suppressSizeToFit: true,
      },
      {
        headerName: "Add",
        suppressSizeToFit: true,
        width: 100,
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-primary ",
          iconClass: "now-ui-icons ui-1_calendar-60",
          onClick: this._onModal.bind(this),
        },
      },
    ]);
    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No Month details entries.</span>';
  }


  ngOnInit() {
    this.selectedYear = this.currentYear = moment().year();
    this.startLoader();

    this._loadMonthDetailsData();
  }

  private _loadMonthDetailsData() {
    forkJoin([
      this.monthDetailService.getYears(),
      this.monthDetailService.getMonthDetailsByYear(this.selectedYear)
    ]).pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader()))
      .subscribe(([years, months]) => {
        this.years = years;
        this._futureYear = this.years[0] + 1;
        this._initAndSortMonthDetails(months);
      });
  }

  private _initAndSortMonthDetails(months: MonthDetailModel[]) {
    this.data = months;
    this.data.sort((m1, m2) => (m1.monthNumber < m2.monthNumber ? -1 : 1));

    this.data.forEach((month) => {
      this._sortFreeDays(month.freeDays);
    });
  }

  public addYear() {
    this.dialogService
      .showDialog({
        title: this._futureYear,
        text: `Do you want to add year ${this._futureYear} into the system?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      })
      .then((result) => {
        if (!result.value) return;

        this.monthDetailService
          .addYear(this._futureYear)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            (months) => {
              this.years.push(this._futureYear);
              this._futureYear += 1; // get new 'future year'

              sortNumbersArray(this.years);
              this.selectedYear = this.years[0];
              this._initAndSortMonthDetails(months);

              this.dialogService.showSuccessMessage(
                "Success",
                `Year ${this._futureYear - 1
                } was successfully added into the system.`
              );
            },
            (err) => {
              this.dialogService.showSimpleDialog("Error", err, "error");
            }
          );
      });
  }

  showModal(month): void {
    this.selectedMonth = month;

    this.minDate = getMomentFromMonthDetails(this.selectedMonth).format();
    this.maxDate = getMomentFromMonthDetails(this.selectedMonth).endOf("month").format()

    this.modalFreeDays = [...this.selectedMonth.freeDays];

    this.holidayModal.show();
  }

  private _onModal(e) {
    this.showModal(e.data);
  }

  hideModal(): void {
    this.holidayModal.hide();
    this.datepickerModel = null;
  }

  saveChanges() {
    if (!this.selectedMonth) return;
    this.gridOptions.api.showLoadingOverlay()
    this.monthDetailService
      .updateFreeDaysForMonth(this.selectedMonth.id, this.modalFreeDays)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result) => {
          const currentIdx = this.data.indexOf(this.selectedMonth);
          this.data[currentIdx] = result;
          this.updateGridAfterEdit(result);
          this.gridOptions.api.hideOverlay();
          this._sortFreeDaysAfterUpdate(this.selectedMonth);

          this.dialogService.showSuccessMessage(
            "Success",
            `Successfully updated free days list for ${this.selectedMonth.name}`
          );

          this.hideModal();
        },
        (error) => {
          this.dialogService.showSimpleDialog(
            "Error",
            `We encountered an error. Details: ${error}`,
            "error"
          );
          this.gridOptions.api.hideOverlay();
        }
      );
  }

  addFreeDay() {
    if (!this.datepickerModel) {
      this.dialogService.showErrorMessage(
        "Error",
        "You must select a day from datepicker!"
      );
      return;
    }

    if (!this.selectedMonth.freeDays) {
      this.selectedMonth.freeDays = [];
    }

    if (
      this.modalFreeDays.filter((freeDay) =>
        isSameDate(freeDay.freeDayDate, this.datepickerModel)
      ).length > 0
    ) {
      this.dialogService.showErrorMessage(
        "Error",
        "The selected date is already added"
      );
      return;
    }

    const freeDay = new FreeDayModel();
    freeDay.freeDayDate = this.datepickerModel;
    const freeDayDate = new Date(freeDay.freeDayDate);
    freeDay.freeDayDateComponent = new DateTimeModel(freeDayDate.getDate(), freeDayDate.getMonth() + 1, freeDayDate.getFullYear());
    this.modalFreeDays.push(freeDay);
    this._sortFreeDays(this.modalFreeDays);
  }

  deleteSelection(day: FreeDayModel) {
    const index = this.modalFreeDays.findIndex((freeDay) =>
      isSameDate(freeDay.freeDayDate, day.freeDayDate)
    );
    this.modalFreeDays.splice(index, 1);
  }

  private _sortFreeDaysAfterUpdate(selectedMonth: MonthDetailModel) {
    const updatedMonthIndex = this.data.findIndex(
      (element) => element.id == selectedMonth.id
    );
    this._sortFreeDays(this.data[updatedMonthIndex].freeDays);
  }

  private _sortFreeDays(freeDays: FreeDayModel[]) {
    if (!freeDays) return;
    freeDays.sort((fd1, fd2) =>
      moment(fd1.freeDayDate).isSameOrBefore(moment(fd2.freeDayDate)) ? -1 : 1
    );
  }

  onRowSelected(): void { }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }
}
