import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../../shared/services/api.service";
import { RouteConstants } from "../../constants/route-constants";

import { HolidayStatsModel } from "src/app/models/holiday-stats/HolidayStatsModel";
import { HolidayTypeTimeStatistic } from "src/app/models/holiday-stats/HolidayTypeTimeStatistic";
import { ConsumedNationalHolidayStatistic } from "src/app/models/holiday-stats/ConsumedNationalHolidayStatistic";
import { HolidayStatDetailedModel } from "src/app/models/holiday-stats/HolidayStatDetailedModel";

@Injectable({
  providedIn: "root",
})
export class HolidayStatsService {
  private _apiBaseUrl = RouteConstants.HOLIDAY_STATS;

  constructor(private apiService: ApiService) { }

  public getBasicStatistics(): Observable<HolidayStatsModel[]> {
    return this.apiService.get<HolidayStatsModel[]>(this._apiBaseUrl);
  }

  public getTakenHolidaysTypeStatistic(userId: number): Observable<HolidayTypeTimeStatistic> {
    return this.apiService.get<HolidayTypeTimeStatistic>(`${this._apiBaseUrl}/holiday-type/${userId}`);
  }

  public getTakenHolidaysTypeStatisticCurrentUser(userId: number): Observable<HolidayTypeTimeStatistic> {
    return this.apiService.get<HolidayTypeTimeStatistic>(`${this._apiBaseUrl}/user-holiday-type/${userId}`);
  }

  public getCondumedNationalHolidaysStatistic(userId: number): Observable<ConsumedNationalHolidayStatistic> {
    return this.apiService.get<ConsumedNationalHolidayStatistic>(`${this._apiBaseUrl}/national/${userId}`);
  }

  public getCondumedNationalHolidaysStatisticForUser(userId: number): Observable<ConsumedNationalHolidayStatistic> {
    return this.apiService.get<ConsumedNationalHolidayStatistic>(`${this._apiBaseUrl}/user-national/${userId}`);
  }

  public getHolidaysStatistic(userId: number): Observable<HolidayStatDetailedModel> {
    return this.apiService.get<HolidayStatDetailedModel>(`${this._apiBaseUrl}/holiday/${userId}`);
  }

  public getHolidaysStatisticForUser(userId: number): Observable<HolidayStatDetailedModel> {
    return this.apiService.get<HolidayStatDetailedModel>(`${this._apiBaseUrl}/user-holiday/${userId}`);
  }
}
