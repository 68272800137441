import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../shared/services/api.service";
import { ApiConstants } from "../constants/api-constants";

import { DailyStatsModel} from "../models/dailyStats/DailyStatsModel";


@Injectable({
  providedIn: "root",
})
export class DailyStatsService {
  private _apiBaseUrl = ApiConstants.DAILY_STATS_API_ENDPOINT;

  constructor(private apiService: ApiService) {}

  public getAllDailyStats(): Observable<DailyStatsModel[]> {
    return this.apiService.get<DailyStatsModel[]>(`${this._apiBaseUrl}`);
  }
}
