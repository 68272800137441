import { Component, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { UserAnniversary } from './../../models/users/UserAnniversary';
import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase';
import { formatLocalTime } from 'src/app/shared/utils/Formatters';
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils';
import { dateComparator } from 'src/app/shared/utils/Utils';

@Component({
  selector: 'trackify-next-anniversaries',
  templateUrl: './next-anniversaries.component.html',
  styleUrls: ['./next-anniversaries.component.scss']
})
export class NextAnniversariesComponent extends GridComponentBase<UserAnniversary> implements OnInit {
  formatDateForTable = formatLocalTime;

  constructor(private userService: UserService) {
    super();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        field: "username",
        headerName: "User",
        width: 170,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) =>
          !x.data.anniversary
            ? "N/A"
            : this.formatDateForTable(x.data.anniversary),
        headerName: "Anniversary",
        width: 140,
        sort: 'asc',
        comparator: (_, __, nodeA, nodeB) => (dateComparator(nodeA.data.anniversary, nodeB.data.anniversary)),
        suppressSizeToFit: false,
      },
    ]);
    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No users to show</span>';
  }

  ngOnInit() {
    this.startLoader();
    this._loadAnniversaryData();
  }

  onRowSelected(_: any): void { }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  private _loadAnniversaryData() {
    this.userService
      .getUpcomingUserAnniversaries()
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
      .subscribe(
        (results) => {
          this.data = results;
        }
      );
  }

}
