import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'numberValidator'
})
@Injectable()
export class NumberValidatoPipe implements PipeTransform {
  transform(items: any[], field: string, value: string): any[] {
    if (!items) {
      return [];
    }
    if (!field || !value) {
      return items;
    }
    const key=parseInt(value);
    return items.filter(singleItem => { 
      while (singleItem[field] > 0) {
        if (singleItem[field] % 10 == key) {
          return true;
        }
        singleItem[field] = Math.trunc(singleItem[field] / 10);
      }
      return false;
    })
      
  }
   
}