import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConstants } from '../constants/api-constants';
import { Company } from '../models/companies/Company';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private _apiUrl = ApiConstants.COMPANY_API_ENDPOINT;

  constructor(private apiService: ApiService) { }

  getCompanies(): Observable<Company[]> {
    return this.apiService.get<Company[]>(this._apiUrl);
  }

  editCompany(company: Company): Observable<Company> {
    return this.apiService.put<Company>(this._apiUrl, company);
  }

  addCompany(company: Company): Observable<Company> {
    return this.apiService.post<Company>(this._apiUrl, company);
  }

  removeCompany(companyId: number): Observable<Company> {
    return this.apiService.delete(`${this._apiUrl}/${companyId}`);
  }
}
