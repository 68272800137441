export class BenefitTypeDto {
  id: number;
  name: string;
}

export enum BenefitTypeEnum {
  RECURRENT = 'recurrent',
  ONETIME = 'one-time',
  EVENT = 'event'
}
