import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BenefitCardConstants } from 'src/app/constants/benefit-card-constants';
import { BenefitRequest } from 'src/app/models/admin-benefits/BenefitRequest';
import { BenefitRequestSimple } from 'src/app/models/admin-benefits/BenefitRequestSimple';
import { BenefitRequestType } from 'src/app/models/admin-benefits/BenefitRequestType';
import { BenefitTypeEnum } from 'src/app/models/admin-benefits/BenefitTypeDto';
import { CreateBenefitRequestDto } from 'src/app/models/admin-benefits/CreateBenefitRequestDto';
import { EventBenefitCardDetails } from 'src/app/models/user-benefits/EventBenefitCardDetails';
import { UserBriefModel } from 'src/app/models/users/UserBriefModel';
import { UserGeneralInfo } from 'src/app/models/users/UserGeneralnfo';
import { BenefitService } from 'src/app/services/benefits/benefit.service';
import { BenefitRequestService } from 'src/app/services/benefits/benefitRequest.service';
import { UserService } from 'src/app/services/user.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LoadableComponentBase } from 'src/app/shared/utils/LoadableComponentBase';
import { clone } from 'src/app/shared/utils/Utils';

@Component({
  selector: 'trackify-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss']
})
export class EventModalComponent extends LoadableComponentBase implements OnInit {
  static readonly NUMBER_PATTERN = '^[1-9]\\d*$';
  dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'fullName',
    allowSearchFilter: true
  };
  isCreateMode: boolean = false;
  eventForm: FormGroup;
  participantsIdsList: number[];
  datepickerModel: string;
  minDate: string;
  maxDate: string;
  currentDate: Date = new Date();
  numberOfSelectedItems: number;
  currentUser: UserBriefModel;
  users: UserGeneralInfo[];
  benefitCard: EventBenefitCardDetails;
  benefitId: number;

  @Output() benefitCardAdded = new EventEmitter<BenefitRequestSimple>();

  constructor(private modalRef: BsModalRef,
    private userService: UserService,
    private benefitService: BenefitService,
    private formBuilder: FormBuilder,
    private benefitRequestService: BenefitRequestService,
    private dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.startLoader();
    this._loadData();
  }

  private _loadData(): void {
    forkJoin([this.userService.getUsers(), this.userService.getCurrentUser(), this.benefitService.getEventBenefitById(this.benefitId)])
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
        this.stopLoader();
      }))
      .subscribe(([users, currentUser, benefit]) => {
        this.users = users.map(user => ({ id: user.id, fullName: user.firstName.split(/-| /)[0] + ' ' + user.lastName }))
          .filter(val => val.id != currentUser.id);
        this.currentUser = currentUser;
        this.benefitCard = benefit;
        this.benefitCard.benefitType = BenefitTypeEnum.EVENT;
        this.benefitCard.enrollmentStatus = BenefitCardConstants.MYEVENT;
      });
  }

  hideModal(): void {
    this.modalRef.hide();
  }

  setCreateMode(): void {
    this.isCreateMode = true;
    const date = new Date();
    this.numberOfSelectedItems = 1;
    this.currentDate = clone(date);

    this.minDate = date.toDateString();
    date.setFullYear(date.getFullYear() + 1);
    this.maxDate = date.toDateString();

    this.participantsIdsList = [];
    this.participantsIdsList.push(this.currentUser.id);
    this.eventForm = this.formBuilder.group({
      details: ['', Validators.required],
      preferredGroupSize: [this.benefitCard.minPeople, Validators.min(this.benefitCard.minPeople)]
    });

    this.users.sort(function (a, b) {
      if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
        return -1;
      }
      if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  goBack(): void {
    this.isCreateMode = false;
  }

  createEvent(): void {
    const createBenefitRequest: CreateBenefitRequestDto = {
      benefitId: this.benefitCard.id,
      benefitRequestType: BenefitRequestType.ADD,
      details: this.eventForm.value.details,
      eventDate: this.currentDate,
      preferredGroupSize: this.eventForm.value.preferredGroupSize,
      participantsIdsList: this.participantsIdsList
    }

    if (this.numberOfSelectedItems <= createBenefitRequest.preferredGroupSize && this.numberOfSelectedItems >= this.benefitCard.minPeople) {
      this.startLoader();
      this.benefitRequestService.createBenefitRequest(createBenefitRequest)
        .pipe(takeUntil(this.ngUnsubscribe),
          finalize(() => {
            this.stopLoader();
            this.modalRef.hide();
          }))
        .subscribe((benefitRequest: BenefitRequest) => {
          const benefitRequestSimple: BenefitRequestSimple = {
            id: benefitRequest.id,
            benefitRequestType: benefitRequest.benefitRequestType
          }
          this.benefitCard.benefitRequest = benefitRequestSimple;
          this.dialogService.showSuccessMessage(
            "Success!",
            `New benefit event has been created for ${this.benefitCard.name}!`
          );
          this.modalRef.content = {
            eventAdded: this.benefitCard,
            createdby: this.currentUser.name
          }
        },
          (err) => {
            this.dialogService.showSimpleDialog("Error", err, "error");
          });
    } else {
      if (this.numberOfSelectedItems > createBenefitRequest.preferredGroupSize) {
        this.dialogService.showErrorMessage("Error", "The number of participants can't be bigger than the preferred group size");
      }
      else if (this.benefitCard.minPeople - createBenefitRequest.preferredGroupSize > 0) {
        this.dialogService.showErrorMessage("Error", "Preferred Group Size can't be lower than min people for this benefit");
      }
      else {
        this.dialogService.showErrorMessage("Select a number of participants from the chosen range", `[${this.benefitCard.minPeople} - ${createBenefitRequest.preferredGroupSize}]`);
      }
    }
  }

  onItemSelect(item: UserGeneralInfo): void {
    this.numberOfSelectedItems++;
    this.participantsIdsList.push(item.id);
  }

  onItemDeSelect(item: UserGeneralInfo): void {
    const index = this.participantsIdsList.indexOf(item.id, 0);
    if (index > -1) {
      this.participantsIdsList.splice(index, 1);
      this.numberOfSelectedItems--;
    }
  }

  onSelectAll(items: UserGeneralInfo[]): void {
    this.numberOfSelectedItems = this.users.length + 1;
    this.participantsIdsList = items.map((item: UserGeneralInfo) => item.id);
    this.participantsIdsList.push(this.currentUser.id);
  }

  onDeSelectAll(): void {
    this.numberOfSelectedItems = 1;
    this.participantsIdsList = [];
  }
}
