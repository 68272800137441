import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize, takeUntil } from "rxjs/operators";

import { clone } from "src/app/shared/utils/Utils";
import { DialogService } from "src/app/shared/services/dialog.service";

import { UsefulLinkService } from "src/app/services/usefulLink.service";

import { UsefulLinkModel } from "src/app/models/usefulLinks/UsefulLinkModel";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { formatDate } from "src/app/shared/utils/Formatters";

@Component({
  selector: "trackify-setting-useful-links",
  templateUrl: "./setting-useful-links.component.html",
  styleUrls: ["./setting-useful-links.component.scss"],
})
export class SettingUsefulLinksComponent
  extends GridComponentBase<UsefulLinkModel>
  implements OnInit {
  editMode = false;
  link: UsefulLinkModel;
  maxLinkNameLength = 30;
  urlValidationPattern: RegExp = new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
  );
  nameValidationPattern = "[A-Za-z0-9 ]{1,30}";
  @ViewChild("usefulLinkModal", { static: true }) modal: ModalDirective;

  constructor(
    private usefulLinkService: UsefulLinkService,
    private dialogService: DialogService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        field: "name",
        headerName: "Link Name",
        width: 150,
        sort: "asc",
        suppressSizeToFit: false,
      },
      {
        field: "url",
        headerName: "URL",
        width: 170,
        suppressSizeToFit: false,
        cellRenderer: function (params) {
          return `<a href="${params.value}" target="_blank">${params.value}</a>`;
        },
      },
      {
        field: "author",
        headerName: "Author",
        width: 140,
        suppressSizeToFit: false,
      },
      {
        field: "isGlobal",
        headerName: "IsGlobal",
        width: 140,
        suppressSizeToFit: true,
      },
      {
        headerName: "Edit",
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-primary btn-xs",
          buttonText: "",
          iconClass: "fas fa-edit white-color",
          onClick: this._onEdit.bind(this),
        },
      },
      {
        headerName: "Delete",
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-danger btn-xs",
          buttonText: "",
          iconClass: "fa fa-trash",
          onClick: this._onDelete.bind(this),
        },
      },
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No useful links entries.</span>';
  }
  formatDateForTable = formatDate;

  ngOnInit() {
    this.startLoader();
    this._getUserUsefulLinks();
  }

  private _getUserUsefulLinks() {
    this.usefulLinkService
      .getUserUsefulLinks()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe(
        (result) => {
          this.data = result;
        });
  }

  public goToLink(link: UsefulLinkModel) {
    window.open(link.url, "_blank");
  }

  openAddModal() {
    this.editMode = false;
    this.link = new UsefulLinkModel();
    this.modal.show();
  }

  openEditModal(link: UsefulLinkModel) {
    this.editMode = true;
    this.link = clone(link);
    this.modal.show();
  }

  private _onEdit(e) {
    this.openEditModal(e.data);
  }

  private _onDelete(e) {
    this.deleteLink(e.data.id);
  }

  saveChanges() {
    if (this.editMode) {
      if (
        this.link.name.trim().length != 0 &&
        this.link.url.match(this.urlValidationPattern)
      ) {
        this.gridOptions.api.showLoadingOverlay()
        this.usefulLinkService
          .editUsefulLinks(this.link)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            (result) => {
              const index = this.data.findIndex((l) => l.id == result.id);
              this.data[index] = result;
              this.updateGridAfterEdit(result);
              this.gridOptions.api.hideOverlay();
              this.modal.hide();
              this.dialogService.showSuccessMessage(
                "Success",
                "Details were updated successfully"
              );
              this.link = null;
            },
            (err) => {
              this.modal.hide();
              this.link = null;
              this.dialogService.showSimpleDialog("Error!", err, "error");
              this.gridOptions.api.hideOverlay();
            }
          );
      }
    } else {
      if (
        this.link.name.trim().length != 0 &&
        this.link.url.match(this.urlValidationPattern)
      ) {
        this.gridOptions.api.showLoadingOverlay()
        this.usefulLinkService
          .addUsefulLinks(this.link)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            (result) => {
              this.data.push(result);
              this.updateGridAfterAdd(result);
              this.gridOptions.api.hideOverlay();
              this.dialogService.showSuccessMessage(
                "Succes!",
                "Link added successfully"
              );
              this.link = null;
              this.modal.hide();
            },
            (_) => {
              this.dialogService.showErrorMessage(
                "Oops...",
                "Something Went wrong!"
              );
              this.link = null;
              this.modal.hide();
              this.gridOptions.api.hideOverlay();
            }
          );
      }
    }
  }

  deleteLink(linkId: number) {
    this.dialogService
      .showDialog({
        text: "Do you want to delete selected link?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      })
      .then((result) => {
        if (!!result.value) {
          this.gridOptions.api.showLoadingOverlay()
          this.usefulLinkService
            .deleteUsefulLinks(linkId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
              (_) => {
                const index = this.data.findIndex((l) => l.id == linkId);
                this.data.splice(index, 1);
                this.updateGridAfterDelete();
                this.gridOptions.api.hideOverlay();
                this.dialogService.showSimpleDialog(
                  "Success",
                  "Link was deleted succesfully",
                  "success"
                );
              },
              (err) => {
                this.dialogService.showSimpleDialog("Error!", err, "error");
              }
            );
        }
      });
  }

  isUrlValid() {
    if (!this.link.url) return true;
    return (
      this.link.url.length > 0 && this.link.url.match(this.urlValidationPattern)
    );
  }

  onRowSelected(): void { }

  onRowDoubleClicked(): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }
}
