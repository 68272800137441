import { Company } from "../companies/Company";
import { DateTimeModel } from "../DateTimeModel";
import { ChildrenModel } from "./ChildrenModel";

export class UserInfoModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  standardSalary: number;
  employmentDate?: string;
  employmentDateComponent?: DateTimeModel;
  leavingDate?: string;
  leavingDateComponent?: DateTimeModel;
  initialHolidayBase: number;
  currentHolidayBase: number;
  dailyHours: number;
  cnp: string;
  clockifyApiKey: string;
  position: string;
  isDailyStatsReminderActivated: boolean;
  isFullTime: boolean;
  useSalaryMultiplicator: boolean;

  company: Company;
  companyId: number;
  iban: string;
  bankBeneficiaryName: string;

  personalEmail: string;
  phoneNumber: string;
  birthday?: string;
  birthdayDateComponent?: DateTimeModel;
  address: string;
  workLocation: string;

  emergencyContactName: string;
  emergencyContactPhone: string;
  yearsOfEmployment: number;

  idCardExpirationDate?: string;
  idCardExpirationDateComponent?: DateTimeModel;
  medicalExaminationDate?: string;
  medicalExaminationDateComponent?: DateTimeModel;
  children: ChildrenModel[];
  idCardNumber: string;
}
